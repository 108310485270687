// FrequentlyAskedQuestions.jsx
import React, { useState } from "react";
import { FaMinus, FaPlus } from "react-icons/fa";
import "./FrequentlyAskedQuestions.css";

const faqData = [
  {
    question: "What services do you offer?",
    answer:
      "We specialize in end-to-end IT hardware platforms and system integrations services. Our comprehensive solutions include Structure cabling, data storage and server solutions",
  },
  {
    question: "How can I contact support?",
    answer: "You can contact us using the contact us form mentioned.",
  },
  {
    question: "How can i learn more about your services?",
    answer: "You can check out our solutions page",
  },

  {
    question: "How do I get started?",
    answer: "You can get in touch with us and we will help you.",
  },
];

const FrequentlyAskedQuestions = () => {
  const [openIndexes, setOpenIndexes] = useState([]);

  const toggleFAQ = (index) => {
    if (openIndexes.includes(index)) {
      setOpenIndexes(openIndexes.filter((i) => i !== index));
    } else {
      setOpenIndexes([...openIndexes, index]);
    }
  };

  return (
    <section className="faq-section">
      <div className="faq-header">
        <h2>Frequently Asked Questions</h2>
        <p>Find answers to the most common questions about our services.</p>
      </div>
      <div className="faq-grid">
        {faqData.map((faq, index) => (
          <div
            className={`faq-item ${
              openIndexes.includes(index) ? "active" : ""
            }`}
            key={index}
          >
            <button
              className="faq-question"
              onClick={() => toggleFAQ(index)}
              aria-expanded={openIndexes.includes(index)}
              aria-controls={`faq-answer-${index}`}
            >
              <span>{faq.question}</span>
              <span className="icon">
                {openIndexes.includes(index) ? <FaMinus /> : <FaPlus />}
              </span>
            </button>
            <div
              id={`faq-answer-${index}`}
              className={`faq-answer ${
                openIndexes.includes(index) ? "show" : ""
              }`}
            >
              <p>{faq.answer}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default FrequentlyAskedQuestions;
