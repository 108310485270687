import React from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate hook
import "./TPLinkPage.css"; // Custom styling for TP-Link page
// SynologyPage.jsx
import ReactPlayer from "react-player";
import Slider from "react-slick"; // Import Slider from react-slick
import "./SynologyPage.css"; // Custom styling for Synology page

// Import slick-carousel CSS
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

// Product Data
const products = [
  {
    name: "Business Wi-Fi Solutions(Omada)",
    // description:
    //   "A 2U rack-mounted NAS designed for enterprise-level storage with redundancy and high performance.",
    image: "/images/products/tplink/tplink-business-wifi-solutions-omada.jpg",
  },
  {
    name: "Home Networking Solutions",
    // description:
    //   "A powerful rack-mounted NAS with advanced data management, suitable for SMBs requiring virtualization support.",
    image: "/images/products/tplink/tplink-home-networking-solutions.jpg",
  },
  {
    name: "Enterprise networking and security solutions",
    // description:
    //   "A desktop NAS offering high scalability and performance, ideal for small to medium-sized businesses.",
    image:
      "/images/products/tplink/tplink-enterprise-networking-security-solutions.jpg",
  },
  {
    name: "Cloud base network management",
    // description:
    //   "A 6-bay NAS for businesses demanding fast data processing and seamless virtualization support.",
    image: "/images/products/tplink/tplink-cloud-base-network-management.jpg",
  },
  {
    name: "Industrial and outdoor networking solutions",
    // description:
    //   "A 5-bay NAS designed to deliver superior performance with multiple expansion options for growing businesses.",
    image:
      "/images/products/tplink/tplink-industrial-outdoor-networking-solutions.jpg",
  },
];

// Carousel settings
const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3, // Number of slides to show on desktop
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000, // 3 seconds
  responsive: [
    {
      breakpoint: 1200, // Tablet breakpoint
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 768, // Mobile breakpoint
      settings: {
        slidesToShow: 1,
      },
    },
  ],
  arrows: false, // Hide arrows for a cleaner look
};
const TPLinkPage = () => {
  const navigate = useNavigate(); // Initialize navigate

  return (
    <div className="tplink-page-container">
      <div className="tplink-hero-container">
        <div className="hero-text">
          <h1>TP-Link</h1>
          <h2>Reliable Networking Solutions</h2>
          <p>
            TP-Link is a leading global provider of reliable networking devices
            and accessories. With a focus on innovation and quality, TP-Link
            delivers high-performance products that cater to both consumers and
            businesses worldwide.
          </p>
          <button
            className="learn-more-button"
            onClick={() => navigate("/contact")}
          >
            Learn More
          </button>
        </div>
        <div
          className="hero-image"
          style={{
            backgroundColor: "#fff",
            padding: "50px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "200px",
          }}
        >
          <img src="/images/logos/Tp link-logo.svg" alt="TP-Link Logo" />
        </div>
      </div>

      {/* Product Carousel Section */}
      <div className="product-carousel-section">
        <h2 className="carousel-title">Our TPLink Solutions</h2>
        <Slider {...settings} aria-label="Product Carousel">
          {products.map((product, index) => (
            <div key={index} className="product-card">
              <a
                href={`/products/${product.name}`}
                className="product-link"
                aria-label={`Learn more about ${product.name}`}
              >
                <div className="product-image-container">
                  <img
                    src={product.image}
                    alt={`${product.name} Logo`}
                    className="product-image"
                    loading="lazy"
                  />
                </div>
                <h3 className="product-name">{product.name}</h3>
                <p className="product-description">{product.description}</p>
              </a>
            </div>
          ))}
        </Slider>
      </div>

      <div className="video-container">
        <div className="video-responsive">
          <ReactPlayer
            url="https://www.youtube.com/watch?v=Duwg0dTa7W4"
            controls
            width="100%"
            height="100%"
            className="react-player"
          />
        </div>
      </div>
    </div>
  );
};

export default TPLinkPage;
