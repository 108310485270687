import React from 'react';
import { Parallax } from 'react-parallax';
import './Services.css';
import {
  FaNetworkWired,
  FaCogs,
  FaCloud,
  FaMobileAlt,
  FaChartLine,
  FaHeadset,
  FaSyncAlt,
  FaLightbulb,
} from 'react-icons/fa';

const IoTImplementation = () => {
  const features = [
    {
      icon: <FaNetworkWired />,
      title: 'Network Integration',
      description: 'Seamlessly integrate IoT devices into your network.',
      iconClass: 'blue',
    },
    {
      icon: <FaCogs />,
      title: 'Custom Solutions',
      description: 'Tailored IoT solutions to meet your business needs.',
      iconClass: 'blue',
    },
    {
      icon: <FaCloud />,
      title: 'Cloud Connectivity',
      description: 'Connect devices to cloud platforms for data storage and analysis.',
      iconClass: 'blue',
    },
    {
      icon: <FaMobileAlt />,
      title: 'Mobile Integration',
      description: 'Manage and monitor devices from mobile applications.',
      iconClass: 'blue',
    },
  ];

  const benefits = [
    {
      icon: <FaChartLine />,
      title: 'Data Insights',
      description: 'Gain valuable insights from device data.',
      iconClass: 'teal',
    },
    {
      icon: <FaSyncAlt />,
      title: 'Improved Efficiency',
      description: 'Automate processes for better efficiency.',
      iconClass: 'teal',
    },
    {
      icon: <FaLightbulb />,
      title: 'Innovation',
      description: 'Stay ahead with cutting-edge IoT technology.',
      iconClass: 'teal',
    },
    {
      icon: <FaHeadset />,
      title: 'Expert Support',
      description: 'Assistance from our IoT specialists.',
      iconClass: 'teal',
    },
  ];

  return (
    <div className="services-page">
      {/* Hero Section */}
      <section
        className="hero-section"
        style={{
          backgroundImage:
            "url('/images/services/iot-implementation/hero-iot-implementation.jpg')",
        }}
      >
        <div className="hero-content">
          <h1>IoT Implementation</h1>
          <p>We specialise in enforcing IoT infrastructure tailored on your business needs. Our solutions consist of the integration of sensors, gateways, and cloud platforms to acquire and analyze facts in actual time. We ensure that your IoT structures are stable, scalable, and absolutely optimized for Performance.
          </p>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/iot-implementation/parallax1.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Connect Everything</h2>
        </div>
      </Parallax>

      {/* Features Section */}
      <section className="section light-bg">
        <div className="container">
          <h2>Our IoT Implementation Services</h2>
          <div className="grid">
            {features.map((feature, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${feature.iconClass}`}>{feature.icon}</div>
                <h3>{feature.title}</h3>
                <p>{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/iot-implementation/parallax2.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Innovate with IoT</h2>
        </div>
      </Parallax>

      {/* Benefits Section */}
      <section className="section dark-bg">
        <div className="container">
          <h2>Benefits</h2>
          <div className="grid">
            {benefits.map((benefit, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${benefit.iconClass}`}>{benefit.icon}</div>
                <h3>{benefit.title}</h3>
                <p>{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="cta-section">
        <h2>Ready to Implement IoT?</h2>
        <a href="/contact" className="cta-button">
          Contact Us
        </a>
      </section>
    </div>
  );
};

export default IoTImplementation;
