// File: src/components/PartnersSection.jsx

import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import "./PartnersSection.css";

const partners = [
  {
    name: "Cisco",
    logo: "/images/logos/Cisco-logo.svg",
    path: "/brands/cisco",
  },
  {
    name: "Ubiquiti",
    logo: "/images/logos/new/U-with-bg.svg",
    path: "/brands/ubiquiti",
    height: "80px",
  },
  {
    name: "Volktek",
    logo: "/images/logos/new/volktek.svg",
    path: "/brands/volktek",
  },
  {
    name: "Dasan Networks",
    logo: "/images/logos/Dasan networks-logo.svg",
    path: "/brands/dasan-networks",
    height: "190px",
  },
  {
    name: "Panduit",
    logo: "/images/logos/new/all_logos__1.svg",
    path: "/brands/panduit",
  },
  {
    name: "Fluke Networks",
    logo: "/images/logos/new/all_logos__5.svg",
    path: "/brands/fluke-networks",
    height: "220px",
  },
  {
    name: "Synology",
    logo: "/images/logos/new/all_logos__6.svg",
    path: "/brands/synology",
    height: "200px",
  },
  {
    name: "TP-Link",
    logo: "/images/logos/new/all_logos.svg",
    path: "/brands/tplink",
    height: "160px",
  },
  {
    name: "HPE",
    logo: "/images/logos/new/all_logos__8.svg",
    path: "/brands/hpe",
  },
  {
    name: "Juniper",
    logo: "/images/logos/new/all_logos__7.svg",
    path: "/brands/juniper",
  },
  {
    name: "Logitech",
    logo: "/images/logos/new/all_logos__4.svg",
    path: "/brands/logitech",
  },
  {
    name: "Micron",
    logo: "/images/logos/new/all_logos__2.svg",
    path: "/brands/micron",
  },
  {
    name: "Microsoft",
    logo: "/images/logos/new/all_logos__11.svg",
    path: "/brands/microsoft",
    height: "180px",
  },
  {
    name: "Lenovo",
    logo: "/images/logos/new/all_logos__3.svg",
    path: "/brands/lenovo",
  },
  {
    name: "VCA",
    logo: "/images/logos/new/all_logos__10.svg",
    path: "/brands/vca",
  },
];

const PartnerCard = ({ name, logo, path, height }) => {
  return (
    <Link to={path} className="partner" aria-label={`Visit ${name} page`}>
      <img
        src={logo}
        alt={`${name} Official Logo`}
        className="partners-section__logo"
        loading="lazy" // Enable lazy loading for performance
        style={{ ...(height ? { height: height, width: "auto" } : {}) }}
      />
    </Link>
  );
};

PartnerCard.propTypes = {
  name: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
};

const PartnersSection = () => {
  return (
    <section className="partners-section">
      <div className="page-container">
        <div className="partners-container">
          <h2 className="partners-section__heading">
            Trusted By Over <span className="highlight">2300+</span> Companies
            Worldwide
          </h2>
          <div className="partners-section__grid" role="list">
            {partners.map((partner) => (
              <PartnerCard
                key={partner.name}
                name={partner.name}
                logo={partner.logo}
                path={partner.path}
                height={partner?.height}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default PartnersSection;
