import React from 'react';
import { Parallax } from 'react-parallax';
import './Services.css';
import {
  FaShieldAlt,
  FaLock,
  FaUserSecret,
  FaBug,
  FaChartLine,
  FaHeadset,
  FaKey,
  FaEye,
} from 'react-icons/fa';

const IoTSecurity = () => {
  const features = [
    {
      icon: <FaShieldAlt />,
      title: 'Device Authentication',
      description: 'Ensure only authorized devices connect to your network.',
      iconClass: 'blue',
    },
    {
      icon: <FaLock />,
      title: 'Data Encryption',
      description: 'Protect data in transit and at rest.',
      iconClass: 'blue',
    },
    {
      icon: <FaUserSecret />,
      title: 'Access Control',
      description: 'Manage permissions for users and devices.',
      iconClass: 'blue',
    },
    {
      icon: <FaBug />,
      title: 'Vulnerability Assessments',
      description: 'Identify and address security weaknesses.',
      iconClass: 'blue',
    },
  ];

  const benefits = [
    {
      icon: <FaKey />,
      title: 'Enhanced Security',
      description: 'Protect your IoT ecosystem from threats.',
      iconClass: 'teal',
    },
    {
      icon: <FaEye />,
      title: 'Continuous Monitoring',
      description: 'Keep an eye on your devices for suspicious activity.',
      iconClass: 'teal',
    },
    {
      icon: <FaChartLine />,
      title: 'Compliance',
      description: 'Meet industry security standards and regulations.',
      iconClass: 'teal',
    },
    {
      icon: <FaHeadset />,
      title: 'Expert Support',
      description: 'Assistance from our IoT security specialists.',
      iconClass: 'teal',
    },
  ];

  return (
    <div className="services-page">
      {/* Hero Section */}
      <section
        className="hero-section"
        style={{
          backgroundImage:
            "url('/images/services/iot-security/hero-iot-security.jpg')",
        }}
      >
        <div className="hero-content">
          <h1>IoT Security</h1>
          <p>As a cybersecurity enterprise, we prioritize security in every element of your IoT infrastructure. We
put into effect robust security features, consisting of encryption, steady authentication, and
vulnerability management, to guard your related devices and information from cyber threats.
</p>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/iot-security/parallax1.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Protect Your IoT Ecosystem</h2>
        </div>
      </Parallax>

      {/* Features Section */}
      <section className="section light-bg">
        <div className="container">
          <h2>Our IoT Security Services</h2>
          <div className="grid">
            {features.map((feature, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${feature.iconClass}`}>
                  {feature.icon}
                </div>
                <h3>{feature.title}</h3>
                <p>{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/iot-security/parallax2.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Stay Secure</h2>
        </div>
      </Parallax>

      {/* Benefits Section */}
      <section className="section dark-bg">
        <div className="container">
          <h2>Benefits</h2>
          <div className="grid">
            {benefits.map((benefit, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${benefit.iconClass}`}>
                  {benefit.icon}
                </div>
                <h3>{benefit.title}</h3>
                <p>{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="cta-section">
        <h2>Ready to Secure Your IoT Devices?</h2>
        <a href="/contact" className="cta-button">
          Contact Us
        </a>
      </section>
    </div>
  );
};

export default IoTSecurity;
