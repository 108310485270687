import React from "react";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom"; // Import useNavigate hook
import Slider from "react-slick"; // Import Slider from react-slick
import "./JuniperPage.css"; // Custom styling for Juniper page

// Import slick-carousel CSS
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

// Carousel settings
const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3, // Number of slides to show on desktop
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000, // 3 seconds
  responsive: [
    {
      breakpoint: 1200, // Tablet breakpoint
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 768, // Mobile breakpoint
      settings: {
        slidesToShow: 1,
      },
    },
  ],
  arrows: false, // Hide arrows for a cleaner look
};
// Product Data
const products = [
  {
    name: "Network Automation and SDN solutions",
    // description:
    //   "A 2U rack-mounted NAS designed for enterprise-level storage with redundancy and high performance.",
    image:
      "/images/products/juniper/juniper-network-automation-sdn-solutions.jpg",
  },
  {
    name: "Cloud Networking and Virtualization",
    // description:
    //   "A powerful rack-mounted NAS with advanced data management, suitable for SMBs requiring virtualization support.",
    image:
      "/images/products/juniper/juniper-cloud-networking-virtualization.png",
  },
  {
    name: "Data Center Solutions",
    // description:
    //   "A desktop NAS offering high scalability and performance, ideal for small to medium-sized businesses.",
    image: "/images/products/juniper/juniper-data-center-solutions.jpg",
  },
  {
    name: "Wide Area Networking",
    // description:
    //   "A desktop NAS offering high scalability and performance, ideal for small to medium-sized businesses.",
    image: "/images/products/juniper/juniper-wide-area-networking.png",
  },
];

const JuniperPage = () => {
  const navigate = useNavigate(); // Initialize navigate

  return (
    <div className="juniper-page-container">
      <div className="juniper-hero-container">
        <div className="hero-text">
          <h1>Juniper Networks</h1>
          <h2>Building the Future of Networking</h2>
          <p>
            Juniper Networks is a global leader in networking technologies,
            providing high-performance networking solutions to businesses,
            service providers, and governments. With innovations in routing,
            switching, and security, Juniper continues to redefine networking
            for the digital age.
          </p>
          <button
            className="learn-more-button"
            onClick={() => navigate("/contact")}
          >
            Learn More
          </button>
        </div>
        <div
          className="hero-image"
          style={{
            backgroundColor: "#fff",
            padding: "50px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "200px",
          }}
        >
          <img src="/images/logos/new/all_logos__7.svg" alt="Juniper Logo" />
        </div>
      </div>

      {/* Product Carousel Section */}
      <div className="product-carousel-section">
        <h2 className="carousel-title">Our Juniper Networks Solutions</h2>
        <Slider {...settings} aria-label="Product Carousel">
          {products.map((product, index) => (
            <div key={index} className="product-card">
              <a
                href={`/products/${product.name}`}
                className="product-link"
                aria-label={`Learn more about ${product.name}`}
              >
                <div className="product-image-container">
                  <img
                    src={product.image}
                    alt={`${product.name} Logo`}
                    className="product-image"
                    loading="lazy"
                  />
                </div>
                <h3 className="product-name">{product.name}</h3>
                <p className="product-description">{product.description}</p>
              </a>
            </div>
          ))}
        </Slider>
      </div>

      <div className="video-container">
        <div className="video-responsive">
          <ReactPlayer
            url="https://www.youtube.com/watch?v=cjTwUrePxLU"
            controls
            width="100%"
            height="100%"
            className="react-player"
          />
        </div>
      </div>
    </div>
  );
};

export default JuniperPage;
