import { default as React } from "react";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom"; // Import useNavigate hook
import Slider from "react-slick"; // Import Slider from react-slick
import "./JuniperPage.css"; // Custom styling for Juniper page
import "./LenovoPage.css"; // Custom styling for Lenovo page

// Import slick-carousel CSS
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

// Carousel settings
const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3, // Number of slides to show on desktop
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000, // 3 seconds
  responsive: [
    {
      breakpoint: 1200, // Tablet breakpoint
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 768, // Mobile breakpoint
      settings: {
        slidesToShow: 1,
      },
    },
  ],
  arrows: false, // Hide arrows for a cleaner look
};
// Product Data
const products = [
  {
    name: "Business IT solutions",
    // description:
    //   "A 2U rack-mounted NAS designed for enterprise-level storage with redundancy and high performance.",
    image: "/images/products/lenovo/lenovo-business-it-solutions.jpg",
  },
  {
    name: "Cloud solutions",
    // description:
    //   "A powerful rack-mounted NAS with advanced data management, suitable for SMBs requiring virtualization support.",
    image: "/images/products/lenovo/lenovo-cloud-solutions.jpg",
  },
  {
    name: "Collaboration and communication solutions",
    // description:
    //   "A desktop NAS offering high scalability and performance, ideal for small to medium-sized businesses.",
    image:
      "/images/products/lenovo/lenovo-collaboration-communication-solutions.jpg",
  },
  {
    name: "Data storage solutions",
    // description:
    //   "A desktop NAS offering high scalability and performance, ideal for small to medium-sized businesses.",
    image: "/images/products/lenovo/lenovo-data-storage-solutions.jpg",
  },
  {
    name: "Virtualization and remote work solutions",
    // description:
    //   "A desktop NAS offering high scalability and performance, ideal for small to medium-sized businesses.",
    image:
      "/images/products/lenovo/lenovo-virtualization-remote-work-solutions.jpg",
  },
  {
    name: "Hybrid cloud solutions",
    // description:
    //   "A desktop NAS offering high scalability and performance, ideal for small to medium-sized businesses.",
    image: "/images/products/lenovo/lenovo-hybrid-cloud-solutions.jpg",
  },
];

const LenovoPage = () => {
  const navigate = useNavigate(); // Initialize navigate

  return (
    <div className="lenovo-page-container">
      <div className="lenovo-hero-container">
        <div className="hero-text">
          <h1>Lenovo</h1>
          <h2>Innovation That Empowers People and Businesses</h2>
          <p>
            Lenovo is a global leader in personal technology, manufacturing a
            wide range of devices including PCs, laptops, smartphones, tablets,
            and more. With a commitment to innovation, Lenovo provides
            cutting-edge solutions that empower individuals and businesses to
            achieve more.
          </p>
          <button
            className="learn-more-button"
            onClick={() => navigate("/contact")}
          >
            Learn More
          </button>
        </div>
        <div className="hero-image">
          <img src="/images/logos/new/all_logos__3.svg" alt="Lenovo Logo" />
        </div>
      </div>

      {/* Product Carousel Section */}
      <div className="product-carousel-section">
        <h2 className="carousel-title">Our Lenovo Solutions</h2>
        <Slider {...settings} aria-label="Product Carousel">
          {products.map((product, index) => (
            <div key={index} className="product-card">
              <a
                href={`/products/${product.name}`}
                className="product-link"
                aria-label={`Learn more about ${product.name}`}
              >
                <div className="product-image-container">
                  <img
                    src={product.image}
                    alt={`${product.name} Logo`}
                    className="product-image"
                    loading="lazy"
                  />
                </div>
                <h3 className="product-name">{product.name}</h3>
                <p className="product-description">{product.description}</p>
              </a>
            </div>
          ))}
        </Slider>
      </div>

      <div className="video-container">
        <div className="video-responsive">
          <ReactPlayer
            url="https://www.youtube.com/watch?v=9fTY2hl9C6I"
            controls
            width="100%"
            height="100%"
            className="react-player"
          />
        </div>
      </div>
    </div>
  );
};

export default LenovoPage;
