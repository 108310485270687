import React, { useEffect, useState } from 'react';
import './HeroSection.css';

const HeroSection = () => {
  const texts = ['Security', 'Reliability', 'Efficiency'];
  const [text, setText] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [loopNum, setLoopNum] = useState(0);
  const [typingSpeed, setTypingSpeed] = useState(150);

  useEffect(() => {
    const handleTyping = () => {
      const i = loopNum % texts.length;
      const fullText = texts[i];

      setText(
        isDeleting
          ? fullText.substring(0, text.length - 1)
          : fullText.substring(0, text.length + 1)
      );

      setTypingSpeed(isDeleting ? 30 : 150);

      if (!isDeleting && text === fullText) {
        setTimeout(() => setIsDeleting(true), 500);
      } else if (isDeleting && text === '') {
        setIsDeleting(false);
        setLoopNum(loopNum + 1);
      }
    };

    const typingTimeout = setTimeout(handleTyping, typingSpeed);
    return () => clearTimeout(typingTimeout);
  }, [text, isDeleting, loopNum, typingSpeed, texts]);

  return (
    <div className="hero-container">
      <div className="hero-text">
        <h1>Professional <span className="changing-text">{text}</span></h1>
        <h2>with Reliable Service</h2>
        <p>At Rajguru distributors, we specialize in end-to-end IT hardware platforms and system integrations services. Our comprehensive solutions include IT infrastructure services such as structured cabling, data storage and server solutions which include NAS and SAN systems, Cyber security, video Surveillance, Collaboration tools and IT peripherals.
        </p>
        <button className="learn-more-button">Learn More</button>
      </div>
      <div className="hero-image">
        <img src="/images/security.png" alt="Hero" />
      </div>
    </div>
  );
};

export default HeroSection;
