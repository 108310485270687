import React from 'react';
import { Parallax } from 'react-parallax';
import './Services.css';
import {
  FaMobileAlt,
  FaTools,
  FaSyncAlt,
  FaCogs,
  FaChartLine,
  FaHeadset,
  FaShieldAlt,
  FaWrench,
} from 'react-icons/fa';

const IoTDeviceManagement = () => {
  const features = [
    {
      icon: <FaSyncAlt />,
      title: 'Remote Updates',
      description: 'Deploy updates to devices over-the-air.',
      iconClass: 'blue',
    },
    {
      icon: <FaTools />,
      title: 'Device Diagnostics',
      description: 'Monitor device health and performance.',
      iconClass: 'blue',
    },
    {
      icon: <FaCogs />,
      title: 'Configuration Management',
      description: 'Manage device settings and configurations.',
      iconClass: 'blue',
    },
    {
      icon: <FaMobileAlt />,
      title: 'Mobile Access',
      description: 'Manage devices from your mobile device.',
      iconClass: 'blue',
    },
  ];

  const benefits = [
    {
      icon: <FaChartLine />,
      title: 'Operational Efficiency',
      description: 'Streamline device management processes.',
      iconClass: 'teal',
    },
    {
      icon: <FaWrench />,
      title: 'Reduced Downtime',
      description: 'Quickly address device issues remotely.',
      iconClass: 'teal',
    },
    {
      icon: <FaShieldAlt />,
      title: 'Enhanced Security',
      description: 'Ensure devices are up-to-date with security patches.',
      iconClass: 'teal',
    },
    {
      icon: <FaHeadset />,
      title: 'Expert Support',
      description: 'Assistance from our IoT management specialists.',
      iconClass: 'teal',
    },
  ];

  return (
    <div className="services-page">
      {/* Hero Section */}
      <section
        className="hero-section"
        style={{
          backgroundImage:
            "url('/images/services/iot-device-management/hero-iot-device-management.jpg')",
        }}
      >
        <div className="hero-content">
          <h1>IoT Device Management</h1>
          <p>We provide complete IoT tool management offerings, ensuring seamless connectivity
between sensors and gateways. Our crew video display units continue your gadgets, coping with
the whole thing from updates to troubleshooting, so your IoT structures run smoothly.
</p>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/iot-device-management/parallax1.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Control at Your Fingertips</h2>
        </div>
      </Parallax>

      {/* Features Section */}
      <section className="section light-bg">
        <div className="container">
          <h2>Our Device Management Services</h2>
          <div className="grid">
            {features.map((feature, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${feature.iconClass}`}>{feature.icon}</div>
                <h3>{feature.title}</h3>
                <p>{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/iot-device-management/parallax2.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Manage with Ease</h2>
        </div>
      </Parallax>

      {/* Benefits Section */}
      <section className="section dark-bg">
        <div className="container">
          <h2>Benefits</h2>
          <div className="grid">
            {benefits.map((benefit, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${benefit.iconClass}`}>{benefit.icon}</div>
                <h3>{benefit.title}</h3>
                <p>{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="cta-section">
        <h2>Ready to Manage Your IoT Devices?</h2>
        <a href="/contact" className="cta-button">
          Contact Us
        </a>
      </section>
    </div>
  );
};

export default IoTDeviceManagement;
