// ContactUsPage.js

import "@fortawesome/fontawesome-free/css/all.min.css"; // Ensure Font Awesome is imported if using npm
import React, { useState } from "react";
import "./ContactUsPage.css";

const ContactUsPage = () => {
  // State for form data
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  // State for submission status
  const [submissionStatus, setSubmissionStatus] = useState(null); // 'success' | 'error' | null

  // Handle input changes
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Simple validation (can be expanded)
    if (
      !formData.name ||
      !formData.email ||
      !formData.subject ||
      !formData.message
    ) {
      setSubmissionStatus("error");
      return;
    }

    try {
      // TODO: Implement actual submission logic (e.g., API call)
      console.log("Form Submitted:", formData);

      // Simulate successful submission
      setSubmissionStatus("success");

      // Reset form
      setFormData({
        name: "",
        email: "",
        subject: "",
        message: "",
      });
    } catch (error) {
      console.error("Submission Error:", error);
      setSubmissionStatus("error");
    }
  };

  return (
    <section className="contact-us-page">
      <div className="contact-us-container">
        <div className="contact-us-content">
          <article className="contact-info">
            <h1 className="contact-us-heading">Contact Us</h1>
            <p className="contact-us-description">
              We’re here to assist with your inquiries and support needs. Reach
              out to us, and we’ll respond promptly.
            </p>
            <div className="contact-details">
              <div className="contact-item">
                <i
                  className="contact-icon fas fa-map-marker-alt"
                  aria-hidden="true"
                ></i>
                <div className="contact-text">
                  <h4>Our Office</h4>
                  <p>
                    No. 1-7-283/D/1,, plot no 126,, 2nd Floor, Block-C, Jaya
                    mansion, SD road, Secunderabad-500003
                  </p>
                </div>
              </div>
              {/* <div className="contact-item">
                <i className="contact-icon fas fa-phone" aria-hidden="true"></i>
                <div className="contact-text">
                  <h4>Call Us</h4>
                  <p>(+62)81 234 998 112</p>
                </div>
              </div> */}
              <div className="contact-item">
                <i
                  className="contact-icon fas fa-envelope"
                  aria-hidden="true"
                ></i>
                <div className="contact-text">
                  <h4>Email Us</h4>
                  <p>biz@rajgurudistributors.com</p>
                </div>
              </div>
            </div>
          </article>
          <aside className="contact-form">
            <form onSubmit={handleSubmit} noValidate>
              <div className="form-group">
                <label htmlFor="name">Your Name</label>
                <input
                  type="text"
                  id="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Your Name"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Your Email</label>
                <input
                  type="email"
                  id="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Your Email"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="subject">Your Subject</label>
                <input
                  type="text"
                  id="subject"
                  value={formData.subject}
                  onChange={handleChange}
                  placeholder="Your Subject"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="message">Your Message</label>
                <textarea
                  id="message"
                  value={formData.message}
                  onChange={handleChange}
                  placeholder="Your Message"
                  required
                ></textarea>
              </div>
              <button type="submit" className="send-message-button">
                Send Message
              </button>
              {submissionStatus === "success" && (
                <p className="feedback-message success-message">
                  Your message has been sent successfully!
                </p>
              )}
              {submissionStatus === "error" && (
                <p className="feedback-message error-message">
                  There was an error sending your message. Please try again.
                </p>
              )}
            </form>
          </aside>
        </div>
      </div>
      <div className="map-container">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3944.693623122665!2d115.26378507434285!3d-8.507156789479526!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd2075c5e5f3f67%3A0xe1e42c13354e5a2e!2sJl.%20Raya%20Ubud%2C%20Ubud%2C%20Kec.%20Ubud%2C%20Kabupaten%20Gianyar%2C%20Bali%2080571%2C%20Indonesia!5e0!3m2!1sen!2sus!4v1595891374315!5m2!1sen!2sus"
          allowFullScreen=""
          loading="lazy"
          title="Our Location"
        ></iframe>
      </div>
    </section>
  );
};

export default ContactUsPage;
