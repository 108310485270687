import React from 'react';
import { Parallax } from 'react-parallax';
import './Services.css';
import {
  FaTools,
  FaServer,
  FaNetworkWired,
  FaBolt,
  FaClock,
  FaShieldAlt,
  FaHeadset,
  FaCheckCircle,
} from 'react-icons/fa';

const DataCenterImplementation = () => {
  const steps = [
    {
      icon: <FaTools />,
      title: 'Preparation & Planning',
      description: 'Detailed planning to ensure a smooth implementation.',
      iconClass: 'blue',
    },
    {
      icon: <FaNetworkWired />,
      title: 'Infrastructure Setup',
      description: 'Installing servers, networking equipment, and cabling.',
      iconClass: 'blue',
    },
    {
      icon: <FaBolt />,
      title: 'Power & Cooling',
      description: 'Implementing efficient power and cooling solutions.',
      iconClass: 'blue',
    },
    {
      icon: <FaCheckCircle />,
      title: 'Testing & Validation',
      description: 'Ensuring everything operates as intended.',
      iconClass: 'blue',
    },
  ];

  const benefits = [
    {
      icon: <FaClock />,
      title: 'Timely Deployment',
      description: 'Minimize delays with our efficient processes.',
      iconClass: 'teal',
    },
    {
      icon: <FaShieldAlt />,
      title: 'Secure Installation',
      description: 'Security measures integrated from the start.',
      iconClass: 'teal',
    },
    {
      icon: <FaHeadset />,
      title: 'Expert Support',
      description: 'Professional assistance throughout the implementation.',
      iconClass: 'teal',
    },
    {
      icon: <FaServer />,
      title: 'Optimized Performance',
      description: 'Data centers built for peak efficiency.',
      iconClass: 'teal',
    },
  ];

  return (
    <div className="services-page">
      {/* Hero Section */}
      <section
        className="hero-section"
        style={{
          backgroundImage:
            "url('/images/services/data-center-implementation/hero-data-center-implementation.jpg')",
        }}
      >
        <div className="hero-content">
          <h1>Data Center Implementation</h1>
          <p>From hardware procurement to installation and configuration, we manage the entire process
of your data center infrastructure. Our team ensures proper use and backup of the servers
The systems, along with the network components, provide a seamless transition to business deployment.
</p>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/data-center-implementation/parallax1.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Expert Execution</h2>
        </div>
      </Parallax>

      {/* Implementation Steps Section */}
      <section className="section light-bg">
        <div className="container">
          <h2>Our Implementation Process</h2>
          <div className="grid">
            {steps.map((step, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${step.iconClass}`}>{step.icon}</div>
                <h3>{step.title}</h3>
                <p>{step.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/data-center-implementation/parallax2.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Reliable Infrastructure</h2>
        </div>
      </Parallax>

      {/* Benefits Section */}
      <section className="section dark-bg">
        <div className="container">
          <h2>Benefits</h2>
          <div className="grid">
            {benefits.map((benefit, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${benefit.iconClass}`}>{benefit.icon}</div>
                <h3>{benefit.title}</h3>
                <p>{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="cta-section">
        <h2>Ready to Implement Your Data Center?</h2>
        <a href="/contact" className="cta-button">
          Contact Us
        </a>
      </section>
    </div>
  );
};

export default DataCenterImplementation;
