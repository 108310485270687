import React from 'react';
import { Parallax } from 'react-parallax';
import './Services.css';
import {
  FaDesktop,
  FaCloud,
  FaTools,
  FaLock,
  FaSyncAlt,
  FaHeadset,
  FaNetworkWired,
  FaShieldAlt,
} from 'react-icons/fa';

const RemoteITManagement = () => {
  const features = [
    {
      icon: <FaCloud />,
      title: 'Cloud-Based Management',
      description: 'Manage your IT infrastructure remotely via the cloud.',
      iconClass: 'blue',
    },
    {
      icon: <FaDesktop />,
      title: 'Remote Desktop Support',
      description: 'Access and troubleshoot devices remotely.',
      iconClass: 'blue',
    },
    {
      icon: <FaTools />,
      title: 'Proactive Maintenance',
      description: 'Identify and resolve issues before they escalate.',
      iconClass: 'blue',
    },
    {
      icon: <FaLock />,
      title: 'Secure Connections',
      description: 'Encrypted remote sessions for security.',
      iconClass: 'blue',
    },
  ];

  const benefits = [
    {
      icon: <FaSyncAlt />,
      title: 'Increased Efficiency',
      description: 'Resolve issues quickly without on-site visits.',
      iconClass: 'teal',
    },
    {
      icon: <FaHeadset />,
      title: 'Expert Support',
      description: 'Assistance from our remote IT specialists.',
      iconClass: 'teal',
    },
    {
      icon: <FaNetworkWired />,
      title: 'Scalable Solutions',
      description: 'Easily manage multiple devices and locations.',
      iconClass: 'teal',
    },
    {
      icon: <FaShieldAlt />,
      title: 'Enhanced Security',
      description: 'Maintain security standards remotely.',
      iconClass: 'teal',
    },
  ];

  return (
    <div className="services-page">
      {/* Hero Section */}
      <section
        className="hero-section"
        style={{
          backgroundImage:
            "url('/images/services/remote-it-management/hero-remote-it-management.jpg')",
        }}
      >
        <div className="hero-content">
          <h1>Remote IT Management</h1>
          <p>Our remote management service allows us to manage and manage your IT infrastructure everywhere. We handle everything from software updates to system health checks and Troubleshooting, ensuring that your systems are always up-to-date and working properly without requiring on-site intervention.
          </p>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/remote-it-management/parallax1.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Stay Connected</h2>
        </div>
      </Parallax>

      {/* Features Section */}
      <section className="section light-bg">
        <div className="container">
          <h2>Our Remote Management Features</h2>
          <div className="grid">
            {features.map((feature, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${feature.iconClass}`}>
                  {feature.icon}
                </div>
                <h3>{feature.title}</h3>
                <p>{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/remote-it-management/parallax2.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Efficient Management</h2>
        </div>
      </Parallax>

      {/* Benefits Section */}
      <section className="section dark-bg">
        <div className="container">
          <h2>Benefits</h2>
          <div className="grid">
            {benefits.map((benefit, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${benefit.iconClass}`}>
                  {benefit.icon}
                </div>
                <h3>{benefit.title}</h3>
                <p>{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="cta-section">
        <h2>Ready to Manage IT Remotely?</h2>
        <a href="/contact" className="cta-button">
          Contact Us
        </a>
      </section>
    </div>
  );
};

export default RemoteITManagement;
