import React from 'react';
import { Parallax } from 'react-parallax';
import './Services.css';
import {
  FaComments,
  FaPhone,
  FaVideo,
  FaEnvelope,
  FaSyncAlt,
  FaHeadset,
  FaMobileAlt,
  FaUsers,
} from 'react-icons/fa';

const UnifiedCommunication = () => {
  const features = [
    {
      icon: <FaComments />,
      title: 'Integrated Messaging',
      description: 'Combine instant messaging and email in one platform.',
      iconClass: 'blue',
    },
    {
      icon: <FaPhone />,
      title: 'Voice Calls',
      description: 'Make and receive calls from any device.',
      iconClass: 'blue',
    },
    {
      icon: <FaVideo />,
      title: 'Video Conferencing',
      description: 'Host video meetings with ease.',
      iconClass: 'blue',
    },
    {
      icon: <FaEnvelope />,
      title: 'Unified Inbox',
      description: 'Manage all communications in one place.',
      iconClass: 'blue',
    },
  ];

  const benefits = [
    {
      icon: <FaSyncAlt />,
      title: 'Streamlined Communication',
      description: 'Enhance efficiency with integrated tools.',
      iconClass: 'teal',
    },
    {
      icon: <FaHeadset />,
      title: 'Expert Support',
      description: 'Assistance from our communication specialists.',
      iconClass: 'teal',
    },
    {
      icon: <FaMobileAlt />,
      title: 'Mobility',
      description: 'Stay connected on the go.',
      iconClass: 'teal',
    },
    {
      icon: <FaUsers />,
      title: 'Team Collaboration',
      description: 'Facilitate teamwork across your organization.',
      iconClass: 'teal',
    },
  ];

  return (
    <div className="services-page">
      {/* Hero Section */}
      <section
        className="hero-section"
        style={{
          backgroundImage:
            "url('/images/services/unified-communication/hero-unified-communication.jpg')",
        }}
      >
        <div className="hero-content">
          <h1>Unified Communication Platforms</h1>
          <p>Simplify your Discussion with unified platforms that combine e-mail, immediate messaging, video
conferencing, and document sharing into an unmarried solution. Our gear streamline workflows and make sure that every Transmission channel is stable, accessible, and smooth to control.
</p>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/unified-communication/parallax1.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Communicate Smarter</h2>
        </div>
      </Parallax>

      {/* Features Section */}
      <section className="section light-bg">
        <div className="container">
          <h2>Our Unified Solutions</h2>
          <div className="grid">
            {features.map((feature, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${feature.iconClass}`}>
                  {feature.icon}
                </div>
                <h3>{feature.title}</h3>
                <p>{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/unified-communication/parallax2.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Connect Your Team</h2>
        </div>
      </Parallax>

      {/* Benefits Section */}
      <section className="section dark-bg">
        <div className="container">
          <h2>Benefits</h2>
          <div className="grid">
            {benefits.map((benefit, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${benefit.iconClass}`}>
                  {benefit.icon}
                </div>
                <h3>{benefit.title}</h3>
                <p>{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="cta-section">
        <h2>Ready to Unify Your Communications?</h2>
        <a href="/contact" className="cta-button">
          Contact Us
        </a>
      </section>
    </div>
  );
};

export default UnifiedCommunication;
