import React from 'react';
import { Parallax } from 'react-parallax';
import './Services.css';
import {
  FaChartLine,
  FaCogs,
  FaTachometerAlt,
  FaArrowsAlt,
  FaDollarSign,
  FaHeadset,
  FaRecycle,
  FaShieldAlt,
} from 'react-icons/fa';

const ResourceOptimization = () => {
  const services = [
    {
      icon: <FaChartLine />,
      title: 'Performance Analysis',
      description: 'Analyze system performance to identify bottlenecks.',
      iconClass: 'blue',
    },
    {
      icon: <FaCogs />,
      title: 'Configuration Tuning',
      description: 'Adjust settings for optimal resource utilization.',
      iconClass: 'blue',
    },
    {
      icon: <FaRecycle />,
      title: 'Capacity Planning',
      description: 'Plan for future growth and resource needs.',
      iconClass: 'blue',
    },
    {
      icon: <FaShieldAlt />,
      title: 'Security Optimization',
      description: 'Ensure security measures do not hinder performance.',
      iconClass: 'blue',
    },
  ];

  const benefits = [
    {
      icon: <FaTachometerAlt />,
      title: 'Improved Performance',
      description: 'Systems run faster and more efficiently.',
      iconClass: 'teal',
    },
    {
      icon: <FaArrowsAlt />,
      title: 'Scalability',
      description: 'Easily scale resources as needed.',
      iconClass: 'teal',
    },
    {
      icon: <FaDollarSign />,
      title: 'Cost Savings',
      description: 'Reduce unnecessary spending on resources.',
      iconClass: 'teal',
    },
    {
      icon: <FaHeadset />,
      title: 'Expert Support',
      description: 'Guidance from our optimization specialists.',
      iconClass: 'teal',
    },
  ];

  return (
    <div className="services-page">
      {/* Hero Section */}
      <section
        className="hero-section"
        style={{
          backgroundImage:
            "url('/images/services/resource-optimization/hero-resource-optimization.jpg')",
        }}
      >
        <div className="hero-content">
          <h1>Resource Optimization</h1>
          <p>By virtualizing servers and desktops, we help businesses optimize and reduce their IT resources
Lowering the demand for supplementary hardware. This not only reduces costs but also reduces energy
infrastructure and physical space required for IT infrastructure.
</p>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/resource-optimization/parallax1.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Optimize Performance</h2>
        </div>
      </Parallax>

      {/* Services Section */}
      <section className="section light-bg">
        <div className="container">
          <h2>Our Optimization Services</h2>
          <div className="grid">
            {services.map((service, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${service.iconClass}`}>{service.icon}</div>
                <h3>{service.title}</h3>
                <p>{service.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/resource-optimization/parallax2.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Maximize Efficiency</h2>
        </div>
      </Parallax>

      {/* Benefits Section */}
      <section className="section dark-bg">
        <div className="container">
          <h2>Benefits</h2>
          <div className="grid">
            {benefits.map((benefit, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${benefit.iconClass}`}>{benefit.icon}</div>
                <h3>{benefit.title}</h3>
                <p>{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="cta-section">
        <h2>Ready to Optimize Your Resources?</h2>
        <a href="/contact" className="cta-button">
          Contact Us
        </a>
      </section>
    </div>
  );
};

export default ResourceOptimization;
