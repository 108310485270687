import React from 'react';
import { Parallax } from 'react-parallax';
import './Services.css';
import {
  FaTasks,
  FaTools,
  FaRocket,
  FaHeadset,
  FaClock,
  FaChartLine,
  FaShieldAlt,
} from 'react-icons/fa';

const NetworkImplementation = () => {
  const processSteps = [
    {
      icon: <FaTasks />,
      title: 'Assessment & Planning',
      description:
        'We evaluate your current infrastructure and plan the deployment.',
      iconClass: 'blue',
    },
    {
      icon: <FaTools />,
      title: 'Design & Configuration',
      description:
        'Customize network settings to meet your needs.',
      iconClass: 'blue',
    },
    {
      icon: <FaRocket />,
      title: 'Deployment',
      description:
        'Install hardware and software components efficiently.',
      iconClass: 'blue',
    },
    {
      icon: <FaHeadset />,
      title: 'Training & Support',
      description:
        'Provide training and ongoing support to your team.',
      iconClass: 'blue',
    },
  ];

  const benefits = [
    {
      icon: <FaClock />,
      title: 'Quick Deployment',
      description: 'Minimize downtime with our efficient implementation.',
      iconClass: 'teal',
    },
    {
      icon: <FaChartLine />,
      title: 'Optimized Performance',
      description: 'Experience enhanced network performance from day one.',
      iconClass: 'teal',
    },
    {
      icon: <FaShieldAlt />,
      title: 'Secure Installation',
      description: 'Implementations with security best practices in mind.',
      iconClass: 'teal',
    },
    {
      icon: <FaHeadset />,
      title: 'Expert Support',
      description: 'Receive ongoing support and maintenance.',
      iconClass: 'teal',
    },
  ];

  return (
    <div className="services-page">
      {/* Hero Section */}
      <section
        className="hero-section"
        style={{
          backgroundImage:
            "url('/images/services/network-implementation/hero-network-implementation.jpg')",
        }}
      >
        <div className="hero-content">
          <h1>Network Implementation</h1>
          <p>Our experienced team manages the whole network implementation manner, from putting in hardware to configuring software and putting in place network protocols. We ensure that your LAN, WAN, or wireless networks is deployed successfully and incorporated smoothly into your existing Infrastructure
          </p>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/network-implementation/parallax1.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Expert Deployment</h2>
        </div>
      </Parallax>

      {/* Our Process Section */}
      <section className="section light-bg">
        <div className="container">
          <h2>Our Implementation Process</h2>
          <div className="grid">
            {processSteps.map((step, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${step.iconClass}`}>{step.icon}</div>
                <h3>{step.title}</h3>
                <p>{step.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/network-implementation/parallax2.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Reliable Connectivity</h2>
        </div>
      </Parallax>

      {/* Benefits Section */}
      <section className="section dark-bg">
        <div className="container">
          <h2>Benefits</h2>
          <div className="grid">
            {benefits.map((benefit, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${benefit.iconClass}`}>{benefit.icon}</div>
                <h3>{benefit.title}</h3>
                <p>{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="cta-section">
        <h2>Ready to Implement Your Network?</h2>
        <a href="/contact" className="cta-button">
          Contact Us
        </a>
      </section>
    </div>
  );
};

export default NetworkImplementation;
