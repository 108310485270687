import React from 'react';
import { Parallax } from 'react-parallax';
import './Services.css';
import {
  FaServer,
  FaCloud,
  FaSyncAlt,
  FaShieldAlt,
  FaTachometerAlt,
  FaCogs,
  FaHeadset,
  FaDollarSign,
} from 'react-icons/fa';

const ServerSolutions = () => {
  const solutions = [
    {
      icon: <FaServer />,
      title: 'On-Premise Servers',
      description: 'Dedicated servers tailored to your needs.',
      iconClass: 'blue',
    },
    {
      icon: <FaCloud />,
      title: 'Cloud Servers',
      description: 'Scalable and flexible cloud server solutions.',
      iconClass: 'blue',
    },
    {
      icon: <FaSyncAlt />,
      title: 'Hybrid Solutions',
      description: 'Combine on-premise and cloud for optimal performance.',
      iconClass: 'blue',
    },
    {
      icon: <FaShieldAlt />,
      title: 'Server Security',
      description: 'Protect your servers with advanced security measures.',
      iconClass: 'blue',
    },
  ];

  const benefits = [
    {
      icon: <FaTachometerAlt />,
      title: 'High Performance',
      description: 'Reliable and fast server operations.',
      iconClass: 'teal',
    },
    {
      icon: <FaCogs />,
      title: 'Customization',
      description: 'Solutions tailored to your specific requirements.',
      iconClass: 'teal',
    },
    {
      icon: <FaDollarSign />,
      title: 'Cost Efficiency',
      description: 'Optimize costs with the right server solution.',
      iconClass: 'teal',
    },
    {
      icon: <FaHeadset />,
      title: 'Expert Support',
      description: 'Ongoing support from our experienced team.',
      iconClass: 'teal',
    },
  ];

  return (
    <div className="services-page">
      {/* Hero Section */}
      <section
        className="hero-section"
        style={{
          backgroundImage: "url('/images/services/server-solutions/hero-server-solutions.jpg')",
        }}
      >
        <div className="hero-content">
          <h1>Server Solutions</h1>
          <p>Our server solutions provide the backbone for your data center, reliability,
and high-performance computers. We design, configure and maintain the server infrastructure to support your businesses-critical applications, and balance performance and security with cost savings.
</p>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/server-solutions/parallax1.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Powerful Performance</h2>
        </div>
      </Parallax>

      {/* Solutions Section */}
      <section className="section light-bg">
        <div className="container">
          <h2>Our Server Solutions</h2>
          <div className="grid">
            {solutions.map((solution, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${solution.iconClass}`}>{solution.icon}</div>
                <h3>{solution.title}</h3>
                <p>{solution.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/server-solutions/parallax2.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Reliable Infrastructure</h2>
        </div>
      </Parallax>

      {/* Benefits Section */}
      <section className="section dark-bg">
        <div className="container">
          <h2>Benefits</h2>
          <div className="grid">
            {benefits.map((benefit, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${benefit.iconClass}`}>{benefit.icon}</div>
                <h3>{benefit.title}</h3>
                <p>{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="cta-section">
        <h2>Ready to Upgrade Your Servers?</h2>
        <a href="/contact" className="cta-button">
          Contact Us
        </a>
      </section>
    </div>
  );
};

export default ServerSolutions;
