// File: src/solutions-pages/CloudComputing.jsx

import React from "react";
import {
  FaCloud,
  FaCloudMoon,
  FaCloudSun,
  FaCloudUploadAlt,
  FaDraftingCompass,
  FaServer,
  FaShieldAlt,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import "./CloudComputing.css";

// Import images from the 'public' directory using 'process.env.PUBLIC_URL'
const heroImage = `${process.env.PUBLIC_URL}/images/cloud-computing/hero-cloud-computing.jpg`;
const parallaxImage = `${process.env.PUBLIC_URL}/images/cloud-computing/parallax-cloud-computing.jpg`; // Ensure you have this image
const overviewImage = `${process.env.PUBLIC_URL}/images/cloud-computing/overview-cloud-computing.jpg`;

// Service images (optional: if you have specific images for services)
const serviceImages = {
  cloudMigration: `${process.env.PUBLIC_URL}/images/services/cloud-migration.jpg`,
  cloudArchitectureDesign: `${process.env.PUBLIC_URL}/images/services/cloud-architecture-design.jpg`,
  cloudInfrastructureManagement: `${process.env.PUBLIC_URL}/images/services/cloud-infrastructure-management.jpg`,
  publicCloudSolutions: `${process.env.PUBLIC_URL}/images/services/public-cloud-solutions.jpg`,
  privateCloudSolutions: `${process.env.PUBLIC_URL}/images/services/private-cloud-solutions.jpg`,
  hybridCloudSolutions: `${process.env.PUBLIC_URL}/images/services/hybrid-cloud-solutions.jpg`,
  cloudSecurity: `${process.env.PUBLIC_URL}/images/services/cloud-security.jpg`,
};

// Brand logos (optional: similar to other pages)
const brands = [
  {
    name: "AWS",
    logo: `${process.env.PUBLIC_URL}/images/brands/aws-logo.png`,
    link: "/brands/aws",
    alt: "Amazon Web Services",
  },
  {
    name: "Microsoft Azure",
    logo: `${process.env.PUBLIC_URL}/images/brands/azure-logo.png`,
    link: "/brands/azure",
    alt: "Microsoft Azure",
  },
  {
    name: "Google Cloud",
    logo: `${process.env.PUBLIC_URL}/images/brands/google-cloud-logo.png`,
    link: "/brands/google-cloud",
    alt: "Google Cloud",
  },
  {
    name: "IBM Cloud",
    logo: `${process.env.PUBLIC_URL}/images/brands/ibm-cloud-logo.png`,
    link: "/brands/ibm-cloud",
    alt: "IBM Cloud",
  },
];

const services = [
  {
    title: "Cloud Migration",
    description:
      "Seamlessly migrate your existing infrastructure and applications to the cloud with minimal downtime.",
    icon: <FaCloudUploadAlt />,
    image: serviceImages.cloudMigration, // Optional: if you have service images
    link: "/services/cloud-migration",
  },
  {
    title: "Cloud Architecture Design",
    description:
      "Design scalable and robust cloud architectures tailored to your business needs.",
    icon: <FaDraftingCompass />,
    image: serviceImages.cloudArchitectureDesign,
    link: "/services/cloud-architecture-design",
  },
  {
    title: "Cloud Infrastructure Management",
    description:
      "Manage and optimize your cloud infrastructure for performance and cost-efficiency.",
    icon: <FaServer />,
    image: serviceImages.cloudInfrastructureManagement,
    link: "/services/cloud-infrastructure-management",
  },
  {
    title: "Public Cloud Solutions",
    description:
      "Leverage public cloud services like AWS, Azure, and Google Cloud for scalability and flexibility.",
    icon: <FaCloud />,
    image: serviceImages.publicCloudSolutions,
    link: "/services/public-cloud-solutions",
  },
  {
    title: "Private Cloud Solutions",
    description:
      "Implement private cloud solutions for enhanced security and control over your data.",
    icon: <FaCloudSun />,
    image: serviceImages.privateCloudSolutions,
    link: "/services/private-cloud-solutions",
  },
  {
    title: "Hybrid Cloud Solutions",
    description:
      "Combine the best of both public and private clouds with our hybrid cloud solutions.",
    icon: <FaCloudMoon />,
    image: serviceImages.hybridCloudSolutions,
    link: "/services/hybrid-cloud-solutions",
  },
  {
    title: "Cloud Security",
    description:
      "Secure your cloud environment with advanced security measures and compliance adherence.",
    icon: <FaShieldAlt />,
    image: serviceImages.cloudSecurity,
    link: "/services/cloud-security",
  },
];

const CloudComputing = () => {
  return (
    <div className="cloud-computing">
      {/* Hero Section */}
      <section
        className="hero-section"
        style={{
          backgroundImage: `url(${heroImage})`,
        }}
      >
        <div className="hero-overlay">
          <h1>Cloud Computing Solutions</h1>
          <p>
            Transform your business with our comprehensive cloud computing
            services. From migration to management, we provide end-to-end
            solutions that drive efficiency and innovation.
          </p>
          <Link to="/contact" className="hero-button">
            Get Started
          </Link>
        </div>
        <div className="scroll-down-indicator"></div>
      </section>

      {/* Overview Section */}
      <section className="overview-section">
        <div className="container">
          <h2>Embrace the Future with Cloud Computing</h2>
          <div className="overview-content">
            <div className="overview-text">
              <p>
                In today's fast-paced digital landscape, cloud computing is
                essential for agility and scalability. Our expert team helps you
                harness the power of the cloud to streamline operations and
                accelerate growth.
              </p>
              <p>
                We partner with leading cloud providers to offer customized
                solutions that meet your unique business requirements.
              </p>
            </div>
            <div className="overview-image">
              <img src={overviewImage} alt="Cloud Computing Overview" />
            </div>
          </div>
        </div>
      </section>

      {/* Services Offered Section */}
      <section className="services-section">
        <div className="container">
          <h2>Our Cloud Computing Services</h2>
          <div className="services-grid">
            {services.map((service, index) => (
              <Link to={service.link} className="service-card" key={index}>
                <div
                  className="service-image"
                  style={{ backgroundImage: `url(${service.image})` }}
                >
                  <div className="service-icon">{service.icon}</div>
                </div>
                <div className="service-content">
                  <h3>{service.title}</h3>
                  <p>{service.description}</p>
                  <span className="read-more">Read More</span>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </section>

      {/* Parallax Section */}
      <section
        className="parallax-section"
        style={{
          backgroundImage: `url(${parallaxImage})`,
        }}
      >
        <div className="parallax-overlay">
          <h2>Comprehensive Cloud Solutions for Modern Businesses</h2>
          <p>
            Enhance your IT infrastructure with our state-of-the-art cloud
            computing services designed to meet your unique business needs.
          </p>
          <Link to="/solutions" className="parallax-button">
            Learn More
          </Link>
        </div>
      </section>

      {/* Call to Action */}
      <section className="cta-section">
        <div className="container">
          <h2>Ready to Elevate Your Business to the Cloud?</h2>
          <p>
            Contact us today for a consultation and discover how our cloud
            computing services can transform your operations.
          </p>
          <Link to="/contact" className="cta-button">
            Contact Us
          </Link>
        </div>
      </section>
    </div>
  );
};

export default CloudComputing;
