import React from 'react';
import { Parallax } from 'react-parallax';
import './Services.css';
import {
  FaMicrochip,
  FaSatelliteDish,
  FaChartArea,
  FaCogs,
  FaChartLine,
  FaHeadset,
  FaCloud,
  FaMobileAlt,
} from 'react-icons/fa';

const SensorsEdgeDevices = () => {
  const features = [
    {
      icon: <FaMicrochip />,
      title: 'Sensor Integration',
      description: 'Deploy and integrate various IoT sensors.',
      iconClass: 'blue',
    },
    {
      icon: <FaSatelliteDish />,
      title: 'Edge Computing',
      description: 'Process data locally on edge devices.',
      iconClass: 'blue',
    },
    {
      icon: <FaCogs />,
      title: 'Custom Device Development',
      description: 'Design devices tailored to your requirements.',
      iconClass: 'blue',
    },
    {
      icon: <FaChartArea />,
      title: 'Data Acquisition',
      description: 'Collect data from multiple sources efficiently.',
      iconClass: 'blue',
    },
  ];

  const benefits = [
    {
      icon: <FaChartLine />,
      title: 'Real-Time Insights',
      description: 'Get immediate data analysis at the edge.',
      iconClass: 'teal',
    },
    {
      icon: <FaCloud />,
      title: 'Reduced Latency',
      description: 'Improve response times by processing data locally.',
      iconClass: 'teal',
    },
    {
      icon: <FaMobileAlt />,
      title: 'Scalable Solutions',
      description: 'Easily expand your network of devices.',
      iconClass: 'teal',
    },
    {
      icon: <FaHeadset />,
      title: 'Expert Support',
      description: 'Assistance from our IoT device specialists.',
      iconClass: 'teal',
    },
  ];

  return (
    <div className="services-page">
      {/* Hero Section */}
      <section
        className="hero-section"
        style={{
          backgroundImage:
            "url('/images/services/sensors-edge-devices/hero-sensors-edge-devices.jpg')",
        }}
      >
        <div className="hero-content">
          <h1>Sensors & Edge Devices</h1>
          <p>We provide a huge variety of IoT sensors and area gadgets that capture treasured statistics out of your
environment. Our specialists assist you pick the proper gadgets on your needs, whether or not for tracking, monitoring, or automation, making sure stable and dependable facts collection.
</p>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/sensors-edge-devices/parallax1.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Connect to the Edge</h2>
        </div>
      </Parallax>

      {/* Features Section */}
      <section className="section light-bg">
        <div className="container">
          <h2>Our Sensors & Edge Services</h2>
          <div className="grid">
            {features.map((feature, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${feature.iconClass}`}>{feature.icon}</div>
                <h3>{feature.title}</h3>
                <p>{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/sensors-edge-devices/parallax2.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Empower Your Data</h2>
        </div>
      </Parallax>

      {/* Benefits Section */}
      <section className="section dark-bg">
        <div className="container">
          <h2>Benefits</h2>
          <div className="grid">
            {benefits.map((benefit, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${benefit.iconClass}`}>{benefit.icon}</div>
                <h3>{benefit.title}</h3>
                <p>{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="cta-section">
        <h2>Ready to Deploy Sensors & Edge Devices?</h2>
        <a href="/contact" className="cta-button">
          Contact Us
        </a>
      </section>
    </div>
  );
};

export default SensorsEdgeDevices;
