// src/components/InfrastructureService.jsx

import React from 'react';
import './InfrastructureService.css'; // Import the CSS file
import {
  FaLaptop,
  FaWifi,
  FaNetworkWired,
  FaDollarSign,
  FaAdjust,
  FaCode,
  FaHandHolding,
  FaShieldAlt,
} from 'react-icons/fa';

const services = [
  {
    title: 'Laptops and Desktops as a Service',
    description:
      'Rent high-performance laptops and desktops that cater to your specific operational needs. Our selection includes the latest models from leading manufacturers, ensuring that your team has access to reliable and powerful devices.',
    features: ['Customizable configurations to fit various user requirements.'],
    icon: <FaLaptop />,
  },
  {
    title: 'Wi-Fi as a Service',
    description:
      'Achieve seamless connectivity across your organization with our managed Wi-Fi solutions. We provide the necessary hardware, installation, and ongoing support, ensuring that your network remains robust and efficient.',
    features: ['Scalable solutions that grow with your business.'],
    icon: <FaWifi />,
  },
  {
    title: 'Switching as a Service',
    description:
      'Optimize your network infrastructure with our switching solutions. We offer scalable switches that can adapt to your growing needs, ensuring reliable performance and connectivity.',
    features: ['Easy integration with existing systems.'],
    icon: <FaNetworkWired />,
  },
  {
    title: 'Routing as a Service',
    description:
      "Enhance your network's efficiency with our Routing as a Service which includes advanced capabilities such as firewall integration, SD-WAN gateways, and multi-WAN routers. This service provides advanced routing solutions that ensure optimal data flow across your organization’s network.",
    features: [
      'Centralized network management with a single interface for monitoring and configuring routing protocols.',
    ],
    icon: <FaNetworkWired />,
  },
];

const benefits = [
  {
    title: 'Cost Efficiency',
    description:
      'Infrastructure as a service allows you to significantly reduce upfront capital expenditures. Instead of purchasing hardware outright, you can rent it, freeing up budget for other critical areas of your business.',
    icon: <FaDollarSign />,
  },
  {
    title: 'Flexibility',
    description:
      'With a minimum lock-in period of just one year, our services provide the flexibility you need to adapt to changing business conditions. Whether you need to scale up or down, our services can be adjusted accordingly.',
    icon: <FaAdjust />,
  },
  {
    title: 'Access to the Latest Technology',
    description:
      'Stay ahead in the fast-paced tech landscape by having access to the latest hardware without the hassle of frequent upgrades. We ensure that you are equipped with cutting-edge technology that enhances productivity.',
    icon: <FaCode />,
  },
  {
    title: 'Comprehensive Support',
    description:
      'Enjoy peace of mind with our dedicated IT support and management services. From installation and maintenance to troubleshooting, we handle all aspects of your hardware needs, allowing you to focus on what you do.',
    icon: <FaHandHolding />,
  },
];

const InfrastructureService = () => {
  return (
    <div className="infra-container">
      <h1 className="infra-header">Infrastructure as a Service</h1>

      <section className="infra-intro">
        <p>
          At Rajguru Distributors, we recognize that businesses today require flexible and efficient IT solutions to stay competitive. Our Infrastructure as a Service offering is tailored to meet these demands, providing you with access to essential hardware without the burdens of ownership.
        </p>
      </section>

      <section className="infra-section">
        <h2 className="infra-section-title">Comprehensive Solutions</h2>
        <div className="infra-services">
          {services.map((service, index) => (
            <div className="infra-card" key={index}>
              <div className="infra-card-icon">{service.icon}</div>
              <h3 className="infra-card-title">{service.title}</h3>
              <p className="infra-card-description">{service.description}</p>
              <ul className="infra-card-features">
                {service.features.map((feature, idx) => (
                  <li key={idx}>
                    <FaShieldAlt className="feature-icon" />
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </section>

      <section className="infra-section">
        <h2 className="infra-section-title">Why Choose Hardware as a Service?</h2>
        <div className="infra-benefits">
          {benefits.map((benefit, index) => (
            <div className="infra-benefit-card" key={index}>
              <div className="infra-benefit-icon">{benefit.icon}</div>
              <h3 className="infra-benefit-title">{benefit.title}</h3>
              <p className="infra-benefit-description">{benefit.description}</p>
            </div>
          ))}
        </div>
      </section>

      <section className="infra-section">
        <h2 className="infra-section-title">Why Rajguru Distributors?</h2>
        <p className="infra-about">
          As a trusted provider in IT infrastructure solutions, Rajguru Distributors is committed to delivering high-quality services tailored to meet the unique needs of our clients. Our expertise in cloud computing, virtualization, and cybersecurity complements our infrastructure offerings, ensuring a comprehensive solution for your business.
        </p>
      </section>

      <section className="infra-section center">
        <a href="/contact" className="infra-button">
          Get Started Today!
        </a>
      </section>
    </div>
  );
};

export default InfrastructureService;
