import { default as React } from "react";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom"; // Import useNavigate hook
import Slider from "react-slick"; // Import Slider from react-slick
import "./JuniperPage.css"; // Custom styling for Juniper page
import "./MicronPage.css"; // Custom styling for Micron page
import "./MicrosoftPage.css"; // Custom styling for Microsoft page

// Import slick-carousel CSS
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

// Carousel settings
const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3, // Number of slides to show on desktop
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000, // 3 seconds
  responsive: [
    {
      breakpoint: 1200, // Tablet breakpoint
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 768, // Mobile breakpoint
      settings: {
        slidesToShow: 1,
      },
    },
  ],
  arrows: false, // Hide arrows for a cleaner look
};
// Product Data
const products = [
  {
    name: "Business solutions",
    // description:
    //   "A 2U rack-mounted NAS designed for enterprise-level storage with redundancy and high performance.",
    image: "/images/products/microsoft/microsoft-business-solutions.jpg",
  },
  {
    name: "Enterprise solutions",
    // description:
    //   "A powerful rack-mounted NAS with advanced data management, suitable for SMBs requiring virtualization support.",
    image: "/images/products/microsoft/microsoft-enterprise-solutions.png",
  },
  {
    name: "Business intelligence and analytics",
    // description:
    //   "A desktop NAS offering high scalability and performance, ideal for small to medium-sized businesses.",
    image:
      "/images/products/microsoft/microsoft-business-intelligence-analytics.jpg",
  },
  {
    name: "Cloud solutions",
    // description:
    //   "A desktop NAS offering high scalability and performance, ideal for small to medium-sized businesses.",
    image: "/images/products/microsoft/microsoft-cloud-solutions.jpg",
  },
  {
    name: "Collaboration and communications solutions",
    // description:
    //   "A desktop NAS offering high scalability and performance, ideal for small to medium-sized businesses.",
    image:
      "/images/products/microsoft/microsoft-collaboration-communication-solutions.jpg",
  },
  {
    name: "Data storage solutions",
    // description:
    //   "A desktop NAS offering high scalability and performance, ideal for small to medium-sized businesses.",
    image: "/images/products/microsoft/microsoft-data-storage-solutions.jpg",
  },
  {
    name: "Industry specific solutions",
    // description:
    //   "A desktop NAS offering high scalability and performance, ideal for small to medium-sized businesses.",
    image:
      "/images/products/microsoft/microsoft-industry-specific-solutions.jpg",
  },
  {
    name: "Virtualization and remote work solutions",
    // description:
    //   "A desktop NAS offering high scalability and performance, ideal for small to medium-sized businesses.",
    image:
      "/images/products/microsoft/microsoft-virtualization-remote-work-solutions.jpg",
  },
];

const MicrosoftPage = () => {
  const navigate = useNavigate(); // Initialize navigate

  return (
    <div className="microsoft-page-container">
      <div className="microsoft-hero-container">
        <div className="hero-text">
          <h1>Microsoft</h1>
          <h2>Empowering Every Person and Organization</h2>
          <p>
            Microsoft is a global leader in software, services, devices, and
            solutions that help people and businesses realize their full
            potential. From Windows to Azure, Microsoft continues to drive
            digital transformation through innovation and cloud-based services.
          </p>
          <button
            className="learn-more-button"
            onClick={() => navigate("/contact")}
          >
            Learn More
          </button>
        </div>
        <div className="hero-image">
          <img src="/images/logos/microsoft-logo.svg" alt="Microsoft Logo" />
        </div>
      </div>

      {/* Product Carousel Section */}
      <div className="product-carousel-section">
        <h2 className="carousel-title">Our Microsoft Solutions</h2>
        <Slider {...settings} aria-label="Product Carousel">
          {products.map((product, index) => (
            <div key={index} className="product-card">
              <a
                href={`/products/${product.name}`}
                className="product-link"
                aria-label={`Learn more about ${product.name}`}
              >
                <div className="product-image-container">
                  <img
                    src={product.image}
                    alt={`${product.name} Logo`}
                    className="product-image"
                    loading="lazy"
                  />
                </div>
                <h3 className="product-name">{product.name}</h3>
                <p className="product-description">{product.description}</p>
              </a>
            </div>
          ))}
        </Slider>
      </div>

      <div className="video-container">
        <div className="video-responsive">
          <ReactPlayer
            url="https://www.youtube.com/watch?v=Fjo20qYsXqc"
            controls
            width="100%"
            height="100%"
            className="react-player"
          />
        </div>
      </div>
    </div>
  );
};

export default MicrosoftPage;
