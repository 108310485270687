import React from 'react';
import { Parallax } from 'react-parallax';
import './Services.css';
import {
  FaServer,
  FaSyncAlt,
  FaCogs,
  FaCloud,
  FaTachometerAlt,
  FaDollarSign,
  FaHeadset,
  FaShieldAlt,
} from 'react-icons/fa';

const ServerVirtualization = () => {
  const solutions = [
    {
      icon: <FaServer />,
      title: 'Hypervisor Implementation',
      description: 'Deploy hypervisors to create virtual servers.',
      iconClass: 'blue',
    },
    {
      icon: <FaSyncAlt />,
      title: 'Migration Services',
      description: 'Migrate physical servers to virtual environments.',
      iconClass: 'blue',
    },
    {
      icon: <FaCogs />,
      title: 'Resource Allocation',
      description: 'Optimize resource usage across virtual servers.',
      iconClass: 'blue',
    },
    {
      icon: <FaCloud />,
      title: 'Cloud Integration',
      description: 'Integrate virtual servers with cloud platforms.',
      iconClass: 'blue',
    },
  ];

  const benefits = [
    {
      icon: <FaTachometerAlt />,
      title: 'Improved Efficiency',
      description: 'Maximize server utilization and performance.',
      iconClass: 'teal',
    },
    {
      icon: <FaDollarSign />,
      title: 'Cost Savings',
      description: 'Reduce hardware and operational costs.',
      iconClass: 'teal',
    },
    {
      icon: <FaShieldAlt />,
      title: 'Enhanced Security',
      description: 'Isolate environments for better security.',
      iconClass: 'teal',
    },
    {
      icon: <FaHeadset />,
      title: 'Expert Support',
      description: 'Assistance from our virtualization specialists.',
      iconClass: 'teal',
    },
  ];

  return (
    <div className="services-page">
      {/* Hero Section */}
      <section
        className="hero-section"
        style={{
          backgroundImage:
            "url('/images/services/server-virtualization/hero-server-virtualization.jpg')",
        }}
      >
        <div className="hero-content">
          <h1>Server Virtualization</h1>
          <p>Optimize your server infrastructure with virtualization.</p>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/server-virtualization/parallax1.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Maximize Efficiency</h2>
        </div>
      </Parallax>

      {/* Solutions Section */}
      <section className="section light-bg">
        <div className="container">
          <h2>Our Server Virtualization Services</h2>
          <div className="grid">
            {solutions.map((solution, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${solution.iconClass}`}>{solution.icon}</div>
                <h3>{solution.title}</h3>
                <p>{solution.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/server-virtualization/parallax2.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Flexible Infrastructure</h2>
        </div>
      </Parallax>

      {/* Benefits Section */}
      <section className="section dark-bg">
        <div className="container">
          <h2>Benefits</h2>
          <div className="grid">
            {benefits.map((benefit, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${benefit.iconClass}`}>{benefit.icon}</div>
                <h3>{benefit.title}</h3>
                <p>{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="cta-section">
        <h2>Ready to Virtualize Your Servers?</h2>
        <a href="/contact" className="cta-button">
          Contact Us
        </a>
      </section>
    </div>
  );
};

export default ServerVirtualization;
