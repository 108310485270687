// File: src/solutions-pages/CollaborationTools.jsx

import React from "react";
import {
  FaComments,
  FaEnvelope,
  FaFolderOpen,
  FaLayerGroup,
  FaVideo,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import "./CollaborationTools.css";

// Import images from the 'public' directory using 'process.env.PUBLIC_URL'
const heroImage = `${process.env.PUBLIC_URL}/images/collaboration-tools/hero-collaboration-tools.jpg`;
const parallaxImage = `${process.env.PUBLIC_URL}/images/collaboration-tools/parallax-collaboration-tools.jpg`; // Ensure you have this image
const overviewImage = `${process.env.PUBLIC_URL}/images/collaboration-tools/overview-collaboration-tools.jpg`;

// Service images (optional: if you have specific images for services)
const serviceImages = {
  secureEmailSolutions: `${process.env.PUBLIC_URL}/images/services/secure-email-solutions.jpg`,
  instantMessaging: `${process.env.PUBLIC_URL}/images/services/instant-messaging.jpg`,
  videoConferencingSolutions: `${process.env.PUBLIC_URL}/images/services/video-conferencing-solutions.jpg`,
  fileSharingCollaboration: `${process.env.PUBLIC_URL}/images/services/file-sharing-collaboration.jpg`,
  unifiedCommunicationPlatforms: `${process.env.PUBLIC_URL}/images/services/unified-communication-platforms.jpg`,
};

// Brand logos (optional: similar to other pages)
const brands = [
  {
    name: "Slack",
    logo: `${process.env.PUBLIC_URL}/images/brands/slack-logo.png`,
    link: "/brands/slack",
    alt: "Slack",
  },
  {
    name: "Microsoft Teams",
    logo: `${process.env.PUBLIC_URL}/images/brands/microsoft-teams-logo.png`,
    link: "/brands/microsoft-teams",
    alt: "Microsoft Teams",
  },
  {
    name: "Zoom",
    logo: `${process.env.PUBLIC_URL}/images/brands/zoom-logo.png`,
    link: "/brands/zoom",
    alt: "Zoom",
  },
  {
    name: "Google Workspace",
    logo: `${process.env.PUBLIC_URL}/images/brands/google-workspace-logo.png`,
    link: "/brands/google-workspace",
    alt: "Google Workspace",
  },
];

const services = [
  {
    title: "Secure Email Solutions",
    description:
      "Enhance your email communication with secure and encrypted email services.",
    icon: <FaEnvelope />,
    image: serviceImages.secureEmailSolutions, // Optional: if you have service images
    link: "/services/secure-email-solutions",
  },
  {
    title: "Instant Messaging",
    description:
      "Facilitate real-time communication with instant messaging platforms tailored for business.",
    icon: <FaComments />,
    image: serviceImages.instantMessaging,
    link: "/services/instant-messaging",
  },
  {
    title: "Video Conferencing Solutions",
    description:
      "Connect with teams and clients through high-quality video conferencing tools.",
    icon: <FaVideo />,
    image: serviceImages.videoConferencingSolutions,
    link: "/services/video-conferencing-solutions",
  },
  {
    title: "File Sharing & Collaboration",
    description:
      "Collaborate efficiently with secure file sharing and collaborative document editing.",
    icon: <FaFolderOpen />,
    image: serviceImages.fileSharingCollaboration,
    link: "/services/file-sharing-collaboration",
  },
  {
    title: "Unified Communication Platforms",
    description:
      "Integrate all your communication channels into a single, cohesive platform.",
    icon: <FaLayerGroup />,
    image: serviceImages.unifiedCommunicationPlatforms,
    link: "/services/unified-communication-platforms",
  },
];

const CollaborationTools = () => {
  return (
    <div className="collaboration-tools">
      {/* Hero Section */}
      <section
        className="hero-section"
        style={{
          backgroundImage: `url(${heroImage})`,
        }}
      >
        <div className="hero-overlay">
          <h1>Collaboration Tools</h1>
          <p>
            Empower your team with cutting-edge collaboration tools that boost
            productivity and streamline communication.
          </p>
          <Link to="/contact" className="hero-button">
            Get Started
          </Link>
        </div>
        <div className="scroll-down-indicator"></div>
      </section>

      {/* Overview Section */}
      <section className="overview-section">
        <div className="container">
          <h2>Enhance Team Collaboration</h2>
          <div className="overview-content">
            <div className="overview-text">
              <p>
                In today's interconnected work environment, effective
                collaboration tools are essential. We offer a suite of solutions
                designed to improve communication and collaboration within your
                organization.
              </p>
              <p>
                From secure email to unified communication platforms, our
                services help your team work together seamlessly.
              </p>
            </div>
            <div className="overview-image">
              <img src={overviewImage} alt="Collaboration Tools Overview" />
            </div>
          </div>
        </div>
      </section>

      {/* Services Offered Section */}
      <section className="services-section">
        <div className="container">
          <h2>Our Collaboration Tools Services</h2>
          <div className="services-grid">
            {services.map((service, index) => (
              <Link to={service.link} className="service-card" key={index}>
                <div
                  className="service-image"
                  style={{ backgroundImage: `url(${service.image})` }}
                >
                  <div className="service-icon">{service.icon}</div>
                </div>
                <div className="service-content">
                  <h3>{service.title}</h3>
                  <p>{service.description}</p>
                  <span className="read-more">Read More</span>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </section>

      {/* Parallax Section */}
      <section
        className="parallax-section"
        style={{
          backgroundImage: `url(${parallaxImage})`,
        }}
      >
        <div className="parallax-overlay">
          <h2>Comprehensive Collaboration Tools for Modern Businesses</h2>
          <p>
            Enhance your team's productivity and communication with our
            state-of-the-art collaboration tools designed to meet your unique
            business needs.
          </p>
          <Link to="/solutions" className="parallax-button">
            Learn More
          </Link>
        </div>
      </section>

      {/* Call to Action */}
      <section className="cta-section">
        <div className="container">
          <h2>Ready to Boost Your Team's Productivity?</h2>
          <p>
            Contact us today for a consultation and discover how our
            collaboration tools can transform your business communication.
          </p>
          <Link to="/contact" className="cta-button">
            Contact Us
          </Link>
        </div>
      </section>
    </div>
  );
};

export default CollaborationTools;
