import React from 'react';
import { Parallax } from 'react-parallax';
import './Services.css';
import {
  FaShieldAlt,
  FaEye,
  FaBug,
  FaLock,
  FaCogs,
  FaHeadset,
  FaCheckCircle,
  FaBrain,
} from 'react-icons/fa';

const IntrusionDetection = () => {
  const services = [
    {
      icon: <FaEye />,
      title: '24/7 Monitoring',
      description: 'Continuous network surveillance.',
      iconClass: 'blue',
    },
    {
      icon: <FaBug />,
      title: 'Threat Detection',
      description: 'Identify and alert on suspicious activities.',
      iconClass: 'blue',
    },
    {
      icon: <FaShieldAlt />,
      title: 'Prevention Systems',
      description: 'Block malicious traffic in real-time.',
      iconClass: 'blue',
    },
    {
      icon: <FaLock />,
      title: 'Security Policies',
      description: 'Custom rules to protect your assets.',
      iconClass: 'blue',
    },
  ];

  const benefits = [
    {
      icon: <FaCogs />,
      title: 'Proactive Defense',
      description: 'Prevent attacks before they cause damage.',
      iconClass: 'teal',
    },
    {
      icon: <FaBrain />,
      title: 'Intelligent Systems',
      description: 'Use AI to detect advanced threats.',
      iconClass: 'teal',
    },
    {
      icon: <FaCheckCircle />,
      title: 'Regulatory Compliance',
      description: 'Meet security standards and regulations.',
      iconClass: 'teal',
    },
    {
      icon: <FaHeadset />,
      title: 'Expert Support',
      description: 'Assistance from our security experts.',
      iconClass: 'teal',
    },
  ];

  return (
    <div className="services-page">
      {/* Hero Section */}
      <section
        className="hero-section"
        style={{
          backgroundImage:
            "url('/images/services/intrusion-detection/hero-intrusion-detection.jpg')",
        }}
      >
        <div className="hero-content">
          <h1>Intrusion Detection & Prevention</h1>
          <p>Our intrusion detection and prevention systems (IDPS) help come across and block suspicious activities
in your system before they turn out to be a hassle. We continuously monitor your network for
potential threats and take instantaneous motion to mitigate dangers, ensuring minimal disruption to your
commercial enterprise.


</p>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/intrusion-detection/parallax1.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Stay Vigilant</h2>
        </div>
      </Parallax>

      {/* Services Section */}
      <section className="section light-bg">
        <div className="container">
          <h2>Our Intrusion Services</h2>
          <div className="grid">
            {services.map((service, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${service.iconClass}`}>
                  {service.icon}
                </div>
                <h3>{service.title}</h3>
                <p>{service.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/intrusion-detection/parallax2.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Protect Your Assets</h2>
        </div>
      </Parallax>

      {/* Benefits Section */}
      <section className="section dark-bg">
        <div className="container">
          <h2>Benefits</h2>
          <div className="grid">
            {benefits.map((benefit, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${benefit.iconClass}`}>
                  {benefit.icon}
                </div>
                <h3>{benefit.title}</h3>
                <p>{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="cta-section">
        <h2>Ready to Enhance Your Security?</h2>
        <a href="/contact" className="cta-button">
          Contact Us
        </a>
      </section>
    </div>
  );
};

export default IntrusionDetection;
