import React from 'react';
import { Parallax } from 'react-parallax';
import './Services.css';
import {
  FaCloud,
  FaGlobe,
  FaArrowsAlt,
  FaCogs,
  FaDollarSign,
  FaShieldAlt,
  FaHeadset,
  FaChartBar,
} from 'react-icons/fa';

const PublicCloudSolutions = () => {
  const solutions = [
    {
      icon: <FaGlobe />,
      title: 'Global Accessibility',
      description: 'Access your resources from anywhere.',
      iconClass: 'blue',
    },
    {
      icon: <FaArrowsAlt />,
      title: 'Scalability',
      description: 'Scale resources up or down based on demand.',
      iconClass: 'blue',
    },
    {
      icon: <FaCogs />,
      title: 'Managed Services',
      description: 'Let us handle your cloud infrastructure.',
      iconClass: 'blue',
    },
    {
      icon: <FaShieldAlt />,
      title: 'Security Compliance',
      description: 'Adherence to industry-standard security practices.',
      iconClass: 'blue',
    },
  ];

  const benefits = [
    {
      icon: <FaDollarSign />,
      title: 'Cost Efficiency',
      description: 'Pay only for what you use.',
      iconClass: 'teal',
    },
    {
      icon: <FaChartBar />,
      title: 'High Performance',
      description: 'Leverage powerful cloud infrastructure.',
      iconClass: 'teal',
    },
    {
      icon: <FaHeadset />,
      title: 'Expert Support',
      description: 'Assistance from our cloud specialists.',
      iconClass: 'teal',
    },
    {
      icon: <FaShieldAlt />,
      title: 'Enhanced Security',
      description: 'Protect your data with advanced security measures.',
      iconClass: 'teal',
    },
  ];

  return (
    <div className="services-page">
      {/* Hero Section */}
      <section
        className="hero-section"
        style={{
          backgroundImage:
            "url('/images/services/public-cloud-solutions/hero-public-cloud-solutions.jpg')",
        }}
      >
        <div className="hero-content">
          <h1>Public Cloud Solutions</h1>
          <p>Leveraged the power of leading public cloud platforms with our solutions. We support projects that use public clouds like AWS, Microsoft Azure, and Google Cloud to increase flexibility, scalability, and cost-effectiveness by ensuring robust security measures are implemented.
          </p>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/public-cloud-solutions/parallax1.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Embrace the Cloud</h2>
        </div>
      </Parallax>

      {/* Solutions Section */}
      <section className="section light-bg">
        <div className="container">
          <h2>Our Public Cloud Services</h2>
          <div className="grid">
            {solutions.map((solution, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${solution.iconClass}`}>{solution.icon}</div>
                <h3>{solution.title}</h3>
                <p>{solution.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/public-cloud-solutions/parallax2.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Scale with Ease</h2>
        </div>
      </Parallax>

      {/* Benefits Section */}
      <section className="section dark-bg">
        <div className="container">
          <h2>Benefits</h2>
          <div className="grid">
            {benefits.map((benefit, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${benefit.iconClass}`}>{benefit.icon}</div>
                <h3>{benefit.title}</h3>
                <p>{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="cta-section">
        <h2>Ready to Go Public with the Cloud?</h2>
        <a href="/contact" className="cta-button">
          Contact Us
        </a>
      </section>
    </div>
  );
};

export default PublicCloudSolutions;
