import React from 'react';
import { Parallax } from 'react-parallax';
import './Services.css';
import {
  FaComments,
  FaLock,
  FaMobileAlt,
  FaUsers,
  FaSyncAlt,
  FaHeadset,
  FaPaperPlane,
  FaSmile,
} from 'react-icons/fa';

const InstantMessaging = () => {
  const features = [
    {
      icon: <FaComments />,
      title: 'Real-Time Communication',
      description: 'Instant messaging for quick collaboration.',
      iconClass: 'blue',
    },
    {
      icon: <FaLock />,
      title: 'Secure Messaging',
      description: 'End-to-end encryption for all conversations.',
      iconClass: 'blue',
    },
    {
      icon: <FaMobileAlt />,
      title: 'Cross-Platform Support',
      description: 'Available on desktop and mobile devices.',
      iconClass: 'blue',
    },
    {
      icon: <FaUsers />,
      title: 'Group Chats',
      description: 'Communicate with teams efficiently.',
      iconClass: 'blue',
    },
  ];

  const benefits = [
    {
      icon: <FaSyncAlt />,
      title: 'Seamless Collaboration',
      description: 'Improve team communication and productivity.',
      iconClass: 'teal',
    },
    {
      icon: <FaHeadset />,
      title: 'Expert Support',
      description: 'Assistance from our communication specialists.',
      iconClass: 'teal',
    },
    {
      icon: <FaPaperPlane />,
      title: 'Quick Setup',
      description: 'Get started with minimal configuration.',
      iconClass: 'teal',
    },
    {
      icon: <FaSmile />,
      title: 'User-Friendly Interface',
      description: 'Easy to use for everyone in your organization.',
      iconClass: 'teal',
    },
  ];

  return (
    <div className="services-page">
      {/* Hero Section */}
      <section
        className="hero-section"
        style={{
          backgroundImage:
            "url('/images/services/instant-messaging/hero-instant-messaging.jpg')",
        }}
      >
        <div className="hero-content">
          <h1>Instant Messaging</h1>
          <p>Enable quick and green Conversations with our secure immediate messaging structures. Our IM
answers permit group individuals to collaborate in real-time, proportion documents, and prepare conversations, all while making sure that your messages are blanketed from interception and data breaches.
</p>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/instant-messaging/parallax1.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Communicate Effortlessly</h2>
        </div>
      </Parallax>

      {/* Features Section */}
      <section className="section light-bg">
        <div className="container">
          <h2>Our Messaging Features</h2>
          <div className="grid">
            {features.map((feature, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${feature.iconClass}`}>
                  {feature.icon}
                </div>
                <h3>{feature.title}</h3>
                <p>{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/instant-messaging/parallax2.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Stay Connected</h2>
        </div>
      </Parallax>

      {/* Benefits Section */}
      <section className="section dark-bg">
        <div className="container">
          <h2>Benefits</h2>
          <div className="grid">
            {benefits.map((benefit, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${benefit.iconClass}`}>
                  {benefit.icon}
                </div>
                <h3>{benefit.title}</h3>
                <p>{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="cta-section">
        <h2>Ready to Improve Communication?</h2>
        <a href="/contact" className="cta-button">
          Contact Us
        </a>
      </section>
    </div>
  );
};

export default InstantMessaging;
