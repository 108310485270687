import React from "react";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom"; // Import useNavigate hook
import Slider from "react-slick"; // Import Slider from react-slick
import "./SynologyPage.css"; // Custom styling for Synology page
import "./VolktekPage.css"; // Custom styling for Voltek page

// Import slick-carousel CSS
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

// Product Data
const products = [
  {
    name: "Enterprise networking solutions",
    // description:
    //   "A 2U rack-mounted NAS designed for enterprise-level storage with redundancy and high performance.",
    image:
      "/images/products/volktek/volktek-enterprise-networking-solutions.jpg",
  },
  {
    name: "Ethernet solutions for enterprise",
    // description:
    //   "A powerful rack-mounted NAS with advanced data management, suitable for SMBs requiring virtualization support.",
    image: "/images/products/volktek/volktek-ethernet-solutions.jpg",
  },
  {
    name: "Internet grade switching",
    // description:
    //   "A desktop NAS offering high scalability and performance, ideal for small to medium-sized businesses.",
    image: "/images/products/volktek/volktek-internet-grade-switching.png",
  },
];

// Carousel settings
const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3, // Number of slides to show on desktop
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000, // 3 seconds
  responsive: [
    {
      breakpoint: 1200, // Tablet breakpoint
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 768, // Mobile breakpoint
      settings: {
        slidesToShow: 1,
      },
    },
  ],
  arrows: false, // Hide arrows for a cleaner look
};

const VolktekPage = () => {
  const navigate = useNavigate(); // Initialize navigate

  return (
    <div className="voltek-page-container">
      <div className="voltek-hero-container">
        <div className="hero-text">
          <h1>Volktek</h1>
          <h2>Innovative Networking Solutions</h2>
          <p>
            Voltek is a global leader in providing networking solutions designed
            for high-performance, scalability, and reliability. With an
            extensive product portfolio that covers switching, routing, and
            security, Voltek continues to revolutionize network infrastructure
            solutions for businesses around the world.
          </p>
          <button
            className="learn-more-button"
            onClick={() => navigate("/contact")}
          >
            Learn More
          </button>
        </div>
        <div
          className="hero-image"
          style={{
            backgroundColor: "#fff",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src="/images/logos/new/volktek.svg" alt="Voltek Logo" />
        </div>
      </div>

      {/* Product Carousel Section */}
      <div className="product-carousel-section">
        <h2 className="carousel-title">Our Volktek Solutions</h2>
        <Slider {...settings} aria-label="Product Carousel">
          {products.map((product, index) => (
            <div key={index} className="product-card">
              <a
                href={`/products/${product.name}`}
                className="product-link"
                aria-label={`Learn more about ${product.name}`}
              >
                <div className="product-image-container">
                  <img
                    src={product.image}
                    alt={`${product.name} Logo`}
                    className="product-image"
                    loading="lazy"
                  />
                </div>
                <h3 className="product-name">{product.name}</h3>
                <p className="product-description">{product.description}</p>
              </a>
            </div>
          ))}
        </Slider>
      </div>
      {/* End of Product Carousel Section */}

      <div className="video-container">
        <div className="video-responsive">
          <ReactPlayer
            url="https://www.youtube.com/watch?v=Ngja7ydOM2A"
            controls
            width="100%"
            height="100%"
            className="react-player"
          />
        </div>
      </div>
    </div>
  );
};

export default VolktekPage;
