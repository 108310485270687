// File: src/solutions-pages/ITSupportManagement.jsx

import React from "react";
import {
  FaClipboardList,
  FaDatabase,
  FaHeadset,
  FaLaptop,
  FaNetworkWired,
  FaTools,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import "./ITSupportManagement.css";

// Import images from the 'public' directory using 'process.env.PUBLIC_URL'
const heroImage = `${process.env.PUBLIC_URL}/images/it-support-management/hero-it-support-management.jpg`;
const parallaxImage = `${process.env.PUBLIC_URL}/images/it-support-management/parallax-it-support-management.jpg`; // If you have a parallax image
const overviewImage = `${process.env.PUBLIC_URL}/images/it-support-management/overview-it-support-management.jpg`;

// Service images (if you have specific images for services)
const serviceImages = {
  helpDeskSupport: `${process.env.PUBLIC_URL}/images/services/help-desk-support.jpg`,
  networkMonitoring: `${process.env.PUBLIC_URL}/images/services/network-monitoring.jpg`,
  remoteITManagement: `${process.env.PUBLIC_URL}/images/services/remote-it-management.jpg`,
  infrastructureMaintenance: `${process.env.PUBLIC_URL}/images/services/infrastructure-maintenance.jpg`,
  itAssetManagement: `${process.env.PUBLIC_URL}/images/services/it-asset-management.jpg`,
  disasterRecovery: `${process.env.PUBLIC_URL}/images/services/disaster-recovery-backup.jpg`,
};

// Brand logos (optional, similar to NetworkInfrastructure page)
const brands = [
  {
    name: "Microsoft",
    logo: `${process.env.PUBLIC_URL}/images/brands/microsoft-logo.png`,
    link: "/brands/microsoft",
    alt: "Microsoft",
  },
  {
    name: "Dell",
    logo: `${process.env.PUBLIC_URL}/images/brands/dell-logo.png`,
    link: "/brands/dell",
    alt: "Dell",
  },
  {
    name: "HP",
    logo: `${process.env.PUBLIC_URL}/images/brands/hp-logo.png`,
    link: "/brands/hp",
    alt: "HP",
  },
  {
    name: "IBM",
    logo: `${process.env.PUBLIC_URL}/images/brands/ibm-logo.png`,
    link: "/brands/ibm",
    alt: "IBM",
  },
];

const services = [
  {
    title: "Help Desk Support",
    description:
      "Provide your team with responsive and efficient help desk support to resolve IT issues promptly.",
    icon: <FaHeadset />,
    image: serviceImages.helpDeskSupport, // Optional: if you have service images
    link: "/services/help-desk-support",
  },
  {
    title: "Network Monitoring",
    description:
      "Continuously monitor your network to ensure optimal performance and quickly address any issues.",
    icon: <FaNetworkWired />,
    image: serviceImages.networkMonitoring,
    link: "/services/network-monitoring",
  },
  {
    title: "Remote IT Management",
    description:
      "Manage and support your IT infrastructure remotely, reducing downtime and operational costs.",
    icon: <FaLaptop />,
    image: serviceImages.remoteITManagement,
    link: "/services/remote-it-management",
  },
  {
    title: "Infrastructure Maintenance",
    description:
      "Keep your IT infrastructure running smoothly with regular maintenance and proactive support.",
    icon: <FaTools />,
    image: serviceImages.infrastructureMaintenance,
    link: "/services/infrastructure-maintenance",
  },
  {
    title: "IT Asset Management",
    description:
      "Track and manage your IT assets effectively to optimize resource utilization and budgeting.",
    icon: <FaClipboardList />,
    image: serviceImages.itAssetManagement,
    link: "/services/it-asset-management",
  },
  {
    title: "Disaster Recovery & Backup Solutions",
    description:
      "Protect your data and ensure business continuity with robust disaster recovery and backup solutions.",
    icon: <FaDatabase />,
    image: serviceImages.disasterRecovery,
    link: "/services/disaster-recovery-backup",
  },
];

const ITSupportManagement = () => {
  return (
    <div className="it-support-management">
      {/* Hero Section */}
      <section
        className="hero-section"
        style={{
          backgroundImage: `url(${heroImage})`,
        }}
      >
        <div className="hero-overlay">
          <h1>IT Support & Management</h1>
          <p>
            Ensure your IT systems run smoothly with our comprehensive support
            and management services. Minimize downtime and maximize
            productivity.
          </p>
          <Link to="/contact" className="hero-button">
            Get Started
          </Link>
        </div>
        <div className="scroll-down-indicator"></div>
      </section>

      {/* Overview Section */}
      <section className="overview-section">
        <div className="container">
          <h2>Reliable IT Support for Your Business</h2>
          <div className="overview-content">
            <div className="overview-text">
              <p>
                In today's technology-driven world, reliable IT support is
                essential. Our expert team provides end-to-end IT support and
                management services to keep your systems running efficiently.
              </p>
              <p>
                From help desk support to disaster recovery, we offer solutions
                tailored to your business needs.
              </p>
            </div>
            <div className="overview-image">
              <img src={overviewImage} alt="IT Support & Management Overview" />
            </div>
          </div>
        </div>
      </section>

      {/* Services Offered Section */}
      <section className="services-section">
        <div className="container">
          <h2>Our IT Support & Management Services</h2>
          <div className="services-grid">
            {services.map((service, index) => (
              <Link to={service.link} className="service-card" key={index}>
                <div
                  className="service-image"
                  style={{ backgroundImage: `url(${service.image})` }}
                >
                  <div className="service-icon">{service.icon}</div>
                </div>
                <div className="service-content">
                  <h3>{service.title}</h3>
                  <p>{service.description}</p>
                  <span className="read-more">Read More</span>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </section>

      {/* Parallax Section (Optional) */}
      <section
        className="parallax-section"
        style={{
          backgroundImage: `url(${parallaxImage})`,
        }}
      >
        <div className="parallax-overlay">
          <h2>Comprehensive IT Solutions for Modern Businesses</h2>
          <p>
            Enhance your IT infrastructure with our state-of-the-art support and
            management services designed to meet your unique business needs.
          </p>
          <Link to="/solutions" className="parallax-button">
            Learn More
          </Link>
        </div>
      </section>

      {/* Call to Action */}
      <section className="cta-section">
        <div className="container">
          <h2>Ready to Enhance Your IT Infrastructure?</h2>
          <p>
            Contact us today for a consultation and discover how our IT support
            and management services can benefit your business.
          </p>
          <Link to="/contact" className="cta-button">
            Contact Us
          </Link>
        </div>
      </section>
    </div>
  );
};

export default ITSupportManagement;
