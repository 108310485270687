import React from 'react';
import { Parallax } from 'react-parallax';
import './Services.css';
import {
  FaServer,
  FaTools,
  FaCogs,
  FaSyncAlt,
  FaClock,
  FaHeadset,
  FaShieldAlt,
  FaWrench,
} from 'react-icons/fa';

const InfrastructureMaintenance = () => {
  const features = [
    {
      icon: <FaTools />,
      title: 'Regular Maintenance',
      description: 'Scheduled upkeep of your IT infrastructure.',
      iconClass: 'blue',
    },
    {
      icon: <FaCogs />,
      title: 'Hardware Support',
      description: 'Repair and replace hardware components.',
      iconClass: 'blue',
    },
    {
      icon: <FaSyncAlt />,
      title: 'Software Updates',
      description: 'Keep your systems up-to-date with the latest software.',
      iconClass: 'blue',
    },
    {
      icon: <FaWrench />,
      title: 'Customized Solutions',
      description: 'Tailored maintenance plans for your needs.',
      iconClass: 'blue',
    },
  ];

  const benefits = [
    {
      icon: <FaClock />,
      title: 'Reduced Downtime',
      description: 'Prevent issues before they cause disruptions.',
      iconClass: 'teal',
    },
    {
      icon: <FaShieldAlt />,
      title: 'Enhanced Security',
      description: 'Maintain security through regular updates.',
      iconClass: 'teal',
    },
    {
      icon: <FaHeadset />,
      title: 'Expert Support',
      description: 'Access to our experienced maintenance team.',
      iconClass: 'teal',
    },
    {
      icon: <FaServer />,
      title: 'Optimized Performance',
      description: 'Ensure your infrastructure runs efficiently.',
      iconClass: 'teal',
    },
  ];

  return (
    <div className="services-page">
      {/* Hero Section */}
      <section
        className="hero-section"
        style={{
          backgroundImage:
            "url('/images/services/infrastructure-maintenance/hero-infrastructure-maintenance.jpg')",
        }}
      >
        <div className="hero-content">
          <h1>Infrastructure Maintenance</h1>
          <p>Our ongoing infrastructure maintenance activities include hardware and software updates patch
management, and regular system checks to ensure your IT environment remains secure and
efficient. We offer proactive maintenance to prevent inactivity and keep your business running
In a simpler way.
</p>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/infrastructure-maintenance/parallax1.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Maintain Excellence</h2>
        </div>
      </Parallax>

      {/* Features Section */}
      <section className="section light-bg">
        <div className="container">
          <h2>Our Maintenance Services</h2>
          <div className="grid">
            {features.map((feature, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${feature.iconClass}`}>
                  {feature.icon}
                </div>
                <h3>{feature.title}</h3>
                <p>{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/infrastructure-maintenance/parallax2.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Reliable Operations</h2>
        </div>
      </Parallax>

      {/* Benefits Section */}
      <section className="section dark-bg">
        <div className="container">
          <h2>Benefits</h2>
          <div className="grid">
            {benefits.map((benefit, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${benefit.iconClass}`}>
                  {benefit.icon}
                </div>
                <h3>{benefit.title}</h3>
                <p>{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="cta-section">
        <h2>Need Maintenance Services?</h2>
        <a href="/contact" className="cta-button">
          Contact Us
        </a>
      </section>
    </div>
  );
};

export default InfrastructureMaintenance;
