import React from 'react';
import { Parallax } from 'react-parallax';
import './Services.css';
import {
  FaFileAlt,
  FaCloudUploadAlt,
  FaUsers,
  FaLock,
  FaSyncAlt,
  FaHeadset,
  FaShareAlt,
  FaFolderOpen,
} from 'react-icons/fa';

const FileSharing = () => {
  const features = [
    {
      icon: <FaCloudUploadAlt />,
      title: 'Cloud Storage',
      description: 'Store and access files securely in the cloud.',
      iconClass: 'blue',
    },
    {
      icon: <FaUsers />,
      title: 'Collaborative Editing',
      description: 'Work on documents with your team in real-time.',
      iconClass: 'blue',
    },
    {
      icon: <FaShareAlt />,
      title: 'Easy Sharing',
      description: 'Share files and folders with ease.',
      iconClass: 'blue',
    },
    {
      icon: <FaLock />,
      title: 'Secure Access',
      description: 'Control who can view or edit your files.',
      iconClass: 'blue',
    },
  ];

  const benefits = [
    {
      icon: <FaSyncAlt />,
      title: 'Improved Productivity',
      description: 'Streamline collaboration and workflow.',
      iconClass: 'teal',
    },
    {
      icon: <FaHeadset />,
      title: 'Expert Support',
      description: 'Assistance from our collaboration specialists.',
      iconClass: 'teal',
    },
    {
      icon: <FaFolderOpen />,
      title: 'Organized Files',
      description: 'Keep your documents organized and accessible.',
      iconClass: 'teal',
    },
    {
      icon: <FaFileAlt />,
      title: 'Version Control',
      description: 'Track changes and restore previous versions.',
      iconClass: 'teal',
    },
  ];

  return (
    <div className="services-page">
      {/* Hero Section */}
      <section
        className="hero-section"
        style={{
          backgroundImage:
            "url('/images/services/file-sharing/hero-file-sharing.jpg')",
        }}
      >
        <div className="hero-content">
          <h1>File Sharing & Collaboration</h1>
          <p>Our file-sharing tools allow teams to collaborate on files and tasks in actual time, with built-in safety functions to protect your touchy statistics. Share files securely with your teams or outside partners, and preserve full management over getting entry to permissions.
          </p>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/file-sharing/parallax1.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Work Together</h2>
        </div>
      </Parallax>

      {/* Features Section */}
      <section className="section light-bg">
        <div className="container">
          <h2>Our Collaboration Features</h2>
          <div className="grid">
            {features.map((feature, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${feature.iconClass}`}>
                  {feature.icon}
                </div>
                <h3>{feature.title}</h3>
                <p>{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/file-sharing/parallax2.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Collaborate Efficiently</h2>
        </div>
      </Parallax>

      {/* Benefits Section */}
      <section className="section dark-bg">
        <div className="container">
          <h2>Benefits</h2>
          <div className="grid">
            {benefits.map((benefit, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${benefit.iconClass}`}>
                  {benefit.icon}
                </div>
                <h3>{benefit.title}</h3>
                <p>{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="cta-section">
        <h2>Ready to Enhance Collaboration?</h2>
        <a href="/contact" className="cta-button">
          Contact Us
        </a>
      </section>
    </div>
  );
};

export default FileSharing;
