import React from 'react';
import { Parallax } from 'react-parallax';
import './Services.css';
import {
  FaNetworkWired,
  FaLock,
  FaUsers,
  FaChartPie,
  FaTachometerAlt,
  FaCogs,
  FaHandshake,
  FaShieldAlt,
} from 'react-icons/fa';

const LANSolutions = () => {
  const solutions = [
    {
      icon: <FaNetworkWired />,
      title: 'Ethernet LAN Design',
      description: 'High-speed connectivity and reliability for your operations.',
      iconClass: 'blue',
    },
    {
      icon: <FaUsers />,
      title: 'Virtual LANs (VLANs)',
      description: 'Segment network traffic and enhance security.',
      iconClass: 'blue',
    },
    {
      icon: <FaLock />,
      title: 'LAN Security Implementation',
      description: 'Protect your local network with advanced security protocols.',
      iconClass: 'blue',
    },
    {
      icon: <FaChartPie />,
      title: 'Performance Optimization',
      description: 'Ensure optimal network performance and resource utilization.',
      iconClass: 'blue',
    },
  ];

  const benefits = [
    {
      icon: <FaTachometerAlt />,
      title: 'Improved Efficiency',
      description: 'Faster data transfer and communication.',
      iconClass: 'teal',
    },
    {
      icon: <FaShieldAlt />,
      title: 'Enhanced Security',
      description: 'Protect sensitive data within your network.',
      iconClass: 'teal',
    },
    {
      icon: <FaCogs />,
      title: 'Easy Scalability',
      description: 'Scale your network effortlessly as your business grows.',
      iconClass: 'teal',
    },
    {
      icon: <FaHandshake />,
      title: 'Better Collaboration',
      description: 'Facilitate teamwork with reliable network access.',
      iconClass: 'teal',
    },
  ];

  return (
    <div className="services-page">
      {/* Hero Section */}
      <section
        className="hero-section"
        style={{
          backgroundImage: "url('/images/services/lan-solutions/hero-lan-solutions.jpg')",
        }}
      >
        <div className="hero-content">
          <h1>LAN Solutions</h1>
          <p>
We lay and deploy steady LANs that offer fast, reliable connections inside your organization. Our answers make sure that your organization is optimized for high speed data transfer, efficient collaboration, and the secure sharing of assets across departments
</p>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/lan-solutions/parallax1.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Optimized Performance</h2>
        </div>
      </Parallax>

      {/* Solutions Section */}
      <section className="section light-bg">
        <div className="container">
          <h2>Solutions We Offer</h2>
          <div className="grid">
            {solutions.map((solution, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${solution.iconClass}`}>{solution.icon}</div>
                <h3>{solution.title}</h3>
                <p>{solution.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/lan-solutions/parallax2.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Enhanced Collaboration</h2>
        </div>
      </Parallax>

      {/* Benefits Section */}
      <section className="section dark-bg">
        <div className="container">
          <h2>Benefits</h2>
          <div className="grid">
            {benefits.map((benefit, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${benefit.iconClass}`}>{benefit.icon}</div>
                <h3>{benefit.title}</h3>
                <p>{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="cta-section">
        <h2>Ready to Upgrade Your LAN?</h2>
        <a href="/contact" className="cta-button">
          Contact Us
        </a>
      </section>
    </div>
  );
};

export default LANSolutions;
