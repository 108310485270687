// File: src/solutions-pages/NetworkInfrastructure.jsx

import React from "react";
import { FaCloud, FaCogs, FaNetworkWired, FaWifi } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./VideoContentAnalyticsSolutionsPage.css";

// Import images from the 'public' directory using 'process.env.PUBLIC_URL'
const heroImage = `${process.env.PUBLIC_URL}/images/NetworkInfrastructure/hero-network.jpg`;
const parallaxImage = `${process.env.PUBLIC_URL}/images/NetworkInfrastructure/parallax.jpeg`;
const overviewImage = `${process.env.PUBLIC_URL}/images/NetworkInfrastructure/overview.jpg`;

// Service images
const serviceImages = {
  networkDesign: `${process.env.PUBLIC_URL}/images/services/network-design.jpg`,
  networkImplementation: `${process.env.PUBLIC_URL}/images/services/network-implementation.jpg`,
  lanSolutions: `${process.env.PUBLIC_URL}/images/services/lan-solutions.jpg`,
  wanSolutions: `${process.env.PUBLIC_URL}/images/services/wan-solutions.jpg`,
  wirelessSolutions: `${process.env.PUBLIC_URL}/images/services/wireless-solutions.jpg`,
  networkManagement: `${process.env.PUBLIC_URL}/images/services/network-management.jpg`,
  networkSecurity: `${process.env.PUBLIC_URL}/images/services/network-security.jpg`,
};

const services = [
  {
    title: "VCA Software",
    description:
      "VCA software transforms video data into actionable intelligence, enabling organizations to monitor activities in real time and conduct thorough forensic analyses. Its seamless integration with existing security systems enhances situational awareness and response capabilities.",
    icon: <FaNetworkWired />,
    image: serviceImages.networkDesign,
    link: "",
  },
  {
    title: "VCA AI Cameras",
    description:
      "VCA AI cameras leverage advanced artificial intelligence to analyze video feeds in real time, identifying potential threats and unusual behaviors. With features like facial recognition and license plate detection, these cameras significantly enhance security measures and access control.",
    icon: <FaCogs />,
    image: serviceImages.networkImplementation,
    link: "",
  },
  {
    title: "VCA Servers",
    description:
      "VCA servers provide a robust foundation for deploying AI-driven analytics across multiple camera setups, ensuring scalability and flexibility for growing businesses. Designed for deep learning integration, they enhance threat detection and streamline operational efficiency.",
    icon: <FaNetworkWired />,
    image: serviceImages.lanSolutions,
    link: "",
  },
  {
    title: "VCA Forensics",
    description:
      "VCA forensics tools offer detailed analysis of incidents by extracting metadata from recorded footage, facilitating rapid investigations and informed decision-making. This capability is crucial for enhancing security protocols and improving overall incident response.",
    icon: <FaCloud />,
    image: serviceImages.wanSolutions,
    link: "",
  },
  {
    title: "VCA Retail Trend Services",
    description:
      "VCA retail trend services deliver valuable insights into customer behavior and shopping patterns, helping retailers optimize store layouts and marketing strategies. These services also support loss prevention efforts by identifying suspicious activities in real time, ultimately driving profitability.",
    icon: <FaWifi />,
    image: serviceImages.wirelessSolutions,
    link: "",
  },
];

const VideoContentAnalyticsSolutionsPage = () => {
  return (
    <div className="network-infrastructure">
      {/* Hero Section */}
      <section
        className="hero-section"
        style={{
          backgroundImage: `url(${heroImage})`,
        }}
      >
        <div className="hero-overlay">
          <h1>Video Content Analytics</h1>
          <p>
            We help businesses leverage advanced technologies that provide
            cutting-edge solutions for security and operational efficiency. Our
            offerings are designed to enhance surveillance capabilities,
            streamline processes, and deliver actionable insights across various
            sectors.
          </p>
          <Link to="/contact" className="hero-button">
            Get Started
          </Link>
        </div>
        <div className="scroll-down-indicator"></div>
      </section>

      {/* Overview Section */}
      <section className="overview-section">
        <div className="container">
          <h2>Expert video content analysis</h2>
          <div className="overview-content">
            <div className="overview-text">
              <p>
                By choosing our advanced solutions, businesses can expect a
                comprehensive approach to security that not only safeguards
                assets but also enhances overall operational effectiveness. We
                are committed to providing the technology and expertise
                necessary for organizations to thrive in an increasingly complex
                environment. For more information on how we can assist your
                business, please reach out to our expert team today.
              </p>
            </div>
            <div className="overview-image">
              <img src={overviewImage} alt="Network Overview" />
            </div>
          </div>
        </div>
      </section>

      {/* Services Offered Section */}
      <section className="network-services-section">
        <div className="container">
          <h2>Our Services</h2>
          <div className="network-services-grid">
            {services.map((service, index) => (
              <Link
                to={service.link}
                className="network-service-card"
                key={index}
              >
                <div
                  className="network-service-image"
                  style={{ backgroundImage: `url(${service.image})` }}
                >
                  <div className="network-service-icon">{service.icon}</div>
                </div>
                <div className="network-service-content">
                  <h3>{service.title}</h3>
                  <p>{service.description}</p>
                  <span className="network-read-more">Read More</span>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </section>

      {/* Parallax Section */}
      <section
        className="parallax-section"
        style={{
          backgroundImage: `url(${parallaxImage})`,
        }}
      >
        <div className="parallax-overlay">
          <h2>Innovative Solutions for Modern Businesses</h2>
          <p>
            Stay ahead of the competition with our state-of-the-art networking
            solutions tailored to your business needs.
          </p>
          <Link to="/solutions" className="parallax-button">
            Learn More
          </Link>
        </div>
      </section>

      {/* Call to Action */}
      <section className="cta-section">
        <div className="container">
          <h2>Ready to Transform Your Network?</h2>
          <p>
            Contact us today for a consultation and let’s build a robust,
            secure, and scalable network infrastructure tailored to your
            business needs.
          </p>
          <Link to="/contact" className="cta-button">
            Get Started
          </Link>
        </div>
      </section>
    </div>
  );
};

export default VideoContentAnalyticsSolutionsPage;
