import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import './LinkFastPage.css'; // Custom styling for LinkFast page

const LinkFastPage = () => {
  const navigate = useNavigate(); // Initialize navigate

  return (
    <div className="linkfast-page-container">
      <div className="linkfast-hero-container">
        <div className="hero-text">
          <h1>LinkFast</h1>
          <h2>Fast, Secure, and Reliable Connectivity</h2>
          <p>
            LinkFast is a leading provider of cutting-edge networking solutions designed to deliver fast, secure, and reliable 
            connectivity. With a focus on quality and innovation, LinkFast products ensure seamless integration and network 
            performance for businesses of all sizes.
          </p>
          <button className="learn-more-button" onClick={() => navigate('/contact')}>
            Learn More
          </button>
        </div>
        <div className="hero-image">
          <img src="/images/logos/linkfast-logo.svg" alt="LinkFast Logo" />
        </div>
      </div>

      <div className="video-container">
        <iframe
          width="560"
          height="400" /* Height set to 400px as requested */
          src="https://www.youtube.com/embed/your_video_id" // Replace with the actual video link
          title="LinkFast Overview"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default LinkFastPage;
