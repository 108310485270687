import React from 'react';
import { Parallax } from 'react-parallax';
import './Services.css';
import {
  FaBug,
  FaShieldAlt,
  FaLock,
  FaSyncAlt,
  FaCheckCircle,
  FaHeadset,
} from 'react-icons/fa';

const AntivirusProtection = () => {
  const features = [
    {
      icon: <FaBug />,
      title: 'Real-Time Scanning',
      description: 'Continuous monitoring for threats.',
      iconClass: 'blue',
    },
    {
      icon: <FaShieldAlt />,
      title: 'Advanced Malware Detection',
      description: 'Protect against viruses, ransomware, and more.',
      iconClass: 'blue',
    },
    {
      icon: <FaSyncAlt />,
      title: 'Automatic Updates',
      description: 'Stay protected with the latest definitions.',
      iconClass: 'blue',
    },
    {
      icon: <FaLock />,
      title: 'Endpoint Security',
      description: 'Secure all devices connected to your network.',
      iconClass: 'blue',
    },
  ];

  const benefits = [
    {
      icon: <FaCheckCircle />,
      title: 'Comprehensive Protection',
      description: 'Defend against a wide range of threats.',
      iconClass: 'teal',
    },
    {
      icon: <FaHeadset />,
      title: 'Expert Support',
      description: 'Assistance from our security professionals.',
      iconClass: 'teal',
    },
    {
      icon: <FaShieldAlt />,
      title: 'Peace of Mind',
      description: 'Operate safely knowing you are protected.',
      iconClass: 'teal',
    },
    {
      icon: <FaLock />,
      title: 'Data Security',
      description: 'Prevent unauthorized access to your data.',
      iconClass: 'teal',
    },
  ];

  return (
    <div className="services-page">
      {/* Hero Section */}
      <section
        className="hero-section"
        style={{
          backgroundImage:
            "url('/images/services/antivirus-protection/hero-antivirus-protection.jpg')",
        }}
      >
        <div className="hero-content">
          <h1>Antivirus & Malware Protection</h1>
          <p>We provide top-tier antivirus and anti-malware plans to defend your structures from viruses,
ransomware, spyware, and other malicious software. Our services consist of regular updates and
non-stop monitoring to make certain that your Organization remains covered from modern threats.
</p>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/antivirus-protection/parallax1.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Stay Safe Online</h2>
        </div>
      </Parallax>

      {/* Features Section */}
      <section className="section light-bg">
        <div className="container">
          <h2>Our Protection Services</h2>
          <div className="grid">
            {features.map((feature, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${feature.iconClass}`}>
                  {feature.icon}
                </div>
                <h3>{feature.title}</h3>
                <p>{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/antivirus-protection/parallax2.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Defend Your Systems</h2>
        </div>
      </Parallax>

      {/* Benefits Section */}
      <section className="section dark-bg">
        <div className="container">
          <h2>Benefits</h2>
          <div className="grid">
            {benefits.map((benefit, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${benefit.iconClass}`}>
                  {benefit.icon}
                </div>
                <h3>{benefit.title}</h3>
                <p>{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="cta-section">
        <h2>Ready to Protect Your Systems?</h2>
        <a href="/contact" className="cta-button">
          Contact Us
        </a>
      </section>
    </div>
  );
};

export default AntivirusProtection;
