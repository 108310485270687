import React from 'react';
import { Parallax } from 'react-parallax';
import './Services.css';
import {
  FaServer,
  FaCloud,
  FaDesktop,
  FaCodeBranch,
  FaCogs,
  FaArrowsAlt,
  FaHeadset,
  FaDollarSign,
} from 'react-icons/fa';

const VirtualizationTechnologies = () => {
  const solutions = [
    {
      icon: <FaServer />,
      title: 'Server Virtualization',
      description: 'Optimize server resources and reduce hardware costs.',
      iconClass: 'blue',
    },
    {
      icon: <FaDesktop />,
      title: 'Desktop Virtualization',
      description: 'Access your desktop environment from anywhere.',
      iconClass: 'blue',
    },
    {
      icon: <FaCloud />,
      title: 'Cloud Virtualization',
      description: 'Scalable cloud solutions for your business.',
      iconClass: 'blue',
    },
    {
      icon: <FaCodeBranch />,
      title: 'Network Virtualization',
      description: 'Simplify network management and deployment.',
      iconClass: 'blue',
    },
  ];

  const benefits = [
    {
      icon: <FaCogs />,
      title: 'Increased Efficiency',
      description: 'Make the most of your IT resources.',
      iconClass: 'teal',
    },
    {
      icon: <FaArrowsAlt />,
      title: 'Scalability',
      description: 'Easily scale resources up or down as needed.',
      iconClass: 'teal',
    },
    {
      icon: <FaDollarSign />,
      title: 'Cost Savings',
      description: 'Reduce hardware and operational costs.',
      iconClass: 'teal',
    },
    {
      icon: <FaHeadset />,
      title: 'Expert Support',
      description: 'Guidance from our experienced virtualization team.',
      iconClass: 'teal',
    },
  ];

  return (
    <div className="services-page">
      {/* Hero Section */}
      <section
        className="hero-section"
        style={{
          backgroundImage:
            "url('/images/services/virtualization-technologies/hero-virtualization-technologies.jpg')",
        }}
      >
        <div className="hero-content">
          <h1>Virtualization Technologies</h1>
          <p>Transforming your IT infrastructure with virtualization.</p>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/virtualization-technologies/parallax1.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Modernize Your IT</h2>
        </div>
      </Parallax>

      {/* Solutions Section */}
      <section className="section light-bg">
        <div className="container">
          <h2>Our Virtualization Solutions</h2>
          <div className="grid">
            {solutions.map((solution, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${solution.iconClass}`}>{solution.icon}</div>
                <h3>{solution.title}</h3>
                <p>{solution.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/virtualization-technologies/parallax2.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Flexible Solutions</h2>
        </div>
      </Parallax>

      {/* Benefits Section */}
      <section className="section dark-bg">
        <div className="container">
          <h2>Benefits</h2>
          <div className="grid">
            {benefits.map((benefit, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${benefit.iconClass}`}>{benefit.icon}</div>
                <h3>{benefit.title}</h3>
                <p>{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="cta-section">
        <h2>Ready to Virtualize Your Infrastructure?</h2>
        <a href="/contact" className="cta-button">
          Contact Us
        </a>
      </section>
    </div>
  );
};

export default VirtualizationTechnologies;
