// File: src/solutions-pages/Virtualization.jsx

import React from "react";
import {
  FaChartLine,
  FaDesktop,
  FaNetworkWired,
  FaServer,
  FaSyncAlt,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import "./Virtualization.css";

// Import images from the 'public' directory using 'process.env.PUBLIC_URL'
const heroImage = `${process.env.PUBLIC_URL}/images/virtualization/hero-virtualization.jpg`;
const parallaxImage = `${process.env.PUBLIC_URL}/images/virtualization/parallax-virtualization.jpg`; // Ensure you have this image
const overviewImage = `${process.env.PUBLIC_URL}/images/virtualization/overview-virtualization.jpg`;

// Service images (optional: if you have specific images for services)
const serviceImages = {
  serverVirtualization: `${process.env.PUBLIC_URL}/images/services/server-virtualization.jpg`,
  desktopVirtualization: `${process.env.PUBLIC_URL}/images/services/desktop-virtualization.jpg`,
  infrastructureManagement: `${process.env.PUBLIC_URL}/images/services/virtual-infrastructure-management.jpg`,
  resourceOptimization: `${process.env.PUBLIC_URL}/images/services/resource-optimization.jpg`,
  businessContinuity: `${process.env.PUBLIC_URL}/images/services/business-continuity-disaster-recovery.jpg`,
};

// Brand logos (optional: similar to NetworkInfrastructure page)
const brands = [
  {
    name: "VMware",
    logo: `${process.env.PUBLIC_URL}/images/brands/vmware-logo.png`,
    link: "/brands/vmware",
    alt: "VMware",
  },
  {
    name: "Microsoft",
    logo: `${process.env.PUBLIC_URL}/images/brands/microsoft-logo.png`,
    link: "/brands/microsoft",
    alt: "Microsoft",
  },
  {
    name: "Citrix",
    logo: `${process.env.PUBLIC_URL}/images/brands/citrix-logo.png`,
    link: "/brands/citrix",
    alt: "Citrix",
  },
  {
    name: "Nutanix",
    logo: `${process.env.PUBLIC_URL}/images/brands/nutanix-logo.png`,
    link: "/brands/nutanix",
    alt: "Nutanix",
  },
];

const services = [
  {
    title: "Server Virtualization",
    description:
      "Optimize your server resources by consolidating multiple servers onto a single physical machine.",
    icon: <FaServer />,
    image: serviceImages.serverVirtualization, // Optional: if you have service images
    link: "/services/server-virtualization",
  },
  {
    title: "Desktop Virtualization",
    description:
      "Provide flexible and secure desktop environments accessible from anywhere.",
    icon: <FaDesktop />,
    image: serviceImages.desktopVirtualization,
    link: "/services/desktop-virtualization",
  },
  {
    title: "Virtual Infrastructure Management",
    description:
      "Manage your virtual environments efficiently with our comprehensive management solutions.",
    icon: <FaNetworkWired />,
    image: serviceImages.infrastructureManagement,
    link: "/services/virtual-infrastructure-management",
  },
  {
    title: "Resource Optimization",
    description:
      "Maximize resource utilization and reduce costs through effective virtualization strategies.",
    icon: <FaChartLine />,
    image: serviceImages.resourceOptimization,
    link: "/services/resource-optimization",
  },
  {
    title: "Business Continuity & Disaster Recovery",
    description:
      "Ensure uninterrupted operations with our robust business continuity and disaster recovery solutions.",
    icon: <FaSyncAlt />,
    image: serviceImages.businessContinuity,
    link: "/services/business-continuity",
  },
];

const Virtualization = () => {
  return (
    <div className="virtualization">
      {/* Hero Section */}
      <section
        className="hero-section"
        style={{
          backgroundImage: `url(${heroImage})`,
        }}
      >
        <div className="hero-overlay">
          <h1>Virtualization Solutions</h1>
          <p>
            We help businesses reduce costs and increase productivity through
            virtualization. which is reinforced using server and virtualization
            technologies, we allow you to maximize hardware utilization, Reduce
            downtime, and improve scalability across your infrastructure.
          </p>
          <Link to="/contact" className="hero-button">
            Get Started
          </Link>
        </div>
        <div className="scroll-down-indicator"></div>
      </section>

      {/* Overview Section */}
      <section className="overview-section">
        <div className="container">
          <h2>Unlock the Power of Virtualization</h2>
          <div className="overview-content">
            <div className="overview-text">
              <p>
                Virtualization is the cornerstone of modern IT infrastructure.
                Our expert team helps you implement virtualization technologies
                that enhance performance and agility.
              </p>
              <p>
                We offer comprehensive solutions tailored to your business
                needs, ensuring you stay ahead in the competitive landscape.
              </p>
            </div>
            <div className="overview-image">
              <img src={overviewImage} alt="Virtualization Overview" />
            </div>
          </div>
        </div>
      </section>

      {/* Services Offered Section */}
      <section className="services-section">
        <div className="container">
          <h2>Our Virtualization Services</h2>
          <div className="services-grid">
            {services.map((service, index) => (
              <Link to={service.link} className="service-card" key={index}>
                <div
                  className="service-image"
                  style={{ backgroundImage: `url(${service.image})` }}
                >
                  <div className="service-icon">{service.icon}</div>
                </div>
                <div className="service-content">
                  <h3>{service.title}</h3>
                  <p>{service.description}</p>
                  <span className="read-more">Read More</span>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </section>

      {/* Parallax Section */}
      <section
        className="parallax-section"
        style={{
          backgroundImage: `url(${parallaxImage})`,
        }}
      >
        <div className="parallax-overlay">
          <h2>Comprehensive Virtualization for Modern Businesses</h2>
          <p>
            Enhance your IT infrastructure with our state-of-the-art
            virtualization services designed to meet your unique business needs.
          </p>
          <Link to="/solutions" className="parallax-button">
            Learn More
          </Link>
        </div>
      </section>

      {/* Call to Action */}
      <section className="cta-section">
        <div className="container">
          <h2>Ready to Virtualize Your Infrastructure?</h2>
          <p>
            Contact us today for a consultation and discover how our
            virtualization services can revolutionize your IT operations.
          </p>
          <Link to="/contact" className="cta-button">
            Contact Us
          </Link>
        </div>
      </section>
    </div>
  );
};

export default Virtualization;
