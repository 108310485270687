import React from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate hook
import "./VCAPage.css"; // Custom styling for TP-Link page
// SynologyPage.jsx
import ReactPlayer from "react-player";
import Slider from "react-slick"; // Import Slider from react-slick
import "./SynologyPage.css"; // Custom styling for Synology page

// Import slick-carousel CSS
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

// Product Data
const products = [
  {
    name: "ANPR & Vehicle Recognition",
    // description:
    //   "A 2U rack-mounted NAS designed for enterprise-level storage with redundancy and high performance.",
    image: "/images/products/vca/vca-vehicle-recognition.jpg",
  },
  {
    name: "AI tracking featuring VCA",
    // description:
    //   "A powerful rack-mounted NAS with advanced data management, suitable for SMBs requiring virtualization support.",
    image: "/images/products/vca/vca-ai-tracking.jpg",
  },
  {
    name: "Face recognition",
    // description:
    //   "A desktop NAS offering high scalability and performance, ideal for small to medium-sized businesses.",
    image: "/images/products/vca/vca-face-recognition.jpg",
  },
];

// Carousel settings
const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3, // Number of slides to show on desktop
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000, // 3 seconds
  responsive: [
    {
      breakpoint: 1200, // Tablet breakpoint
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 768, // Mobile breakpoint
      settings: {
        slidesToShow: 1,
      },
    },
  ],
  arrows: false, // Hide arrows for a cleaner look
};
export default function VCAPage() {
  const navigate = useNavigate(); // Initialize navigate

  return (
    <div className="vca-page-container">
      <div className="vca-hero-container">
        <div className="hero-text">
          <h1>VCA</h1>
          <p>
            VCA Technology specializes in advanced video content analytics,
            leveraging artificial intelligence to provide innovative solutions
            for various sectors, including retail, security, and transportation.
            Founded in 2009, the company focuses on enhancing business
            intelligence through its powerful analytics tools, which help
            organizations improve security, optimize operations, and gain
            insights into customer behavior. Their technology enables real-time
            monitoring and forensic analysis, allowing businesses to make
            informed decisions based on detailed video data. VCA Technology is
            recognized for its commitment to cost-effective and user-friendly
            solutions that drive productivity and efficiency across industries.
          </p>
          <button
            className="learn-more-button"
            onClick={() => navigate("/contact")}
          >
            Learn More
          </button>
        </div>
        <div
          className="hero-image"
          style={{
            backgroundColor: "#fff",
            padding: "50px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "200px",
          }}
        >
          <img src="/images/logos/new/all_logos__10.svg" alt="VCA Logo" />
        </div>
      </div>

      {/* Product Carousel Section */}
      <div className="product-carousel-section">
        <h2 className="carousel-title">Our VCA Solutions</h2>
        <Slider {...settings} aria-label="Product Carousel">
          {products.map((product, index) => (
            <div key={index} className="product-card">
              <a
                href={`/products/${product.name}`}
                className="product-link"
                aria-label={`Learn more about ${product.name}`}
              >
                <div className="product-image-container">
                  <img
                    src={product.image}
                    alt={`${product.name} Logo`}
                    className="product-image"
                    loading="lazy"
                  />
                </div>
                <h3 className="product-name">{product.name}</h3>
                <p className="product-description">{product.description}</p>
              </a>
            </div>
          ))}
        </Slider>
      </div>

      <div className="video-container">
        <div className="video-responsive">
          <ReactPlayer
            url=""
            controls
            width="100%"
            height="100%"
            className="react-player"
          />
        </div>
      </div>
    </div>
  );
}
