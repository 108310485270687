import React from 'react';
import { Parallax } from 'react-parallax';
import './Services.css';
import {
  FaCloud,
  FaDatabase,
  FaCogs,
  FaChartLine,
  FaSyncAlt,
  FaHeadset,
  FaShieldAlt,
  FaMobileAlt,
} from 'react-icons/fa';

const CloudIoTPlatforms = () => {
  const features = [
    {
      icon: <FaCloud />,
      title: 'Scalable Cloud Infrastructure',
      description: 'Leverage the power of the cloud for your IoT needs.',
      iconClass: 'blue',
    },
    {
      icon: <FaDatabase />,
      title: 'Data Storage & Analytics',
      description: 'Store and analyze large volumes of IoT data.',
      iconClass: 'blue',
    },
    {
      icon: <FaCogs />,
      title: 'Device Management',
      description: 'Manage your devices remotely through the cloud.',
      iconClass: 'blue',
    },
    {
      icon: <FaShieldAlt />,
      title: 'Secure Connectivity',
      description: 'Ensure secure communication between devices and cloud.',
      iconClass: 'blue',
    },
  ];

  const benefits = [
    {
      icon: <FaChartLine />,
      title: 'Real-Time Insights',
      description: 'Access data and analytics in real-time.',
      iconClass: 'teal',
    },
    {
      icon: <FaSyncAlt />,
      title: 'Automatic Scaling',
      description: 'Easily scale resources based on demand.',
      iconClass: 'teal',
    },
    {
      icon: <FaMobileAlt />,
      title: 'Anywhere Access',
      description: 'Monitor and control your IoT ecosystem from anywhere.',
      iconClass: 'teal',
    },
    {
      icon: <FaHeadset />,
      title: 'Expert Support',
      description: 'Assistance from our cloud IoT specialists.',
      iconClass: 'teal',
    },
  ];

  return (
    <div className="services-page">
      {/* Hero Section */}
      <section
        className="hero-section"
        style={{
          backgroundImage:
            "url('/images/services/cloud-iot-platforms/hero-cloud-iot-platforms.jpg')",
        }}
      >
        <div className="hero-content">
          <h1>Cloud-Based IoT Platforms</h1>
          <p>We offer cloud-primarily based IoT structures that provide a centralized hub for managing and reading
IoT records. Our systems allow you to access, save, and examine statistics in real-time, giving you
insights that power better choice-making and operational performance.
</p>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/cloud-iot-platforms/parallax1.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Scale with the Cloud</h2>
        </div>
      </Parallax>

      {/* Features Section */}
      <section className="section light-bg">
        <div className="container">
          <h2>Our Cloud IoT Services</h2>
          <div className="grid">
            {features.map((feature, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${feature.iconClass}`}>
                  {feature.icon}
                </div>
                <h3>{feature.title}</h3>
                <p>{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/cloud-iot-platforms/parallax2.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Empower Your IoT Solutions</h2>
        </div>
      </Parallax>

      {/* Benefits Section */}
      <section className="section dark-bg">
        <div className="container">
          <h2>Benefits</h2>
          <div className="grid">
            {benefits.map((benefit, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${benefit.iconClass}`}>
                  {benefit.icon}
                </div>
                <h3>{benefit.title}</h3>
                <p>{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="cta-section">
        <h2>Ready to Leverage the Cloud for IoT?</h2>
        <a href="/contact" className="cta-button">
          Contact Us
        </a>
      </section>
    </div>
  );
};

export default CloudIoTPlatforms;
