import React from 'react';
import { Parallax } from 'react-parallax';
import './Services.css';
import {
  FaCloud,
  FaSyncAlt,
  FaArrowsAlt,
  FaCogs,
  FaShieldAlt,
  FaHeadset,
  FaChartBar,
  FaNetworkWired,
} from 'react-icons/fa';

const HybridCloudSolutions = () => {
  const solutions = [
    {
      icon: <FaSyncAlt />,
      title: 'Integrated Environments',
      description: 'Combine public and private clouds seamlessly.',
      iconClass: 'blue',
    },
    {
      icon: <FaArrowsAlt />,
      title: 'Scalability',
      description: 'Dynamic resource allocation across environments.',
      iconClass: 'blue',
    },
    {
      icon: <FaNetworkWired />,
      title: 'Connectivity',
      description: 'Secure connections between cloud and on-premise systems.',
      iconClass: 'blue',
    },
    {
      icon: <FaCogs />,
      title: 'Customization',
      description: 'Tailored hybrid solutions for your needs.',
      iconClass: 'blue',
    },
  ];

  const benefits = [
    {
      icon: <FaShieldAlt />,
      title: 'Enhanced Security',
      description: 'Protect sensitive data on private clouds.',
      iconClass: 'teal',
    },
    {
      icon: <FaChartBar />,
      title: 'Optimized Performance',
      description: 'Leverage the strengths of both cloud types.',
      iconClass: 'teal',
    },
    {
      icon: <FaHeadset />,
      title: 'Expert Support',
      description: 'Assistance from our hybrid cloud specialists.',
      iconClass: 'teal',
    },
    {
      icon: <FaArrowsAlt />,
      title: 'Flexibility',
      description: 'Adapt to changing business requirements.',
      iconClass: 'teal',
    },
  ];

  return (
    <div className="services-page">
      {/* Hero Section */}
      <section
        className="hero-section"
        style={{
          backgroundImage:
            "url('/images/services/hybrid-cloud-solutions/hero-hybrid-cloud-solutions.jpg')",
        }}
      >
        <div className="hero-content">
          <h1>Hybrid Cloud Solutions</h1>
          <p>Our hybrid cloud services combine the best of both worlds, giving people flexibility in Cloud With the power and security of a private cloud. We help build and manage businesses in Hybrid cloud environments to improve efficiency, reduce costs and ensure compliance with industry standards.</p>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/hybrid-cloud-solutions/parallax1.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Flexible Cloud Strategies</h2>
        </div>
      </Parallax>

      {/* Solutions Section */}
      <section className="section light-bg">
        <div className="container">
          <h2>Our Hybrid Cloud Services</h2>
          <div className="grid">
            {solutions.map((solution, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${solution.iconClass}`}>{solution.icon}</div>
                <h3>{solution.title}</h3>
                <p>{solution.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/hybrid-cloud-solutions/parallax2.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Adaptable Solutions</h2>
        </div>
      </Parallax>

      {/* Benefits Section */}
      <section className="section dark-bg">
        <div className="container">
          <h2>Benefits</h2>
          <div className="grid">
            {benefits.map((benefit, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${benefit.iconClass}`}>{benefit.icon}</div>
                <h3>{benefit.title}</h3>
                <p>{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="cta-section">
        <h2>Ready for Hybrid Cloud?</h2>
        <a href="/contact" className="cta-button">
          Contact Us
        </a>
      </section>
    </div>
  );
};

export default HybridCloudSolutions;
