import React from 'react';
import { Parallax } from 'react-parallax';
import './Services.css';
import {
  FaSyncAlt,
  FaShieldAlt,
  FaServer,
  FaCloud,
  FaLock,
  FaHistory,
  FaCogs,
  FaHeadset,
} from 'react-icons/fa';

const BusinessContinuity = () => {
  const solutions = [
    {
      icon: <FaShieldAlt />,
      title: 'Risk Assessment',
      description: 'Identify potential threats to your business.',
      iconClass: 'blue',
    },
    {
      icon: <FaSyncAlt />,
      title: 'Continuity Planning',
      description: 'Develop strategies to maintain operations.',
      iconClass: 'blue',
    },
    {
      icon: <FaServer />,
      title: 'Data Backup & Recovery',
      description: 'Protect critical data with regular backups.',
      iconClass: 'blue',
    },
    {
      icon: <FaCloud />,
      title: 'Cloud Solutions',
      description: 'Leverage cloud for redundancy and accessibility.',
      iconClass: 'blue',
    },
  ];

  const benefits = [
    {
      icon: <FaLock />,
      title: 'Data Security',
      description: 'Safeguard your data against loss.',
      iconClass: 'teal',
    },
    {
      icon: <FaHistory />,
      title: 'Quick Recovery',
      description: 'Minimize downtime after disruptions.',
      iconClass: 'teal',
    },
    {
      icon: <FaCogs />,
      title: 'Operational Resilience',
      description: 'Ensure business processes continue smoothly.',
      iconClass: 'teal',
    },
    {
      icon: <FaHeadset />,
      title: 'Expert Support',
      description: 'Guidance during planning and recovery phases.',
      iconClass: 'teal',
    },
  ];

  return (
    <div className="services-page">
      {/* Hero Section */}
      <section
        className="hero-section"
        style={{
          backgroundImage:
            "url('/images/services/business-continuity/hero-business-continuity.jpg')",
        }}
      >
        <div className="hero-content">
          <h1>Business Continuity & Disaster Recovery</h1>
          <p>Virtualization is important for maintaining business continuity and effective catastrophe restoration. This process complements flexibility, allowing speedy recuperation and information healing at some stage in disruptions, reducing downtime, and ensuring the enterprise continues running smoothly.
          </p>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/business-continuity/parallax1.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Stay Prepared</h2>
        </div>
      </Parallax>

      {/* Solutions Section */}
      <section className="section light-bg">
        <div className="container">
          <h2>Our Continuity Solutions</h2>
          <div className="grid">
            {solutions.map((solution, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${solution.iconClass}`}>{solution.icon}</div>
                <h3>{solution.title}</h3>
                <p>{solution.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/business-continuity/parallax2.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Secure Your Future</h2>
        </div>
      </Parallax>

      {/* Benefits Section */}
      <section className="section dark-bg">
        <div className="container">
          <h2>Benefits</h2>
          <div className="grid">
            {benefits.map((benefit, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${benefit.iconClass}`}>{benefit.icon}</div>
                <h3>{benefit.title}</h3>
                <p>{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="cta-section">
        <h2>Ready to Protect Your Business?</h2>
        <a href="/contact" className="cta-button">
          Contact Us
        </a>
      </section>
    </div>
  );
};

export default BusinessContinuity;
