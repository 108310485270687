import React from 'react';
import { Parallax } from 'react-parallax';
import './Services.css';
import {
  FaShieldAlt,
  FaLock,
  FaUserShield,
  FaBug,
  FaCogs,
  FaBrain,
  FaCheckCircle,
  FaHeadset,
} from 'react-icons/fa';

const CloudSecurity = () => {
  const securityServices = [
    {
      icon: <FaLock />,
      title: 'Data Encryption',
      description: 'Secure your data with advanced encryption methods.',
      iconClass: 'blue',
    },
    {
      icon: <FaBug />,
      title: 'Threat Detection',
      description: 'Monitor and detect potential threats.',
      iconClass: 'blue',
    },
    {
      icon: <FaUserShield />,
      title: 'Identity Management',
      description: 'Manage user access and authentication.',
      iconClass: 'blue',
    },
    {
      icon: <FaShieldAlt />,
      title: 'Compliance Management',
      description: 'Ensure adherence to regulatory standards.',
      iconClass: 'blue',
    },
  ];

  const benefits = [
    {
      icon: <FaCogs />,
      title: 'Proactive Protection',
      description: 'Stay ahead of potential threats.',
      iconClass: 'teal',
    },
    {
      icon: <FaBrain />,
      title: 'Intelligent Security',
      description: 'Leverage AI for smarter security measures.',
      iconClass: 'teal',
    },
    {
      icon: <FaCheckCircle />,
      title: 'Regulatory Compliance',
      description: 'Avoid penalties with compliance adherence.',
      iconClass: 'teal',
    },
    {
      icon: <FaHeadset />,
      title: 'Expert Support',
      description: 'Assistance from our cloud security experts.',
      iconClass: 'teal',
    },
  ];

  return (
    <div className="services-page">
      {/* Hero Section */}
      <section
        className="hero-section"
        style={{
          backgroundImage:
            "url('/images/services/cloud-security/hero-cloud-security.jpg')",
        }}
      >
        <div className="hero-content">
          <h1>Cloud Security</h1>
          <p>Security is at the coronary heart of everything we do. Our cloud safety offerings guard your information and infrastructure in opposition to evolving threats. From encryption and getting entry to control the danger of tracking and compliance, we make sure your cloud environment is steady and resilient.
          </p>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/cloud-security/parallax1.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Secure Your Cloud</h2>
        </div>
      </Parallax>

      {/* Security Services Section */}
      <section className="section light-bg">
        <div className="container">
          <h2>Our Security Services</h2>
          <div className="grid">
            {securityServices.map((service, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${service.iconClass}`}>{service.icon}</div>
                <h3>{service.title}</h3>
                <p>{service.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/cloud-security/parallax2.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Stay Ahead of Threats</h2>
        </div>
      </Parallax>

      {/* Benefits Section */}
      <section className="section dark-bg">
        <div className="container">
          <h2>Benefits</h2>
          <div className="grid">
            {benefits.map((benefit, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${benefit.iconClass}`}>{benefit.icon}</div>
                <h3>{benefit.title}</h3>
                <p>{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="cta-section">
        <h2>Ready to Secure Your Cloud?</h2>
        <a href="/contact" className="cta-button">
          Contact Us
        </a>
      </section>
    </div>
  );
};

export default CloudSecurity;
