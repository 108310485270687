import React from 'react';
import { Parallax } from 'react-parallax';
import './Services.css';
import {
  FaCloud,
  FaTools,
  FaEye,
  FaShieldAlt,
  FaClock,
  FaChartBar,
  FaHeadset,
  FaThumbsUp,
} from 'react-icons/fa';

const CloudInfrastructureManagement = () => {
  const services = [
    {
      icon: <FaEye />,
      title: '24/7 Monitoring',
      description: 'Continuous oversight of your cloud environment.',
      iconClass: 'blue',
    },
    {
      icon: <FaTools />,
      title: 'Maintenance & Updates',
      description: 'Regular updates to keep systems running smoothly.',
      iconClass: 'blue',
    },
    {
      icon: <FaShieldAlt />,
      title: 'Security Management',
      description: 'Protect your cloud infrastructure from threats.',
      iconClass: 'blue',
    },
    {
      icon: <FaHeadset />,
      title: 'Support Services',
      description: 'Dedicated support for all your cloud needs.',
      iconClass: 'blue',
    },
  ];

  const benefits = [
    {
      icon: <FaClock />,
      title: 'Maximized Uptime',
      description: 'Ensure your cloud services are always available.',
      iconClass: 'teal',
    },
    {
      icon: <FaChartBar />,
      title: 'Optimized Performance',
      description: 'Keep your cloud environment running at peak efficiency.',
      iconClass: 'teal',
    },
    {
      icon: <FaShieldAlt />,
      title: 'Enhanced Security',
      description: 'Protect against cyber threats.',
      iconClass: 'teal',
    },
    {
      icon: <FaThumbsUp />,
      title: 'Peace of Mind',
      description: 'Focus on your business while we manage your cloud.',
      iconClass: 'teal',
    },
  ];

  return (
    <div className="services-page">
      {/* Hero Section */}
      <section
        className="hero-section"
        style={{
          backgroundImage:
            "url('/images/services/cloud-infrastructure-management/hero-cloud-infrastructure-management.jpg')",
        }}
      >
        <div className="hero-content">
          <h1>Cloud Infrastructure Management</h1>
          <p>Managing cloud infrastructure can be hard, but we’re making it easy. We provide comprehensive
Management services, including monitoring, optimization and support, to ensure trouble-free performance in your cloud environment. Our proactive approach helps us identify and resolve issues quickly before they may affect your operations.
</p>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/cloud-infrastructure-management/parallax1.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Expert Oversight</h2>
        </div>
      </Parallax>

      {/* Services Overview Section */}
      <section className="section light-bg">
        <div className="container">
          <h2>Our Management Services</h2>
          <div className="grid">
            {services.map((service, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${service.iconClass}`}>{service.icon}</div>
                <h3>{service.title}</h3>
                <p>{service.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/cloud-infrastructure-management/parallax2.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Reliable Operations</h2>
        </div>
      </Parallax>

      {/* Benefits Section */}
      <section className="section dark-bg">
        <div className="container">
          <h2>Benefits</h2>
          <div className="grid">
            {benefits.map((benefit, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${benefit.iconClass}`}>{benefit.icon}</div>
                <h3>{benefit.title}</h3>
                <p>{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="cta-section">
        <h2>Ready to Manage Your Cloud Efficiently?</h2>
        <a href="/contact" className="cta-button">
          Contact Us
        </a>
      </section>
    </div>
  );
};

export default CloudInfrastructureManagement;
