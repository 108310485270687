import { default as React } from "react";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom"; // Import useNavigate hook
import Slider from "react-slick"; // Import Slider from react-slick
import "./DasanNetworksPage.css"; // Custom styling for Dasan Networks page
import "./PanduitPage.css"; // Custom styling for Panduit page

// Import slick-carousel CSS
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

// Carousel settings
const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3, // Number of slides to show on desktop
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000, // 3 seconds
  responsive: [
    {
      breakpoint: 1200, // Tablet breakpoint
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 768, // Mobile breakpoint
      settings: {
        slidesToShow: 1,
      },
    },
  ],
  arrows: false, // Hide arrows for a cleaner look
};
// Product Data
const products = [
  {
    name: "Data center",
    // description:
    //   "A 2U rack-mounted NAS designed for enterprise-level storage with redundancy and high performance.",
    image: "/images/products/panduit/panduit-data-center.jpg",
  },
  {
    name: "Power(PDU) Solutions",
    // description:
    //   "A powerful rack-mounted NAS with advanced data management, suitable for SMBs requiring virtualization support.",
    image: "/images/products/panduit/panduit-power-pdu-solutions.png",
  },
  {
    name: "Enterprise network connectivity solutions",
    // description:
    //   "A desktop NAS offering high scalability and performance, ideal for small to medium-sized businesses.",
    image:
      "/images/products/panduit/panduit-enterprise-network-connectivity-solutions.jpg",
  },
  {
    name: "Industrial Ethernet Solutions",
    // description:
    //   "A desktop NAS offering high scalability and performance, ideal for small to medium-sized businesses.",
    image: "/images/products/panduit/panduit-ethernet-network-solutions.jpg",
  },
];
const PanduitPage = () => {
  const navigate = useNavigate(); // Initialize navigate

  return (
    <div className="panduit-page-container">
      <div className="panduit-hero-container">
        <div className="hero-text">
          <h1>Panduit</h1>
          <h2>Innovative Infrastructure Solutions</h2>
          <p>
            Panduit is a global leader in providing innovative solutions for
            electrical and network infrastructure. With a focus on reliability,
            scalability, and performance, Panduit empowers businesses to build
            sustainable and efficient infrastructures that meet the demands of
            the modern world.
          </p>
          <button
            className="learn-more-button"
            onClick={() => navigate("/contact")}
          >
            Learn More
          </button>
        </div>
        <div className="hero-image">
          <img src="/images/logos/panduit-logo.svg" alt="Panduit Logo" />
        </div>
      </div>

      {/* Product Carousel Section */}
      <div className="product-carousel-section">
        <h2 className="carousel-title">Our Panduit Solutions</h2>
        <Slider {...settings} aria-label="Product Carousel">
          {products.map((product, index) => (
            <div key={index} className="product-card">
              <a
                href={`/products/${product.name}`}
                className="product-link"
                aria-label={`Learn more about ${product.name}`}
              >
                <div className="product-image-container">
                  <img
                    src={product.image}
                    alt={`${product.name} Logo`}
                    className="product-image"
                    loading="lazy"
                  />
                </div>
                <h3 className="product-name">{product.name}</h3>
                <p className="product-description">{product.description}</p>
              </a>
            </div>
          ))}
        </Slider>
      </div>

      <div className="video-container">
        <div className="video-responsive">
          <ReactPlayer
            url="https://www.youtube.com/watch?v=0NzalYTQFA8"
            controls
            width="100%"
            height="100%"
            className="react-player"
          />
        </div>
      </div>
    </div>
  );
};

export default PanduitPage;
