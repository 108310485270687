import React from 'react';
import { Parallax } from 'react-parallax';
import './Services.css';
import {
  FaShieldAlt,
  FaServer,
  FaDraftingCompass,
  FaProjectDiagram,
  FaSitemap,
  FaCogs,
  FaRegLightbulb,
} from 'react-icons/fa';

const DataCenterDesign = () => {
  const features = [
    {
      icon: <FaDraftingCompass />,
      title: 'Customized Layouts',
      description: 'Tailored designs to meet your specific data center needs.',
      iconClass: 'blue',
    },
    {
      icon: <FaProjectDiagram />,
      title: 'Scalable Architecture',
      description: 'Designs that grow with your business.',
      iconClass: 'blue',
    },
    {
      icon: <FaSitemap />,
      title: 'Efficient Workflow',
      description: 'Optimized layouts for maximum efficiency.',
      iconClass: 'blue',
    },
    {
      icon: <FaRegLightbulb />,
      title: 'Energy Efficiency',
      description: 'Designs that reduce energy consumption and costs.',
      iconClass: 'blue',
    },
  ];

  const benefits = [
    {
      icon: <FaCogs />,
      title: 'Optimized Performance',
      description: 'Enhance data center operations and reliability.',
      iconClass: 'teal',
    },
    {
      icon: <FaServer />,
      title: 'Future-Proofing',
      description: 'Prepare for future technological advancements.',
      iconClass: 'teal',
    },
    {
      icon: <FaRegLightbulb />,
      title: 'Cost Savings',
      description: 'Reduce operational costs with efficient designs.',
      iconClass: 'teal',
    },
    {
      icon: <FaShieldAlt />,
      title: 'Improved Security',
      description: 'Implement security measures from the ground up.',
      iconClass: 'teal',
    },
  ];

  return (
    <div className="services-page">
      {/* Hero Section */}
      <section
        className="hero-section"
        style={{
          backgroundImage:
            "url('/images/services/data-center-design/hero-data-center-design.jpg')",
        }}
      >
        <div className="hero-content">
          <h1>Data Center Design</h1>
          <p>Crafting state-of-the-art data centers tailored to your needs.</p>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/data-center-design/parallax1.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Innovative Solutions</h2>
        </div>
      </Parallax>

      {/* Key Features Section */}
      <section className="section light-bg">
        <div className="container">
          <h2>Key Features</h2>
          <div className="grid">
            {features.map((feature, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${feature.iconClass}`}>{feature.icon}</div>
                <h3>{feature.title}</h3>
                <p>{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/data-center-design/parallax2.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Build for the Future</h2>
        </div>
      </Parallax>

      {/* Benefits Section */}
      <section className="section dark-bg">
        <div className="container">
          <h2>Benefits</h2>
          <div className="grid">
            {benefits.map((benefit, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${benefit.iconClass}`}>{benefit.icon}</div>
                <h3>{benefit.title}</h3>
                <p>{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="cta-section">
        <h2>Ready to Design Your Data Center?</h2>
        <a href="/contact" className="cta-button">
          Contact Us
        </a>
      </section>
    </div>
  );
};

export default DataCenterDesign;
