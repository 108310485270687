import React from 'react';
import { Parallax } from 'react-parallax';
import './Services.css';
import {
  FaNetworkWired,
  FaBug,
  FaChartBar,
  FaFileAlt,
  FaShieldAlt,
  FaCheckCircle,
  FaBrain,
  FaThumbsUp,
} from 'react-icons/fa';

const VulnerabilityAssessments = () => {
  const services = [
    {
      icon: <FaNetworkWired />,
      title: 'Network Scanning',
      description: 'Identify vulnerabilities within your network infrastructure.',
      iconClass: 'blue',
    },
    {
      icon: <FaBug />,
      title: 'Penetration Testing',
      description: 'Simulate attacks to discover potential security weaknesses.',
      iconClass: 'blue',
    },
    {
      icon: <FaChartBar />,
      title: 'Risk Analysis',
      description: 'Evaluate potential impacts of identified vulnerabilities.',
      iconClass: 'blue',
    },
    {
      icon: <FaFileAlt />,
      title: 'Reporting & Remediation',
      description: 'Provide actionable reports and remediation strategies.',
      iconClass: 'blue',
    },
  ];

  const benefits = [
    {
      icon: <FaShieldAlt />,
      title: 'Improved Security',
      description: 'Address vulnerabilities proactively to enhance security.',
      iconClass: 'teal',
    },
    {
      icon: <FaCheckCircle />,
      title: 'Compliance',
      description: 'Ensure adherence to industry regulations and standards.',
      iconClass: 'teal',
    },
    {
      icon: <FaBrain />,
      title: 'Expert Insights',
      description: 'Leverage the expertise of our security professionals.',
      iconClass: 'teal',
    },
    {
      icon: <FaThumbsUp />,
      title: 'Peace of Mind',
      description: 'Operate confidently knowing your systems are secure.',
      iconClass: 'teal',
    },
  ];

  return (
    <div className="services-page">
      {/* Hero Section */}
      <section
        className="hero-section"
        style={{
          backgroundImage:
            "url('/images/services/vulnerability-assessments/hero-vulnerability-assessments.jpg')",
        }}
      >
        <div className="hero-content">
          <h1>Vulnerability Assessments</h1>
          <p>We conduct thorough vulnerability tests and assessments to discover weaknesses in your Framework, packages, and systems. Our team gives designated reports and actionable suggestions to bolster your defenses and minimize exposure to potential attacks.
          </p>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/vulnerability-assessments/parallax1.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Secure Your Network</h2>
        </div>
      </Parallax>

      {/* Services Section */}
      <section className="section light-bg">
        <div className="container">
          <h2>Our Assessment Services</h2>
          <div className="grid">
            {services.map((service, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${service.iconClass}`}>{service.icon}</div>
                <h3>{service.title}</h3>
                <p>{service.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/vulnerability-assessments/parallax2.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Stay Ahead of Threats</h2>
        </div>
      </Parallax>

      {/* Benefits Section */}
      <section className="section dark-bg">
        <div className="container">
          <h2>Benefits</h2>
          <div className="grid">
            {benefits.map((benefit, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${benefit.iconClass}`}>{benefit.icon}</div>
                <h3>{benefit.title}</h3>
                <p>{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="cta-section">
        <h2>Ready to Assess Your Vulnerabilities?</h2>
        <a href="/contact" className="cta-button">
          Contact Us
        </a>
      </section>
    </div>
  );
};

export default VulnerabilityAssessments;
