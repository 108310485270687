import React from 'react';
import { Parallax } from 'react-parallax';
import './Services.css';
import {
  FaNetworkWired,
  FaExchangeAlt,
  FaCloud,
  FaCogs,
  FaChartLine,
  FaHeadset,
  FaLock,
  FaServer,
} from 'react-icons/fa';

const IoTGateways = () => {
  const features = [
    {
      icon: <FaExchangeAlt />,
      title: 'Protocol Translation',
      description: 'Bridge communication between devices and networks.',
      iconClass: 'blue',
    },
    {
      icon: <FaCloud />,
      title: 'Cloud Integration',
      description: 'Connect edge devices to cloud services seamlessly.',
      iconClass: 'blue',
    },
    {
      icon: <FaLock />,
      title: 'Secure Data Transmission',
      description: 'Ensure data is securely transmitted and received.',
      iconClass: 'blue',
    },
    {
      icon: <FaCogs />,
      title: 'Custom Configuration',
      description: 'Tailor gateway settings to your specific needs.',
      iconClass: 'blue',
    },
  ];

  const benefits = [
    {
      icon: <FaChartLine />,
      title: 'Improved Connectivity',
      description: 'Enhance communication between devices and systems.',
      iconClass: 'teal',
    },
    {
      icon: <FaServer />,
      title: 'Edge Processing',
      description: 'Process data locally to reduce latency.',
      iconClass: 'teal',
    },
    {
      icon: <FaNetworkWired />,
      title: 'Scalable Infrastructure',
      description: 'Easily expand your IoT network.',
      iconClass: 'teal',
    },
    {
      icon: <FaHeadset />,
      title: 'Expert Support',
      description: 'Assistance from our IoT gateway specialists.',
      iconClass: 'teal',
    },
  ];

  return (
    <div className="services-page">
      {/* Hero Section */}
      <section
        className="hero-section"
        style={{
          backgroundImage:
            "url('/images/services/iot-gateways/hero-iot-gateways.jpg')",
        }}
      >
        <div className="hero-content">
          <h1>IoT Gateways</h1>
          <p>Our IoT gateways act as the bridge between your sensors and cloud platforms, permitting secure and efficient data transmission. We make certain that your gateways are configured and controlled for the most suitable performance, taking into consideration real-time facts waft and processing.
          </p>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/iot-gateways/parallax1.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Bridge the Gap</h2>
        </div>
      </Parallax>

      {/* Features Section */}
      <section className="section light-bg">
        <div className="container">
          <h2>Our Gateway Services</h2>
          <div className="grid">
            {features.map((feature, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${feature.iconClass}`}>
                  {feature.icon}
                </div>
                <h3>{feature.title}</h3>
                <p>{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/iot-gateways/parallax2.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Enhance Connectivity</h2>
        </div>
      </Parallax>

      {/* Benefits Section */}
      <section className="section dark-bg">
        <div className="container">
          <h2>Benefits</h2>
          <div className="grid">
            {benefits.map((benefit, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${benefit.iconClass}`}>
                  {benefit.icon}
                </div>
                <h3>{benefit.title}</h3>
                <p>{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="cta-section">
        <h2>Ready to Implement IoT Gateways?</h2>
        <a href="/contact" className="cta-button">
          Contact Us
        </a>
      </section>
    </div>
  );
};

export default IoTGateways;
