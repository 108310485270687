import React from 'react';
import { Parallax } from 'react-parallax';
import './Services.css';
import {
  FaServer,
  FaTools,
  FaEye,
  FaShieldAlt,
  FaClock,
  FaChartBar,
  FaHeadset,
  FaThumbsUp,
} from 'react-icons/fa';

const DataCenterManagement = () => {
  const services = [
    {
      icon: <FaEye />,
      title: '24/7 Monitoring',
      description: 'Constant monitoring to ensure uptime and performance.',
      iconClass: 'blue',
    },
    {
      icon: <FaTools />,
      title: 'Maintenance & Updates',
      description: 'Regular maintenance to keep systems running smoothly.',
      iconClass: 'blue',
    },
    {
      icon: <FaShieldAlt />,
      title: 'Security Management',
      description: 'Protect your data center from threats.',
      iconClass: 'blue',
    },
    {
      icon: <FaHeadset />,
      title: 'Support Services',
      description: 'Dedicated support for all your data center needs.',
      iconClass: 'blue',
    },
  ];

  const benefits = [
    {
      icon: <FaClock />,
      title: 'Maximized Uptime',
      description: 'Ensure your data center is always operational.',
      iconClass: 'teal',
    },
    {
      icon: <FaChartBar />,
      title: 'Optimized Performance',
      description: 'Keep your infrastructure running at peak efficiency.',
      iconClass: 'teal',
    },
    {
      icon: <FaShieldAlt />,
      title: 'Enhanced Security',
      description: 'Protect against physical and cyber threats.',
      iconClass: 'teal',
    },
    {
      icon: <FaThumbsUp />,
      title: 'Peace of Mind',
      description: 'Focus on your business while we manage your data center.',
      iconClass: 'teal',
    },
  ];

  return (
    <div className="services-page">
      {/* Hero Section */}
      <section
        className="hero-section"
        style={{
          backgroundImage:
            "url('/images/services/data-center-management/hero-data-center-management.jpg')",
        }}
      >
        <div className="hero-content">
          <h1>Data Center Management</h1>
          <p>We provide end-to-end solutions, covering the design, implementation, and ongoing management of your data center infrastructure. We build state-of-the-art, scalable, secure, and energy-efficient data centers that prioritize uptime and efficiency, ensuring your business is equipped for both current and future needs. From hardware procurement to installation and configuration, we handle the entire implementation process, integrating servers, storage systems, and network components seamlessly into your operations. Once deployed, we offer continuous management of your data center, focusing on server and storage optimization, virtualization technologies, and security to ensure high performance, minimal downtime, and the flexibility to scale as your business grows.
          </p>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/data-center-management/parallax1.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Expert Oversight</h2>
        </div>
      </Parallax>

      {/* Services Overview Section */}
      <section className="section light-bg">
        <div className="container">
          <h2>Our Management Services</h2>
          <div className="grid">
            {services.map((service, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${service.iconClass}`}>{service.icon}</div>
                <h3>{service.title}</h3>
                <p>{service.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/data-center-management/parallax2.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Reliable Operations</h2>
        </div>
      </Parallax>

      {/* Benefits Section */}
      <section className="section dark-bg">
        <div className="container">
          <h2>Benefits</h2>
          <div className="grid">
            {benefits.map((benefit, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${benefit.iconClass}`}>{benefit.icon}</div>
                <h3>{benefit.title}</h3>
                <p>{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="cta-section">
        <h2>Ready to Manage Your Data Center Efficiently?</h2>
        <a href="/contact" className="cta-button">
          Contact Us
        </a>
      </section>
    </div>
  );
};

export default DataCenterManagement;
