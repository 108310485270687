// File: src/components/BrandsPage.jsx

import React from 'react';
import PartnersSection from './PartnersSection'; // Import the PartnersSection component
import './BrandsPage.css'; // Import the separate CSS file

const BrandsPage = () => {
  return (
    <main className="brands-page" aria-labelledby="brands-heading">
      <header className="brands-page__intro">
        <h1 id="brands-heading" className="brands-page__title">
          Our Trusted Brands
        </h1>
        <p className="brands-page__description">
          We are proud to partner with some of the most renowned and trusted brands in the industry. These partnerships enable us to provide cutting-edge solutions that drive innovation and success for our clients.
        </p>
      </header>
      <PartnersSection /> {/* Include the PartnersSection here */}
    </main>
  );
};

export default BrandsPage;
