import React from "react";
import {
  FaCloud,
  FaDatabase,
  FaProjectDiagram,
  FaRegLifeRing,
  FaServer,
  FaTools,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import "./DataCenterInfrastructure.css";

// Import images from the 'public' directory using 'process.env.PUBLIC_URL'
const heroImage = `${process.env.PUBLIC_URL}/images/DataCenterInfrastructure/hero-datacenter.jpg`;
const parallaxImage = `${process.env.PUBLIC_URL}/images/DataCenterInfrastructure/parallax.jpeg`;
const overviewImage = `${process.env.PUBLIC_URL}/images/DataCenterInfrastructure/overview.jpg`;

// Service images
const serviceImages = {
  dataCenterDesign: `${process.env.PUBLIC_URL}/images/services/data-center-design.jpg`,
  dataCenterImplementation: `${process.env.PUBLIC_URL}/images/services/data-center-implementation.jpg`,
  serverSolutions: `${process.env.PUBLIC_URL}/images/services/server-solutions.jpg`,
  storageSystems: `${process.env.PUBLIC_URL}/images/services/storage-systems.jpg`,
  virtualizationTechnologies: `${process.env.PUBLIC_URL}/images/services/virtualization-technologies.jpg`,
  dataCenterManagement: `${process.env.PUBLIC_URL}/images/services/data-center-management.jpg`,
  disasterRecoverySolutions: `${process.env.PUBLIC_URL}/images/services/disaster-recovery-solutions.jpg`,
};

// Brand logos
const brands = [
  {
    name: "Dell EMC",
    logo: `${process.env.PUBLIC_URL}/images/brands/dell-emc-logo.png`,
    link: "/brands/dell-emc",
    alt: "Dell EMC",
  },
  {
    name: "Hewlett Packard Enterprise",
    logo: `${process.env.PUBLIC_URL}/images/brands/hpe-logo.png`,
    link: "/brands/hpe",
    alt: "HPE",
  },
  {
    name: "IBM",
    logo: `${process.env.PUBLIC_URL}/images/brands/ibm-logo.png`,
    link: "/brands/ibm",
    alt: "IBM",
  },
  {
    name: "VMware",
    logo: `${process.env.PUBLIC_URL}/images/brands/vmware-logo.png`,
    link: "/brands/vmware",
    alt: "VMware",
  },
];

const services = [
  {
    title: "Data Center Design",
    description:
      "Customized data center designs that meet your business requirements for scalability, efficiency, and security. Our expert architects ensure your data center is future-ready.",
    icon: <FaProjectDiagram />,
    image: serviceImages.dataCenterDesign,
    link: "/services/data-center-design",
  },
  {
    title: "Data Center Implementation",
    description:
      "Full installation and configuration services, ensuring seamless deployment of data center infrastructure, from servers and storage systems to networking and security.",
    icon: <FaTools />,
    image: serviceImages.dataCenterImplementation,
    link: "/services/data-center-implementation",
  },
  {
    title: "Server Solutions",
    description:
      "High-performance server solutions tailored to your workload demands, ensuring reliability, scalability, and optimized performance for your applications.",
    icon: <FaServer />,
    image: serviceImages.serverSolutions,
    link: "/services/server-solutions",
  },
  {
    title: "Storage Systems",
    description:
      "Robust and scalable storage solutions, including SAN, NAS, and cloud storage, to securely store and manage your critical data efficiently.",
    icon: <FaDatabase />,
    image: serviceImages.storageSystems,
    link: "/services/storage-systems",
  },
  {
    title: "Virtualization Technologies",
    description:
      "Leverage virtualization to optimize resource utilization, reduce costs, and improve scalability with our virtualization solutions using industry-leading technologies.",
    icon: <FaCloud />,
    image: serviceImages.virtualizationTechnologies,
    link: "/services/virtualization-technologies",
  },
  {
    title: "Data Center Management",
    description:
      "Comprehensive management services to monitor, maintain, and optimize your data center operations, ensuring maximum uptime and efficiency.",
    icon: <FaServer />,
    image: serviceImages.dataCenterManagement,
    link: "/services/data-center-management",
  },
  {
    title: "Disaster Recovery Solutions",
    description:
      "Protect your business from unexpected disruptions with our disaster recovery solutions, ensuring business continuity and minimal downtime.",
    icon: <FaRegLifeRing />,
    image: serviceImages.disasterRecoverySolutions,
    link: "/services/disaster-recovery-solutions",
  },
];

const DataCenterInfrastructure = () => {
  return (
    <div className="data-center-infrastructure">
      {/* Hero Section */}
      <section
        className="hero-section"
        style={{
          backgroundImage: `url(${heroImage})`,
        }}
      >
        <div className="hero-overlay">
          <h1>Data Center Infrastructure Solutions</h1>
          <p>
            Build a robust, scalable, and secure data center infrastructure with
            our expert design and implementation services. From server solutions
            to virtualization technologies, we ensure your data center meets
            your current and future business needs.
          </p>
          <Link to="/contact" className="hero-button">
            Get Started
          </Link>
        </div>
        <div className="scroll-down-indicator"></div>
      </section>

      {/* Overview Section */}
      <section className="overview-section">
        <div className="container">
          <h2>Empower Your Business with Advanced Data Center Solutions</h2>
          <div className="overview-content">
            <div className="overview-text">
              <p>
                In today's data-driven world, your data center is the heart of
                your business operations. We provide customized solutions to
                ensure seamless data management, security, and efficiency,
                regardless of the size or complexity of your data center.
              </p>
              <p>
                Our partnerships with leading brands such as{" "}
                <strong>Dell EMC</strong>, <strong>HPE</strong>,{" "}
                <strong>IBM</strong>, <strong>VMware</strong>, and more allow us
                to offer high-quality, cutting-edge technology that ensures top
                performance and reliability.
              </p>
            </div>
            <div className="overview-image">
              <img src={overviewImage} alt="Data Center Overview" />
            </div>
          </div>
        </div>
      </section>

      {/* Services Offered Section */}
      <section className="data-center-services-section">
        <div className="container">
          <h2>Our Services</h2>
          <div className="data-center-services-grid">
            {services.map((service, index) => (
              <Link
                to={service.link}
                className="data-center-service-card"
                key={index}
              >
                <div
                  className="data-center-service-image"
                  style={{ backgroundImage: `url(${service.image})` }}
                >
                  <div className="data-center-service-icon">{service.icon}</div>
                </div>
                <div className="data-center-service-content">
                  <h3>{service.title}</h3>
                  <p>{service.description}</p>
                  <span className="data-center-read-more">Read More</span>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </section>

      {/* Parallax Section */}
      <section
        className="parallax-section"
        style={{
          backgroundImage: `url(${parallaxImage})`,
        }}
      >
        <div className="parallax-overlay">
          <h2>Innovative Data Center Solutions for Modern Businesses</h2>
          <p>
            Stay ahead of the competition with our state-of-the-art data center
            solutions tailored to your business needs.
          </p>
          <Link to="/solutions" className="parallax-button">
            Learn More
          </Link>
        </div>
      </section>

      {/* Call to Action */}
      <section className="cta-section">
        <div className="container">
          <h2>Ready to Transform Your Data Center?</h2>
          <p>
            Contact us today for a consultation and let’s build a robust,
            secure, and scalable data center infrastructure tailored to your
            business needs.
          </p>
          <Link to="/contact" className="cta-button">
            Get Started
          </Link>
        </div>
      </section>
    </div>
  );
};

export default DataCenterInfrastructure;
