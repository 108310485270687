import React from 'react';
import { Parallax } from 'react-parallax';
import './Services.css';
import {
  FaCloud,
  FaDraftingCompass,
  FaProjectDiagram,
  FaCogs,
  FaRegLightbulb,
  FaShieldAlt,
  FaArrowsAlt,
  FaHeadset,
} from 'react-icons/fa';

const CloudArchitectureDesign = () => {
  const features = [
    {
      icon: <FaDraftingCompass />,
      title: 'Custom Designs',
      description: 'Architect cloud solutions tailored to your business needs.',
      iconClass: 'blue',
    },
    {
      icon: <FaProjectDiagram />,
      title: 'Scalable Architectures',
      description: 'Designs that scale seamlessly with your growth.',
      iconClass: 'blue',
    },
    {
      icon: <FaShieldAlt />,
      title: 'Security Integration',
      description: 'Security measures built into your cloud architecture.',
      iconClass: 'blue',
    },
    {
      icon: <FaRegLightbulb />,
      title: 'Innovative Solutions',
      description: 'Leverage the latest cloud technologies.',
      iconClass: 'blue',
    },
  ];

  const benefits = [
    {
      icon: <FaCogs />,
      title: 'Optimized Performance',
      description: 'Efficient architectures for better performance.',
      iconClass: 'teal',
    },
    {
      icon: <FaArrowsAlt />,
      title: 'Flexibility',
      description: 'Adaptable designs to meet changing demands.',
      iconClass: 'teal',
    },
    {
      icon: <FaShieldAlt />,
      title: 'Enhanced Security',
      description: 'Protect your assets with secure architectures.',
      iconClass: 'teal',
    },
    {
      icon: <FaHeadset />,
      title: 'Expert Support',
      description: 'Guidance from our cloud architecture experts.',
      iconClass: 'teal',
    },
  ];

  return (
    <div className="services-page">
      {/* Hero Section */}
      <section
        className="hero-section"
        style={{
          backgroundImage:
            "url('/images/services/cloud-architecture-design/hero-cloud-architecture-design.jpg')",
        }}
      >
        <div className="hero-content">
          <h1>Cloud Architecture Design</h1>
          <p>We build robust, scalable cloud infrastructure to fit your business needs Whether a public, private, hybrid or cloud infrastructure is needed, we ensure that your cloud environment is Optimized for performance, safety and cost.
          </p>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/cloud-architecture-design/parallax1.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Innovative Cloud Solutions</h2>
        </div>
      </Parallax>

      {/* Key Features Section */}
      <section className="section light-bg">
        <div className="container">
          <h2>Key Features</h2>
          <div className="grid">
            {features.map((feature, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${feature.iconClass}`}>{feature.icon}</div>
                <h3>{feature.title}</h3>
                <p>{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/cloud-architecture-design/parallax2.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Build on a Solid Foundation</h2>
        </div>
      </Parallax>

      {/* Benefits Section */}
      <section className="section dark-bg">
        <div className="container">
          <h2>Benefits</h2>
          <div className="grid">
            {benefits.map((benefit, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${benefit.iconClass}`}>{benefit.icon}</div>
                <h3>{benefit.title}</h3>
                <p>{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="cta-section">
        <h2>Ready to Design Your Cloud Architecture?</h2>
        <a href="/contact" className="cta-button">
          Contact Us
        </a>
      </section>
    </div>
  );
};

export default CloudArchitectureDesign;
