import React from 'react';
import { Parallax } from 'react-parallax';
import './Services.css';
import {
  FaShieldAlt,
  FaBug,
  FaLock,
  FaCheckCircle,
  FaUserSecret,
  FaBrain,
} from 'react-icons/fa';

const NetworkSecurity = () => {
  const securityServices = [
    {
      icon: <FaShieldAlt />,
      title: 'Firewall Management',
      description: 'Implement and manage robust firewalls.',
      iconClass: 'blue',
    },
    {
      icon: <FaBug />,
      title: 'Intrusion Detection',
      description: 'Monitor and prevent unauthorized access.',
      iconClass: 'blue',
    },
    {
      icon: <FaLock />,
      title: 'Endpoint Security',
      description: 'Protect devices within your network.',
      iconClass: 'blue',
    },
    {
      icon: <FaCheckCircle />,
      title: 'Compliance Management',
      description: 'Ensure adherence to industry standards.',
      iconClass: 'blue',
    },
  ];

  const benefits = [
    {
      icon: <FaShieldAlt />,
      title: 'Threat Protection',
      description: 'Safeguard against malware and cyber attacks.',
      iconClass: 'teal',
    },
    {
      icon: <FaUserSecret />,
      title: 'Data Privacy',
      description: 'Protect sensitive information from breaches.',
      iconClass: 'teal',
    },
    {
      icon: <FaCheckCircle />,
      title: 'Regulatory Compliance',
      description: 'Avoid penalties by meeting compliance requirements.',
      iconClass: 'teal',
    },
    {
      icon: <FaBrain />,
      title: 'Proactive Approach',
      description: 'Stay ahead with continuous monitoring.',
      iconClass: 'teal',
    },
  ];

  return (
    <div className="services-page">
      {/* Hero Section */}
      <section
        className="hero-section"
        style={{
          backgroundImage:
            "url('/images/services/network-security/hero-network-security.jpg')",
        }}
      >
        <div className="hero-content">
          <h1>Network Security</h1>
          <p>Our Network Security solutions are designed to protect your organization’s data, infrastructure, and users from cyber threats. We integrate robust security measures across LAN, WAN, and wireless networks to safeguard against intrusions, breaches, and vulnerabilities.
</p>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/network-security/parallax1.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Fortify Your Defenses</h2>
        </div>
      </Parallax>

      {/* Security Services Section */}
      <section className="section light-bg">
        <div className="container">
          <h2>Our Security Services</h2>
          <div className="grid">
            {securityServices.map((service, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${service.iconClass}`}>{service.icon}</div>
                <h3>{service.title}</h3>
                <p>{service.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/network-security/parallax2.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Stay Ahead of Threats</h2>
        </div>
      </Parallax>

      {/* Benefits Section */}
      <section className="section dark-bg">
        <div className="container">
          <h2>Benefits</h2>
          <div className="grid">
            {benefits.map((benefit, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${benefit.iconClass}`}>{benefit.icon}</div>
                <h3>{benefit.title}</h3>
                <p>{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="cta-section">
        <h2>Ready to Secure Your Network?</h2>
        <a href="/contact" className="cta-button">
          Contact Us
        </a>
      </section>
    </div>
  );
};

export default NetworkSecurity;
