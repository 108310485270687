// src/pages/PrivacyPolicy.jsx

import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
  return (
    <main className="privacy-section" role="main">
      <div className="container">
        <h1>Privacy Policy</h1>
        <div className="content">
          <div className="main-content">
            <div className="section">
              <h2>Introduction</h2>
              <p>
                Rajguru Distributors is committed to safeguarding the privacy of our clients and users of our services. This Privacy Policy outlines our practices regarding the collection, use, and disclosure of personal information that we may receive from clients and visitors to our website. By using our services, you agree to the collection and use of information in accordance with this policy.
              </p>
            </div>

            <div className="section">
              <h2>Information We Collect</h2>
              <p>We may collect various types of information to provide and improve our services:</p>
              <ul className="sub-list">
                <li>
                  <strong>Personal Information:</strong> This includes any information that can identify you personally, such as:
                  <ul className="nested-sub-list">
                    <li>
                      <strong>Contact Information:</strong> Name, email address, phone number, and mailing address.
                    </li>
                    <li>
                      <strong>Account Information:</strong> Usernames, passwords, and other credentials used to access our services.
                    </li>
                    <li>
                      <strong>Payment Information:</strong> Billing details necessary for processing payments (note: we may use third-party payment processors who have their own privacy policies).
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>Usage Data:</strong> We may collect information about how you access and use our website and services. This may include:
                  <ul className="nested-sub-list">
                    <li>Your IP address</li>
                    <li>Browser type and version</li>
                    <li>Pages visited on our site</li>
                    <li>Time and date of your visit</li>
                    <li>Time spent on those pages</li>
                    <li>Unique device identifiers</li>
                  </ul>
                </li>
                <li>
                  <strong>Cookies and Tracking Technologies:</strong> We use cookies and similar tracking technologies to monitor activity on our website and store certain information. Cookies are files with small amounts of data that may include an anonymous unique identifier. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our service.
                </li>
              </ul>
            </div>

            <div className="section">
              <h2>How We Use Your Information</h2>
              <p>The Company uses the collected information for various purposes, including but not limited to:</p>
              <ul className="sub-list">
                <li>
                  <strong>Providing Services:</strong> To deliver the services you request, including managing your account and processing transactions.
                </li>
                <li>
                  <strong>Communication:</strong> To communicate with you about your account or transactions, respond to inquiries, provide customer support, and send updates or promotional materials.
                </li>
                <li>
                  <strong>Improvement of Services:</strong> To analyze usage patterns and improve our website and services based on user feedback.
                </li>
                <li>
                  <strong>Marketing Purposes:</strong> To send you newsletters, marketing or promotional materials, and other information that may be of interest to you. You can opt out of receiving any or all of these communications by following the unsubscribe link or instructions provided in any email we send.
                </li>
              </ul>
            </div>

            <div className="section">
              <h2>Disclosure of Your Information</h2>
              <p>We may share your personal information in the following situations:</p>
              <ul className="sub-list">
                <li>
                  <strong>With Service Providers:</strong> We may share your information with third-party service providers who perform services on our behalf (e.g., payment processing, data analysis, email delivery). These third parties are obligated not to disclose or use it for any other purpose.
                </li>
                <li>
                  <strong>For Legal Reasons:</strong> We may disclose your personal information if required to do so by law or in response to valid requests by public authorities (e.g., a court or government agency). This includes responding to subpoenas or other legal processes.
                </li>
                <li>
                  <strong>Business Transfers:</strong> If the Company is involved in a merger, acquisition, or asset sale, your personal information may be transferred as part of that transaction. We will provide notice before your personal information is transferred and becomes subject to a different Privacy Policy.
                </li>
              </ul>
            </div>

            <div className="section">
              <h2>Data Security</h2>
              <p>
                The security of your data is important to us. We implement reasonable security measures designed to protect your personal information from unauthorized access, use, alteration, or destruction. These measures include encryption of sensitive data during transmission and storage.
              </p>
              <p>
                However, please remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to protect your personal information using commercially acceptable means, we cannot guarantee its absolute security.
              </p>
            </div>

            <div className="section">
              <h2>Your Rights</h2>
              <p>Depending on your location and applicable laws, you may have the following rights regarding your personal information:</p>
              <ul className="sub-list">
                <li>
                  <strong>Right to Access:</strong> You have the right to request copies of your personal data held by us.
                </li>
                <li>
                  <strong>Right to Rectification:</strong> You have the right to request correction of any inaccurate personal data we hold about you.
                </li>
                <li>
                  <strong>Right to Erasure:</strong> You have the right to request deletion of your personal data under certain conditions.
                </li>
                <li>
                  <strong>Right to Restrict Processing:</strong> You have the right to request that we restrict the processing of your personal data under certain conditions.
                </li>
                <li>
                  <strong>Right to Data Portability:</strong> You have the right to request that we transfer the data that we have collected to another organization or directly to you under certain conditions.
                </li>
              </ul>
              <p>To exercise any of these rights, please contact us using the contact details provided below.</p>
            </div>

            <div className="section">
              <h2>Third-Party Links</h2>
              <p>
                Our website may contain links to third-party websites that are not operated by us. If you click on a third-party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy and terms of service of any third-party site you visit. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.
              </p>
            </div>

            <div className="section">
              <h2>Changes to This Privacy Policy</h2>
              <p>
                We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page with an updated effective date at the top. You are advised to review this Privacy Policy periodically for any changes. Changes are effective when they are posted on this page.
              </p>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default PrivacyPolicy;
