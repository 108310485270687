import React from "react";
import Marquee from "react-fast-marquee";
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./BrandsCarousel.css";

const partners = [
  {
    name: "Volktek",
    logo: "/images/logos/new/volktek.svg",
    path: "/brands/volktek",
  },
  {
    name: "TP-Link",
    logo: "/images/logos/new/all_logos.svg",
    path: "/brands/tplink",
  },
  {
    name: "Panduit",
    logo: "/images/logos/new/all_logos__1.svg",
    path: "/brands/panduit",
  },
  {
    name: "Micron",
    logo: "/images/logos/new/all_logos__2.svg",
    path: "/brands/micron",
  },
  {
    name: "Lenovo",
    logo: "/images/logos/new/all_logos__3.svg",
    path: "/brands/lenovo",
  },
  {
    name: "Logitech",
    logo: "/images/logos/new/all_logos__4.svg",
    path: "/brands/logitech",
  },
  {
    name: "Fluke Networks",
    logo: "/images/logos/new/all_logos__5.svg",
    path: "/brands/fluke-networks",
  },
  {
    name: "Synology",
    logo: "/images/logos/new/all_logos__6.svg",
    path: "/brands/synology",
  },
  {
    name: "Juniper",
    logo: "/images/logos/new/all_logos__7.svg",
    path: "/brands/juniper",
  },
  {
    name: "HPE",
    logo: "/images/logos/new/all_logos__8.svg",
    path: "/brands/hpe",
  },
  {
    name: "Poly",
    logo: "/images/logos/new/all_logos__9.svg",
    path: "/brands/poly",
  },
  {
    name: "VCA",
    logo: "/images/logos/new/all_logos__10.svg",
    path: "/brands/vca",
  },
  {
    name: "Microsoft",
    logo: "/images/logos/new/all_logos__11.svg",
    path: "/brands/microsoft",
  },
  {
    name: "Ubiquiti",
    logo: "/images/logos/new/U-with-bg.svg",
    path: "/brands/ubiquiti",
  },
  { name: "HP", logo: "/images/logos/new/HP.svg", path: "/brands/hp" },
];

const BrandsCarousel = () => {
  return (
    <section className="brands-section">
      <div className="brands-header">
        <h2>Our Trusted Partners</h2>
        <p style={{ color: "#666" }}>
          We collaborate with industry-leading brands to deliver top-notch
          solutions.
        </p>
      </div>
      <div className="carousel-wrapper">
        <Marquee gradient={false} speed={80} pauseOnHover={true}>
          {partners.map((partner, index) => (
            <div key={index} className="brand-slide">
              <Link
                to={partner.path}
                aria-label={`Learn more about ${partner.name}`}
              >
                <img
                  src={partner.logo}
                  alt={`${partner.name} Logo`}
                  className="brand-logo"
                  style={{
                    ...(partner.name === "HP" ? { height: "60px" } : {}),
                    ...(partner.name === "Volktek" ? { height: "60px" } : {}),
                    ...(partner.name === "Ubiquiti" ? { height: "80px" } : {}),
                  }}
                />
              </Link>
            </div>
          ))}
        </Marquee>
      </div>
      <Link to="/brands" className="know-more-button">
        Know More <FaArrowRight className="button-icon" />
      </Link>
    </section>
  );
};

export default BrandsCarousel;
