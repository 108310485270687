import React from 'react';
import { Parallax } from 'react-parallax';
import './Services.css';
import {
  FaDesktop,
  FaCloud,
  FaUsers,
  FaSyncAlt,
  FaArrowsAlt,
  FaDollarSign,
  FaHeadset,
  FaShieldAlt,
} from 'react-icons/fa';

const DesktopVirtualization = () => {
  const solutions = [
    {
      icon: <FaDesktop />,
      title: 'Virtual Desktop Infrastructure (VDI)',
      description: 'Deploy virtual desktops accessible from anywhere.',
      iconClass: 'blue',
    },
    {
      icon: <FaCloud />,
      title: 'Cloud-Based Desktops',
      description: 'Host desktops in the cloud for flexibility.',
      iconClass: 'blue',
    },
    {
      icon: <FaUsers />,
      title: 'Multi-User Access',
      description: 'Support multiple users with centralized management.',
      iconClass: 'blue',
    },
    {
      icon: <FaSyncAlt />,
      title: 'Seamless Integration',
      description: 'Integrate with existing systems and applications.',
      iconClass: 'blue',
    },
  ];

  const benefits = [
    {
      icon: <FaArrowsAlt />,
      title: 'Flexibility',
      description: 'Access desktops from any device, anywhere.',
      iconClass: 'teal',
    },
    {
      icon: <FaDollarSign />,
      title: 'Cost Savings',
      description: 'Reduce hardware and maintenance costs.',
      iconClass: 'teal',
    },
    {
      icon: <FaShieldAlt />,
      title: 'Enhanced Security',
      description: 'Centralized control reduces security risks.',
      iconClass: 'teal',
    },
    {
      icon: <FaHeadset />,
      title: 'Expert Support',
      description: 'Assistance from our desktop virtualization experts.',
      iconClass: 'teal',
    },
  ];

  return (
    <div className="services-page">
      {/* Hero Section */}
      <section
        className="hero-section"
        style={{
          backgroundImage:
            "url('/images/services/desktop-virtualization/hero-desktop-virtualization.jpg')",
        }}
      >
        <div className="hero-content">
          <h1>Desktop Virtualization</h1>
          <p>Our desktop virtualization services provide centralized management and delivery of desktop environments for end users. By virtualizing desktops, we allow employees to access their Workspace, improve security and simplify IT management from any device, anywhere. This solution reduces the need for virtual workstations and reduces hardware costs.
</p>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/desktop-virtualization/parallax1.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Work Anywhere</h2>
        </div>
      </Parallax>

      {/* Solutions Section */}
      <section className="section light-bg">
        <div className="container">
          <h2>Our Desktop Virtualization Services</h2>
          <div className="grid">
            {solutions.map((solution, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${solution.iconClass}`}>{solution.icon}</div>
                <h3>{solution.title}</h3>
                <p>{solution.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/desktop-virtualization/parallax2.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Empower Your Team</h2>
        </div>
      </Parallax>

      {/* Benefits Section */}
      <section className="section dark-bg">
        <div className="container">
          <h2>Benefits</h2>
          <div className="grid">
            {benefits.map((benefit, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${benefit.iconClass}`}>{benefit.icon}</div>
                <h3>{benefit.title}</h3>
                <p>{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="cta-section">
        <h2>Ready to Virtualize Your Desktops?</h2>
        <a href="/contact" className="cta-button">
          Contact Us
        </a>
      </section>
    </div>
  );
};

export default DesktopVirtualization;
