import React from 'react';
import { Parallax } from 'react-parallax';
import './Services.css';
import {
  FaVideo,
  FaUsers,
  FaDesktop,
  FaLock,
  FaSyncAlt,
  FaHeadset,
  FaShareSquare,
  FaMicrophone,
} from 'react-icons/fa';

const VideoConferencing = () => {
  const features = [
    {
      icon: <FaVideo />,
      title: 'High-Definition Video',
      description: 'Crystal clear video quality for meetings.',
      iconClass: 'blue',
    },
    {
      icon: <FaUsers />,
      title: 'Group Meetings',
      description: 'Host meetings with multiple participants.',
      iconClass: 'blue',
    },
    {
      icon: <FaDesktop />,
      title: 'Screen Sharing',
      description: 'Share your screen for presentations.',
      iconClass: 'blue',
    },
    {
      icon: <FaLock />,
      title: 'Secure Connections',
      description: 'Encrypted calls to protect your privacy.',
      iconClass: 'blue',
    },
  ];

  const benefits = [
    {
      icon: <FaSyncAlt />,
      title: 'Enhanced Collaboration',
      description: 'Connect with your team anywhere.',
      iconClass: 'teal',
    },
    {
      icon: <FaHeadset />,
      title: 'Expert Support',
      description: 'Assistance from our conferencing specialists.',
      iconClass: 'teal',
    },
    {
      icon: <FaShareSquare />,
      title: 'Easy Sharing',
      description: 'Share documents and files during calls.',
      iconClass: 'teal',
    },
    {
      icon: <FaMicrophone />,
      title: 'Clear Audio',
      description: 'High-quality audio for effective communication.',
      iconClass: 'teal',
    },
  ];

  return (
    <div className="services-page">
      {/* Hero Section */}
      <section
        className="hero-section"
        style={{
          backgroundImage:
            "url('/images/services/video-conferencing/hero-video-conferencing.jpg')",
        }}
      >
        <div className="hero-content">
          <h1>Video Conferencing Solutions</h1>
          <p>Stay connected with your groups and companions through our steady video conferencing services.
We provide wonderful video and audio conferencing gear that permits seamless digital conferences, ensuring easy verbal exchange and collaboration throughout distances, with strong
security measures in the region to save you unauthorized right of entry.
</p>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/video-conferencing/parallax1.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Connect Anywhere</h2>
        </div>
      </Parallax>

      {/* Features Section */}
      <section className="section light-bg">
        <div className="container">
          <h2>Our Conferencing Features</h2>
          <div className="grid">
            {features.map((feature, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${feature.iconClass}`}>
                  {feature.icon}
                </div>
                <h3>{feature.title}</h3>
                <p>{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/video-conferencing/parallax2.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Engage with Ease</h2>
        </div>
      </Parallax>

      {/* Benefits Section */}
      <section className="section dark-bg">
        <div className="container">
          <h2>Benefits</h2>
          <div className="grid">
            {benefits.map((benefit, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${benefit.iconClass}`}>
                  {benefit.icon}
                </div>
                <h3>{benefit.title}</h3>
                <p>{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="cta-section">
        <h2>Ready to Start Conferencing?</h2>
        <a href="/contact" className="cta-button">
          Contact Us
        </a>
      </section>
    </div>
  );
};

export default VideoConferencing;
