import React from 'react';
import { Parallax } from 'react-parallax';
import './Services.css';
import {
  FaWifi,
  FaBroadcastTower,
  FaMobileAlt,
  FaCloud,
  FaSignal,
  FaMoneyBillWave,
  FaArrowsAlt,
  FaUsers,
} from 'react-icons/fa';

const WirelessNetworkSolutions = () => {
  const technologies = [
    {
      icon: <FaWifi />,
      title: 'Wi-Fi 6 Solutions',
      description: 'Faster speeds and improved performance.',
      iconClass: 'blue',
    },
    {
      icon: <FaBroadcastTower />,
      title: 'Mesh Networking',
      description: 'Seamless coverage across large areas.',
      iconClass: 'blue',
    },
    {
      icon: <FaMobileAlt />,
      title: 'Mobile Integration',
      description: 'Connect mobile devices effortlessly.',
      iconClass: 'blue',
    },
    {
      icon: <FaCloud />,
      title: 'Cloud-Based Management',
      description: 'Manage your network from anywhere.',
      iconClass: 'blue',
    },
  ];

  const benefits = [
    {
      icon: <FaSignal />,
      title: 'Unleashed Mobility',
      description: 'Work from anywhere within your premises.',
      iconClass: 'teal',
    },
    {
      icon: <FaMoneyBillWave />,
      title: 'Cost Savings',
      description: 'Reduce cabling and maintenance costs.',
      iconClass: 'teal',
    },
    {
      icon: <FaArrowsAlt />,
      title: 'Easy Scalability',
      description: 'Add new devices without hassle.',
      iconClass: 'teal',
    },
    {
      icon: <FaUsers />,
      title: 'Enhanced Collaboration',
      description: 'Improve teamwork with better connectivity.',
      iconClass: 'teal',
    },
  ];

  return (
    <div className="services-page">
      {/* Hero Section */}
      <section
        className="hero-section"
        style={{
          backgroundImage:
            "url('/images/services/wireless-network-solutions/hero-wireless-network-solutions.jpg')",
        }}
      >
        <div className="hero-content">
          <h1>Wireless Network Solutions</h1>
          <p>Stay connected with our wi-fi community solutions, which provide steady and reliable entry 
throughout your office, warehouse, or campus. We layout and install organization-grade Wi-Fi
networks that make certain consistent coverage, velocity, and security for all devices and users.
We additionally offer point-to-factor and point to multi-point wi-fi networks
</p>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/wireless-network-solutions/parallax1.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Unleash Mobility</h2>
        </div>
      </Parallax>

      {/* Wireless Technologies Section */}
      <section className="section light-bg">
        <div className="container">
          <h2>Wireless Technologies We Offer</h2>
          <div className="grid">
            {technologies.map((tech, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${tech.iconClass}`}>{tech.icon}</div>
                <h3>{tech.title}</h3>
                <p>{tech.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/wireless-network-solutions/parallax2.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Stay Connected</h2>
        </div>
      </Parallax>

      {/* Benefits Section */}
      <section className="section dark-bg">
        <div className="container">
          <h2>Benefits</h2>
          <div className="grid">
            {benefits.map((benefit, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${benefit.iconClass}`}>{benefit.icon}</div>
                <h3>{benefit.title}</h3>
                <p>{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="cta-section">
        <h2>Ready to Go Wireless?</h2>
        <a href="/contact" className="cta-button">
          Contact Us
        </a>
      </section>
    </div>
  );
};

export default WirelessNetworkSolutions;
