import React from 'react';
import { Parallax } from 'react-parallax';
import './Services.css';
import {
  FaSyncAlt,
  FaCloudUploadAlt,
  FaShieldAlt,
  FaServer,
  FaLock,
  FaHistory,
  FaHeadset,
  FaLifeRing,
} from 'react-icons/fa';

const DisasterRecovery = () => {
  const features = [
    {
      icon: <FaCloudUploadAlt />,
      title: 'Cloud Backups',
      description: 'Secure off-site backups of your data.',
      iconClass: 'blue',
    },
    {
      icon: <FaSyncAlt />,
      title: 'Automated Backup Scheduling',
      description: 'Set it and forget it with automated backups.',
      iconClass: 'blue',
    },
    {
      icon: <FaShieldAlt />,
      title: 'Data Encryption',
      description: 'Protect backups with advanced encryption.',
      iconClass: 'blue',
    },
    {
      icon: <FaServer />,
      title: 'Disaster Recovery Planning',
      description: 'Develop strategies to restore operations quickly.',
      iconClass: 'blue',
    },
  ];

  const benefits = [
    {
      icon: <FaLock />,
      title: 'Data Security',
      description: 'Safeguard your critical information.',
      iconClass: 'teal',
    },
    {
      icon: <FaHistory />,
      title: 'Rapid Recovery',
      description: 'Minimize downtime after a disaster.',
      iconClass: 'teal',
    },
    {
      icon: <FaHeadset />,
      title: 'Expert Support',
      description: 'Assistance during planning and recovery.',
      iconClass: 'teal',
    },
    {
      icon: <FaLifeRing />,
      title: 'Business Continuity',
      description: 'Ensure your business can withstand disruptions.',
      iconClass: 'teal',
    },
  ];

  return (
    <div className="services-page">
      {/* Hero Section */}
      <section
        className="hero-section"
        style={{
          backgroundImage:
            "url('/images/services/disaster-recovery/hero-disaster-recovery.jpg')",
        }}
      >
        <div className="hero-content">
          <h1>Disaster Recovery & Backup Solutions</h1>
          <p>We provide Emergency restoration and backup solutions to defend your essential records in the occasion of machine failure or cyberattacks. Our services ensure that your enterprise can get better quickly and
keep operations with minimal data loss and downtime.
</p>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/disaster-recovery/parallax1.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Be Prepared</h2>
        </div>
      </Parallax>

      {/* Features Section */}
      <section className="section light-bg">
        <div className="container">
          <h2>Our Recovery Solutions</h2>
          <div className="grid">
            {features.map((feature, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${feature.iconClass}`}>
                  {feature.icon}
                </div>
                <h3>{feature.title}</h3>
                <p>{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/disaster-recovery/parallax2.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Secure Your Future</h2>
        </div>
      </Parallax>

      {/* Benefits Section */}
      <section className="section dark-bg">
        <div className="container">
          <h2>Benefits</h2>
          <div className="grid">
            {benefits.map((benefit, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${benefit.iconClass}`}>
                  {benefit.icon}
                </div>
                <h3>{benefit.title}</h3>
                <p>{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="cta-section">
        <h2>Ready to Protect Your Data?</h2>
        <a href="/contact" className="cta-button">
          Contact Us
        </a>
      </section>
    </div>
  );
};

export default DisasterRecovery;
