import React from 'react';
import { Parallax } from 'react-parallax';
import './Services.css';
import {
  FaHeadset,
  FaUserFriends,
  FaClock,
  FaTools,
  FaPhone,
  FaEnvelope,
  FaLaptop,
  FaThumbsUp,
} from 'react-icons/fa';

const HelpDeskSupport = () => {
  const features = [
    {
      icon: <FaHeadset />,
      title: '24/7 Support',
      description: 'Round-the-clock assistance for all your IT needs.',
      iconClass: 'blue',
    },
    {
      icon: <FaUserFriends />,
      title: 'Skilled Technicians',
      description: 'Experienced professionals ready to help.',
      iconClass: 'blue',
    },
    {
      icon: <FaPhone />,
      title: 'Multi-Channel Access',
      description: 'Get support via phone, email, or chat.',
      iconClass: 'blue',
    },
    {
      icon: <FaTools />,
      title: 'Remote Troubleshooting',
      description: 'Quick resolutions through remote access.',
      iconClass: 'blue',
    },
  ];

  const benefits = [
    {
      icon: <FaClock />,
      title: 'Reduced Downtime',
      description: 'Minimize disruptions to your operations.',
      iconClass: 'teal',
    },
    {
      icon: <FaLaptop />,
      title: 'Increased Productivity',
      description: 'Keep your team focused and productive.',
      iconClass: 'teal',
    },
    {
      icon: <FaThumbsUp />,
      title: 'User Satisfaction',
      description: 'Improve satisfaction with prompt support.',
      iconClass: 'teal',
    },
    {
      icon: <FaEnvelope />,
      title: 'Timely Communication',
      description: 'Stay informed with regular updates.',
      iconClass: 'teal',
    },
  ];

  return (
    <div className="services-page">
      {/* Hero Section */}
      <section
        className="hero-section"
        style={{
          backgroundImage:
            "url('/images/services/help-desk-support/hero-help-desk-support.jpg')",
        }}
      >
        <div className="hero-content">
          <h1>Help Desk Support</h1>
          <p>Our automated help desk offers fast and efficient solutions to your IT issues. Whether you’re dealing with software problems, hardware failures, or system errors, our dedicated team is here to support your employees and ensure minimal disruption to your business activities.
          </p>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/help-desk-support/parallax1.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Always Here to Help</h2>
        </div>
      </Parallax>

      {/* Features Section */}
      <section className="section light-bg">
        <div className="container">
          <h2>Our Support Features</h2>
          <div className="grid">
            {features.map((feature, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${feature.iconClass}`}>
                  {feature.icon}
                </div>
                <h3>{feature.title}</h3>
                <p>{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/help-desk-support/parallax2.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Support You Can Rely On</h2>
        </div>
      </Parallax>

      {/* Benefits Section */}
      <section className="section dark-bg">
        <div className="container">
          <h2>Benefits</h2>
          <div className="grid">
            {benefits.map((benefit, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${benefit.iconClass}`}>
                  {benefit.icon}
                </div>
                <h3>{benefit.title}</h3>
                <p>{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="cta-section">
        <h2>Need Immediate Support?</h2>
        <a href="/contact" className="cta-button">
          Contact Us
        </a>
      </section>
    </div>
  );
};

export default HelpDeskSupport;
