// src/Footer.js

import React from "react";
import {
  FaFacebookF,
  FaInstagram,
  FaPinterest
} from "react-icons/fa";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-top">
        <div className="footer-logo">
          <img src="/images/logo.svg" alt="Rajguru IT Solutions Logo" />
        </div>
        <div className="footer-social">
          <a
            href="https://www.facebook.com"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Facebook"
          >
            <FaFacebookF />
          </a>
          {/* <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer" aria-label="Twitter">
            <FaTwitter />
          </a> */}
          <a
            href="https://www.instagram.com"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Instagram"
          >
            <FaInstagram />
          </a>
          <a
            href="https://www.pinterest.com"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Instagram"
          >
            <FaPinterest />
          </a>
          {/* <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer" aria-label="YouTube">
            <FaYoutube />
          </a> */}
        </div>
      </div>
      <div className="footer-bottom">
        <div className="footer-nav">
          <Link to="/privacy-policy">Privacy & Policy</Link>
          <Link to="/terms-and-conditions">Terms & Conditions</Link>
        </div>
        <span>Copyright © 2024 Rajguru IT Solutions. All rights reserved.</span>
      </div>
    </footer>
  );
};

export default Footer;
