// src/pages/TermsAndConditions.jsx

import React from 'react';
import './TermsAndConditions.css';

const TermsAndConditions = () => {
  return (
    <main className="terms-section" role="main">
      <div className="container">
        <h1>Terms and Conditions</h1>
        <div className="content">
          <div className="main-content">

            <div className="section">
              <h2>Introduction</h2>
              <p>
                These Terms and Conditions govern the relationship between Rajguru IT Solutions and its clients regarding the provision of IT solutions and services, including but not limited to network infrastructure, data center infrastructure, cloud computing, virtualization, cyber-security, collaboration tools, IT support and management, IoT infrastructure solutions, and Infrastructure as a Service.
              </p>
            </div>

            <div className="section">
              <h2>Definitions</h2>
              <ul className="sub-list">
                <li><strong>Client:</strong> The entity or individual receiving services from the Company.</li>
                <li><strong>Services:</strong> The IT solutions and support provided by the Company as outlined in the service agreement.</li>
                <li><strong>IaaS:</strong> Infrastructure as a Service, which includes rental of laptops, computers, Wi-Fi as a service, and routing as a service.</li>
              </ul>
            </div>

            <div className="section">
              <h2>Scope of Services</h2>
              <p>
                The Company will provide services as specified in the mutually agreed-upon proposal or service agreement. This includes:
              </p>
              <ul className="sub-list">
                <li>Design and implementation of network infrastructure.</li>
                <li>Provisioning of data center resources.</li>
                <li>Cloud computing solutions tailored to Client needs.</li>
                <li>Cyber-security measures to protect Client data.</li>
                <li>Collaboration tools to enhance productivity.</li>
                <li>Ongoing IT support and management services.</li>
                <li>IoT infrastructure solutions.</li>
              </ul>
            </div>

            <div className="section">
              <h2>Infrastructure as a Service</h2>
              <p>
                The Company offers IaaS with a minimum Annual Maintenance Contract (AMC) of one year. This includes:
              </p>
              <ul className="sub-list">
                <li>Rental of laptops and computers.</li>
                <li>Infrastructure as a service (routing, switching, wifi, colloboration tools)</li>
              </ul>
            </div>

            <div className="section">
              <h2>Client Responsibilities</h2>
              <p>The Client agrees to:</p>
              <ul className="sub-list">
                <li>Provide accurate and complete information necessary for the provision of services.</li>
                <li>Ensure that all hardware and software meet the Company's specifications.</li>
                <li>Comply with all applicable laws in connection with the use of services.</li>
              </ul>
            </div>

            <div className="section">
              <h2>Payment Terms</h2>
              <p>
                The Client shall pay all fees for services as outlined in the service agreement. Payments are due upon receipt of the invoice unless otherwise agreed upon in writing. Late payments may incur penalties or interest charges as specified in the agreement.
              </p>
            </div>

            <div className="section">
              <h2>Confidentiality</h2>
              <p>
                Both parties agree to maintain confidentiality regarding any proprietary information exchanged during their relationship. This obligation will survive termination of these Terms.
              </p>
            </div>

            <div className="section">
              <h2>Intellectual Property Rights</h2>
              <p>
                All intellectual property rights related to any software, documentation, or other materials created by the Company during the provision of services shall remain with the Company. The Client is granted a non-exclusive, non-transferable license to use such materials solely for their intended purpose.
              </p>
            </div>

            <div className="section">
              <h2>Service Level Agreements (SLAs)</h2>
              <p>
                The Company may provide SLAs that define expected performance metrics for specific services. These SLAs will be detailed in separate documents or agreements and are subject to change based on mutual agreement.
              </p>
            </div>

            <div className="section">
              <h2>Data Protection and Privacy</h2>
              <p>
                The Company will handle Client data in accordance with applicable data protection laws. The Company commits to implementing appropriate technical and organizational measures to protect personal data against unauthorized access or processing.
              </p>
            </div>

            <div className="section">
              <h2>Limitation of Liability</h2>
              <p>
                The Company will not be liable for any indirect or consequential damages arising from the use of its services. The total liability shall not exceed the fees paid by the Client for services during the preceding twelve months.
              </p>
            </div>

            <div className="section">
              <h2>Termination</h2>
              <p>
                Either party may terminate this agreement with written notice if:
              </p>
              <ul className="sub-list">
                <li>The other party breaches any material term or condition and does not rectify the breach within a reasonable timeframe after receiving written notice of such breach.</li>
                <li>The other party becomes insolvent or bankrupt.</li>
              </ul>
              <p>
                Upon termination, both parties shall fulfill their obligations up to the termination date, including payment for services rendered.
              </p>
            </div>

            <div className="section">
              <h2>Governing Law</h2>
              <p>
                These Terms shall be governed by and construed in accordance with the laws of India. Any legal disputes arising under these Terms shall be resolved in the courts located in Hyderabad, Telangana, India.
              </p>
            </div>

            <div className="section">
              <h2>Force Majeure</h2>
              <p>
                Neither party shall be liable for failure to perform its obligations under these Terms if such failure is due to events beyond its reasonable control (e.g., natural disasters, pandemics).
              </p>
            </div>

            <div className="section">
              <h2>Amendments</h2>
              <p>
                The Company reserves the right to amend these Terms at any time. Changes will be communicated to the Client in writing, and continued use of services after such changes constitutes acceptance of the new Terms.
              </p>
            </div>

            <div className="section">
              <h2>Acceptance</h2>
              <p>
                By engaging with our services, the Client acknowledges acceptance of these Terms.
              </p>
            </div>

          </div>
        </div>
      </div>
    </main>
  );
};

export default TermsAndConditions;
