import React from "react";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./DasanNetworksPage.css";

// Carousel settings
const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3, // Number of slides to show on desktop
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000, // 3 seconds
  responsive: [
    {
      breakpoint: 1200, // Tablet breakpoint
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 768, // Mobile breakpoint
      settings: {
        slidesToShow: 1,
      },
    },
  ],
  arrows: false, // Hide arrows for a cleaner look
};
// Product Data
const products = [
  {
    name: "Fiber Access Solutions",
    // description:
    //   "A 2U rack-mounted NAS designed for enterprise-level storage with redundancy and high performance.",
    image: "/images/products/dasan/dasan-fiber-access-solutions.jpg",
  },
  {
    name: "Ethernet switching solutions",
    // description:
    //   "A powerful rack-mounted NAS with advanced data management, suitable for SMBs requiring virtualization support.",
    image: "/images/products/dasan/dasan-ethernet-switching-solutions.jpg",
  },
  {
    name: "Optical Line Terminals",
    // description:
    //   "A desktop NAS offering high scalability and performance, ideal for small to medium-sized businesses.",
    image: "/images/products/dasan/dasan-optical-line-terminals.png",
  },
];
const DasanNetworksPage = () => {
  const navigate = useNavigate(); // Initialize navigate

  return (
    <div className="dasan-page-container">
      <div className="dasan-hero-container">
        <div className="hero-text">
          <h1>Dasan Networks</h1>
          <h2>Leading Network Solutions Provider</h2>
          <p>
            Dasan Networks is a global leader in providing end-to-end networking
            solutions, offering scalable and reliable solutions for telecom
            operators and enterprises. With cutting-edge technology in fiber
            optics, Ethernet switches, and wireless access, Dasan Networks
            ensures seamless connectivity across diverse industries.
          </p>
          <button
            className="learn-more-button"
            onClick={() => navigate("/contact")}
          >
            Learn More
          </button>
        </div>
        <div className="hero-image" >
          <img
            src="/images/logos/Dasan networks-logo.svg"
            alt="Dasan Networks Logo"
          />
        </div>
      </div>

      {/* Product Carousel Section */}
      <div className="product-carousel-section">
        <h2 className="carousel-title">Our Dasan Networks Solutions</h2>
        <Slider {...settings} aria-label="Product Carousel">
          {products.map((product, index) => (
            <div key={index} className="product-card">
              <a
                href={`/products/${product.name}`}
                className="product-link"
                aria-label={`Learn more about ${product.name}`}
              >
                <div className="product-image-container">
                  <img
                    src={product.image}
                    alt={`${product.name} Logo`}
                    className="product-image"
                    loading="lazy"
                  />
                </div>
                <h3 className="product-name">{product.name}</h3>
                <p className="product-description">{product.description}</p>
              </a>
            </div>
          ))}
        </Slider>
      </div>

      <div className="video-container">
        <div className="video-responsive">
          <ReactPlayer
            url="https://www.youtube.com/watch?v=vCNJAsuZdWo"
            controls
            width="100%"
            height="100%"
            className="react-player"
          />
        </div>
      </div>
    </div>
  );
};

export default DasanNetworksPage;
