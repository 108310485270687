import React from 'react';
import { Parallax } from 'react-parallax';
import './Services.css';
import {
  FaShieldAlt,
  FaLock,
  FaServer,
  FaCogs,
  FaCheckCircle,
  FaHeadset,
} from 'react-icons/fa';

const FirewallProtection = () => {
  const features = [
    {
      icon: <FaShieldAlt />,
      title: 'Next-Gen Firewalls',
      description: 'Advanced security features for modern threats.',
      iconClass: 'blue',
    },
    {
      icon: <FaLock />,
      title: 'Unified Threat Management',
      description: 'Comprehensive protection in a single solution.',
      iconClass: 'blue',
    },
    {
      icon: <FaServer />,
      title: 'Firewall Deployment',
      description: 'Professional setup and configuration services.',
      iconClass: 'blue',
    },
    {
      icon: <FaCogs />,
      title: 'Policy Management',
      description: 'Custom firewall rules tailored to your needs.',
      iconClass: 'blue',
    },
  ];

  const benefits = [
    {
      icon: <FaCheckCircle />,
      title: 'Enhanced Security',
      description: 'Protect your network from unauthorized access.',
      iconClass: 'teal',
    },
    {
      icon: <FaHeadset />,
      title: 'Expert Support',
      description: 'Assistance from our security specialists.',
      iconClass: 'teal',
    },
    {
      icon: <FaShieldAlt />,
      title: 'Compliance',
      description: 'Meet industry regulations and standards.',
      iconClass: 'teal',
    },
    {
      icon: <FaLock />,
      title: 'Peace of Mind',
      description: 'Focus on your business with confidence.',
      iconClass: 'teal',
    },
  ];

  return (
    <div className="services-page">
      {/* Hero Section */}
      <section
        className="hero-section"
        style={{
          backgroundImage:
            "url('/images/services/firewall-protection/hero-firewall-protection.jpg')",
        }}
      >
        <div className="hero-content">
          <h1>Firewall Protection</h1>
          <p>Our advanced firewall solutions act as the first line of defense on your network, monitoring and
controlling incoming and outgoing visitors based on predetermined protection rules. We enforce
and manipulate sturdy firewalls that block unauthorized get admission to and ensure the protection of your
sensitive information.
</p>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/firewall-protection/parallax1.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Fortify Your Network</h2>
        </div>
      </Parallax>

      {/* Features Section */}
      <section className="section light-bg">
        <div className="container">
          <h2>Our Firewall Services</h2>
          <div className="grid">
            {features.map((feature, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${feature.iconClass}`}>
                  {feature.icon}
                </div>
                <h3>{feature.title}</h3>
                <p>{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/firewall-protection/parallax2.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Stay Protected</h2>
        </div>
      </Parallax>

      {/* Benefits Section */}
      <section className="section dark-bg">
        <div className="container">
          <h2>Benefits</h2>
          <div className="grid">
            {benefits.map((benefit, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${benefit.iconClass}`}>
                  {benefit.icon}
                </div>
                <h3>{benefit.title}</h3>
                <p>{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="cta-section">
        <h2>Ready to Secure Your Network?</h2>
        <a href="/contact" className="cta-button">
          Contact Us
        </a>
      </section>
    </div>
  );
};

export default FirewallProtection;
