// File: src/solutions-pages/IoTInfrastructureSolutions.jsx

import React from "react";
import {
  FaCloud,
  FaExchangeAlt,
  FaLock,
  FaMicrochip,
  FaMobileAlt,
  FaNetworkWired,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import "./IoTInfrastructureSolutions.css";

// Import images from the 'public' directory using 'process.env.PUBLIC_URL'
const heroImage = `${process.env.PUBLIC_URL}/images/iot-infrastructure/hero-iot-infrastructure.jpg`;
const parallaxImage = `${process.env.PUBLIC_URL}/images/iot-infrastructure/parallax-iot-infrastructure.jpg`; // Ensure you have this image
const overviewImage = `${process.env.PUBLIC_URL}/images/iot-infrastructure/overview-iot-infrastructure.jpg`;

// Service images (optional: if you have specific images for services)
const serviceImages = {
  iotImplementation: `${process.env.PUBLIC_URL}/images/services/iot-implementation.jpg`,
  iotDeviceManagement: `${process.env.PUBLIC_URL}/images/services/iot-device-management.jpg`,
  sensorsEdgeDevices: `${process.env.PUBLIC_URL}/images/services/sensors-edge-devices.jpg`,
  iotGateways: `${process.env.PUBLIC_URL}/images/services/iot-gateways.jpg`,
  cloudBasedIoTPlatforms: `${process.env.PUBLIC_URL}/images/services/cloud-based-iot-platforms.jpg`,
  iotSecurity: `${process.env.PUBLIC_URL}/images/services/iot-security.jpg`,
};

// Brand logos (optional: similar to other pages)
const brands = [
  {
    name: "Cisco",
    logo: `${process.env.PUBLIC_URL}/images/brands/cisco-logo.png`,
    link: "/brands/cisco",
    alt: "Cisco",
  },
  {
    name: "IBM",
    logo: `${process.env.PUBLIC_URL}/images/brands/ibm-logo.png`,
    link: "/brands/ibm",
    alt: "IBM",
  },
  {
    name: "Siemens",
    logo: `${process.env.PUBLIC_URL}/images/brands/siemens-logo.png`,
    link: "/brands/siemens",
    alt: "Siemens",
  },
  {
    name: "GE Digital",
    logo: `${process.env.PUBLIC_URL}/images/brands/ge-digital-logo.png`,
    link: "/brands/ge-digital",
    alt: "GE Digital",
  },
];

const services = [
  {
    title: "IoT Implementation",
    description:
      "Deploy Internet of Things solutions tailored to your business needs for enhanced efficiency.",
    icon: <FaNetworkWired />,
    image: serviceImages.iotImplementation, // Optional: if you have service images
    link: "/services/iot-implementation",
  },
  {
    title: "IoT Device Management",
    description:
      "Manage and monitor your IoT devices seamlessly with our comprehensive management solutions.",
    icon: <FaMobileAlt />,
    image: serviceImages.iotDeviceManagement,
    link: "/services/iot-device-management",
  },
  {
    title: "Sensors & Edge Devices",
    description:
      "Integrate advanced sensors and edge devices for real-time data collection and processing.",
    icon: <FaMicrochip />,
    image: serviceImages.sensorsEdgeDevices,
    link: "/services/sensors-edge-devices",
  },
  {
    title: "IoT Gateways",
    description:
      "Connect your devices and networks securely with our robust IoT gateway solutions.",
    icon: <FaExchangeAlt />,
    image: serviceImages.iotGateways,
    link: "/services/iot-gateways",
  },
  {
    title: "Cloud-Based IoT Platforms",
    description:
      "Leverage cloud platforms for scalable and flexible IoT data management and analytics.",
    icon: <FaCloud />,
    image: serviceImages.cloudBasedIoTPlatforms,
    link: "/services/cloud-based-iot-platforms",
  },
  {
    title: "IoT Security",
    description:
      "Protect your IoT ecosystem with advanced security measures and protocols.",
    icon: <FaLock />,
    image: serviceImages.iotSecurity,
    link: "/services/iot-security",
  },
];

const IoTInfrastructureSolutions = () => {
  return (
    <div className="iot-infrastructure-solutions">
      {/* Hero Section */}
      <section
        className="hero-section"
        style={{
          backgroundImage: `url(${heroImage})`,
        }}
      >
        <div className="hero-overlay">
          <h1>IoT Infrastructure Solutions</h1>
          <p>
            Transform your business operations with our comprehensive IoT
            solutions. Connect devices, gather insights, and drive innovation.
          </p>
          <Link to="/contact" className="hero-button">
            Get Started
          </Link>
        </div>
        <div className="scroll-down-indicator"></div>
      </section>

      {/* Overview Section */}
      <section className="overview-section">
        <div className="container">
          <h2>Embrace the Future with IoT</h2>
          <div className="overview-content">
            <div className="overview-text">
              <p>
                The Internet of Things (IoT) is revolutionizing industries by
                enabling smarter operations and data-driven decisions. Our
                expert team helps you harness the power of IoT to enhance
                efficiency and competitiveness.
              </p>
              <p>
                From implementation to security, we offer end-to-end IoT
                solutions tailored to your business needs.
              </p>
            </div>
            <div className="overview-image">
              <img src={overviewImage} alt="IoT Infrastructure Overview" />
            </div>
          </div>
        </div>
      </section>

      {/* Services Offered Section */}
      <section className="services-section">
        <div className="container">
          <h2>Our IoT Infrastructure Services</h2>
          <div className="services-grid">
            {services.map((service, index) => (
              <Link to={service.link} className="service-card" key={index}>
                <div
                  className="service-image"
                  style={{ backgroundImage: `url(${service.image})` }}
                >
                  <div className="service-icon">{service.icon}</div>
                </div>
                <div className="service-content">
                  <h3>{service.title}</h3>
                  <p>{service.description}</p>
                  <span className="read-more">Read More</span>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </section>

      {/* Parallax Section */}
      <section
        className="parallax-section"
        style={{
          backgroundImage: `url(${parallaxImage})`,
        }}
      >
        <div className="parallax-overlay">
          <h2>Comprehensive IoT Solutions for Modern Businesses</h2>
          <p>
            Enhance your operations with our state-of-the-art IoT infrastructure
            solutions designed to meet your unique business needs.
          </p>
          <Link to="/solutions" className="parallax-button">
            Learn More
          </Link>
        </div>
      </section>

      {/* Call to Action */}
      <section className="cta-section">
        <div className="container">
          <h2>Ready to Innovate with IoT?</h2>
          <p>
            Contact us today for a consultation and discover how our IoT
            solutions can transform your business.
          </p>
          <Link to="/contact" className="cta-button">
            Contact Us
          </Link>
        </div>
      </section>
    </div>
  );
};

export default IoTInfrastructureSolutions;
