import React from 'react';
import { Parallax } from 'react-parallax';
import './Services.css';
import {
  FaClipboardCheck,
  FaFileSignature,
  FaGavel,
  FaTasks,
  FaExclamationTriangle,
  FaCheckCircle,
  FaStar,
  FaHeadset,
} from 'react-icons/fa';

const SecurityAudits = () => {
  const services = [
    {
      icon: <FaClipboardCheck />,
      title: 'Comprehensive Audits',
      description: 'Thorough evaluations of your security posture.',
      iconClass: 'blue',
    },
    {
      icon: <FaFileSignature />,
      title: 'Policy Review',
      description: 'Analyze and improve your security policies.',
      iconClass: 'blue',
    },
    {
      icon: <FaGavel />,
      title: 'Compliance Checks',
      description: 'Ensure adherence to regulatory requirements.',
      iconClass: 'blue',
    },
    {
      icon: <FaTasks />,
      title: 'Action Plans',
      description: 'Develop strategies to enhance security.',
      iconClass: 'blue',
    },
  ];

  const benefits = [
    {
      icon: <FaExclamationTriangle />,
      title: 'Risk Reduction',
      description: 'Identify and mitigate security risks.',
      iconClass: 'teal',
    },
    {
      icon: <FaCheckCircle />,
      title: 'Regulatory Compliance',
      description: 'Avoid penalties and legal issues.',
      iconClass: 'teal',
    },
    {
      icon: <FaStar />,
      title: 'Enhanced Reputation',
      description: 'Build trust with clients and stakeholders.',
      iconClass: 'teal',
    },
    {
      icon: <FaHeadset />,
      title: 'Expert Support',
      description: 'Guidance from our experienced auditors.',
      iconClass: 'teal',
    },
  ];

  return (
    <div className="services-page">
      {/* Hero Section */}
      <section
        className="hero-section"
        style={{
          backgroundImage:
            "url('/images/services/security-audits/hero-security-audits.jpg')",
        }}
      >
        <div className="hero-content">
          <h1>Security Audits & Compliance</h1>
          <p>Our cybersecurity professionals carry out in-depth security audits to make certain your organization complies with enterprise regulations and requirements. We examine your existing security features and help you enforce the important upgrades to keep compliance and keep away from consequences.
          </p>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/security-audits/parallax1.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Strengthen Your Security</h2>
        </div>
      </Parallax>

      {/* Services Section */}
      <section className="section light-bg">
        <div className="container">
          <h2>Our Audit Services</h2>
          <div className="grid">
            {services.map((service, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${service.iconClass}`}>{service.icon}</div>
                <h3>{service.title}</h3>
                <p>{service.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/security-audits/parallax2.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Achieve Compliance</h2>
        </div>
      </Parallax>

      {/* Benefits Section */}
      <section className="section dark-bg">
        <div className="container">
          <h2>Benefits</h2>
          <div className="grid">
            {benefits.map((benefit, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${benefit.iconClass}`}>{benefit.icon}</div>
                <h3>{benefit.title}</h3>
                <p>{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="cta-section">
        <h2>Ready for a Security Audit?</h2>
        <a href="/contact" className="cta-button">
          Contact Us
        </a>
      </section>
    </div>
  );
};

export default SecurityAudits;
