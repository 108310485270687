// File: src/solutions-pages/NetworkInfrastructure.jsx

import React from "react";
import {
  FaCloud,
  FaCogs,
  FaMobileAlt,
  FaNetworkWired,
  FaShieldAlt,
  FaWifi,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import "./NetworkInfrastructure.css";

// Import images from the 'public' directory using 'process.env.PUBLIC_URL'
const heroImage = `${process.env.PUBLIC_URL}/images/NetworkInfrastructure/hero-network.jpg`;
const parallaxImage = `${process.env.PUBLIC_URL}/images/NetworkInfrastructure/parallax.jpeg`;
const overviewImage = `${process.env.PUBLIC_URL}/images/NetworkInfrastructure/overview.jpg`;

// Service images
const serviceImages = {
  networkDesign: `${process.env.PUBLIC_URL}/images/services/network-design.jpg`,
  networkImplementation: `${process.env.PUBLIC_URL}/images/services/network-implementation.jpg`,
  lanSolutions: `${process.env.PUBLIC_URL}/images/services/lan-solutions.jpg`,
  wanSolutions: `${process.env.PUBLIC_URL}/images/services/wan-solutions.jpg`,
  wirelessSolutions: `${process.env.PUBLIC_URL}/images/services/wireless-solutions.jpg`,
  networkManagement: `${process.env.PUBLIC_URL}/images/services/network-management.jpg`,
  networkSecurity: `${process.env.PUBLIC_URL}/images/services/network-security.jpg`,
};

// Brand logos (updated to include only specified brands)
const brands = [
  {
    name: "Cisco",
    logo: `${process.env.PUBLIC_URL}/images/brands/cisco-logo.png`,
    link: "/brands/cisco",
    alt: "Cisco",
  },
  {
    name: "Ubiquiti",
    logo: `${process.env.PUBLIC_URL}/images/brands/ubiquiti-logo.png`,
    link: "/brands/ubiquiti",
    alt: "Ubiquiti",
  },
  {
    name: "Panduit",
    logo: `${process.env.PUBLIC_URL}/images/brands/panduit-logo.png`,
    link: "/brands/panduit",
    alt: "Panduit",
  },
  {
    name: "TP-Link",
    logo: `${process.env.PUBLIC_URL}/images/brands/tplink-logo.png`,
    link: "/brands/tplink",
    alt: "TP-Link",
  },
];

const services = [
  {
    title: "Network Design",
    description:
      "Customized, scalable, and secure network infrastructures for LAN, WAN, and wireless networks. Our designs are built to handle future growth and technological advancements.",
    icon: <FaNetworkWired />,
    image: serviceImages.networkDesign,
    link: "/services/network-design",
  },
  {
    title: "Network Implementation",
    description:
      "Full installation and configuration services, ensuring seamless integration of hardware and software, from switches and routers to wireless access points and firewalls.",
    icon: <FaCogs />,
    image: serviceImages.networkImplementation,
    link: "/services/network-implementation",
  },
  {
    title: "LAN Solutions",
    description:
      "Design and implementation of secure, fast, and reliable internal networks that keep your business running efficiently, with minimal downtime.",
    icon: <FaNetworkWired />,
    image: serviceImages.lanSolutions,
    link: "/services/lan-solutions",
  },
  {
    title: "WAN Solutions",
    description:
      "Seamless connectivity across multiple locations, providing secure and reliable access to data and services across wide-area networks.",
    icon: <FaCloud />,
    image: serviceImages.wanSolutions,
    link: "/services/wan-solutions",
  },
  {
    title: "Wireless Network Solutions",
    description:
      "Enterprise-grade Wi-Fi solutions that provide fast, secure, and scalable wireless connectivity for offices, campuses, and industrial environments.",
    icon: <FaWifi />,
    image: serviceImages.wirelessSolutions,
    link: "/services/wireless-network-solutions",
  },
  {
    title: "Network Management",
    description:
      "Continuous monitoring, optimization, and management of your network infrastructure to ensure maximum performance and security at all times.",
    icon: <FaMobileAlt />,
    image: serviceImages.networkManagement,
    link: "/services/network-management",
  },
  {
    title: "Network Security",
    description:
      "Implementing advanced security measures such as firewalls, encryption, and security protocols to protect your network from internal and external threats.",
    icon: <FaShieldAlt />,
    image: serviceImages.networkSecurity,
    link: "/services/network-security",
  },
];

const NetworkInfrastructure = () => {
  return (
    <div className="network-infrastructure">
      {/* Hero Section */}
      <section
        className="hero-section"
        style={{
          backgroundImage: `url(${heroImage})`,
        }}
      >
        <div className="hero-overlay">
          <h1>Network Infrastructure Solutions</h1>
          <p>
            Build a reliable, scalable, and secure network infrastructure with
            our expert design and implementation services. From fiber optic
            cabling to wireless network solutions, we ensure that your network
            is built to handle your current and future needs.
          </p>
          <Link to="/contact" className="hero-button">
            Get Started
          </Link>
        </div>
        <div className="scroll-down-indicator"></div>
      </section>

      {/* Overview Section */}
      <section className="overview-section">
        <div className="container">
          <h2>Empower Your Business with Advanced Networking</h2>
          <div className="overview-content">
            <div className="overview-text">
              <p>
                In today's connected world, your network infrastructure is the
                backbone of your business operations. We provide customized
                solutions to ensure seamless communication, data transfer, and
                security, no matter the size or complexity of your network.
              </p>
              <p>
                Our partnerships with leading brands such as{" "}
                <strong>Cisco</strong>, <strong>Ubiquiti</strong>,{" "}
                <strong>Panduit</strong>, <strong>TP-Link</strong>, and more
                allow us to offer high-quality, cutting-edge technology that
                ensures top performance and reliability.
              </p>
            </div>
            <div className="overview-image">
              <img src={overviewImage} alt="Network Overview" />
            </div>
          </div>
        </div>
      </section>

      {/* Services Offered Section */}
      <section className="network-services-section">
        <div className="container">
          <h2>Our Services</h2>
          <div className="network-services-grid">
            {services.map((service, index) => (
              <Link
                to={service.link}
                className="network-service-card"
                key={index}
              >
                <div
                  className="network-service-image"
                  style={{ backgroundImage: `url(${service.image})` }}
                >
                  <div className="network-service-icon">{service.icon}</div>
                </div>
                <div className="network-service-content">
                  <h3>{service.title}</h3>
                  <p>{service.description}</p>
                  <span className="network-read-more">Read More</span>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </section>

      {/* Parallax Section */}
      <section
        className="parallax-section"
        style={{
          backgroundImage: `url(${parallaxImage})`,
        }}
      >
        <div className="parallax-overlay">
          <h2>Innovative Solutions for Modern Businesses</h2>
          <p>
            Stay ahead of the competition with our state-of-the-art networking
            solutions tailored to your business needs.
          </p>
          <Link to="/solutions" className="parallax-button">
            Learn More
          </Link>
        </div>
      </section>

      {/* Call to Action */}
      <section className="cta-section">
        <div className="container">
          <h2>Ready to Transform Your Network?</h2>
          <p>
            Contact us today for a consultation and let’s build a robust,
            secure, and scalable network infrastructure tailored to your
            business needs.
          </p>
          <Link to="/contact" className="cta-button">
            Get Started
          </Link>
        </div>
      </section>
    </div>
  );
};

export default NetworkInfrastructure;
