// SynologyPage.jsx
import React from "react";
import { FaArrowRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom"; // Import useNavigate hook
import Slider from "react-slick"; // Import Slider from react-slick
import "./SynologyPage.css"; // Custom styling for Synology page

// Import slick-carousel CSS
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

// Product Data
const products = [
  {
    name: "RS2423+RP",
    description:
      "A 2U rack-mounted NAS designed for enterprise-level storage with redundancy and high performance.",
    image: "/images/products/RS2423rp.svg",
  },
  {
    name: "RS1619xs+",
    description:
      "A powerful rack-mounted NAS with advanced data management, suitable for SMBs requiring virtualization support.",
    image: "/images/products/RS1619xs.svg",
  },
  {
    name: "DS1821+",
    description:
      "A desktop NAS offering high scalability and performance, ideal for small to medium-sized businesses.",
    image: "/images/products/DS1821.svg",
  },
  {
    name: "DS1621+",
    description:
      "A 6-bay NAS for businesses demanding fast data processing and seamless virtualization support.",
    image: "/images/products/DS1621.svg",
  },
  {
    name: "DS1522+",
    description:
      "A 5-bay NAS designed to deliver superior performance with multiple expansion options for growing businesses.",
    image: "/images/products/DS1522.svg",
  },
  {
    name: "DS923+",
    description:
      "A 4-bay desktop NAS with robust storage management and multimedia capabilities.",
    image: "/images/products/DS923.svg",
  },
  {
    name: "DS423+",
    description:
      "A versatile 4-bay NAS aimed at home and small business users for data backup and media streaming.",
    image: "/images/products/DS423.svg",
  },
  {
    name: "DS224+",
    description:
      "A compact 2-bay NAS for users needing efficient data sharing and backup solutions.",
    image: "/images/products/DS224.svg",
  },
  {
    name: "DS223j",
    description:
      "An entry-level 2-bay NAS optimized for home users to back up and stream personal content.",
    image: "/images/products/DS223j.svg",
  },
];

const SynologyPage = () => {
  const navigate = useNavigate(); // Initialize navigate

  // Carousel settings
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Number of slides to show on desktop
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000, // 3 seconds
    responsive: [
      {
        breakpoint: 1200, // Tablet breakpoint
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768, // Mobile breakpoint
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    arrows: false, // Hide arrows for a cleaner look
  };

  return (
    <div className="synology-page-container">
      <div className="synology-hero-container">
        <div className="hero-text">
          <h1>Synology</h1>
          <h2>Data Storage and Management Solutions</h2>
          <p>
            Synology is a global leader in data storage solutions, offering
            cutting-edge NAS devices, cloud services, and network equipment.
            With a focus on scalability, security, and performance, Synology
            empowers businesses to manage their data with ease and efficiency.
          </p>
          <button
            className="learn-more-button"
            onClick={() => navigate("/contact")}
          >
            Learn More <FaArrowRight className="button-icon" />
          </button>
        </div>
        <div className="hero-image">
          <img src="/images/logos/Synology-logo.svg" alt="Synology Logo" />
        </div>
      </div>

      {/* Product Carousel Section */}
      <div className="product-carousel-section">
        <h2 className="carousel-title">Our NAS Products</h2>
        <Slider {...settings} aria-label="Product Carousel">
          {products.map((product, index) => (
            <div key={index} className="product-card">
              <a
                href={`/products/${product.name}`}
                className="product-link"
                aria-label={`Learn more about ${product.name}`}
              >
                <div className="product-image-container">
                  <img
                    src={product.image}
                    alt={`${product.name} Logo`}
                    className="product-image"
                    loading="lazy"
                  />
                </div>
                <h3 className="product-name">{product.name}</h3>
                <p className="product-description">{product.description}</p>
              </a>
            </div>
          ))}
        </Slider>
      </div>
      {/* End of Product Carousel Section */}

      <div className="video-container">
        <iframe
          width="560"
          height="400" /* Height set to 400px as requested */
          src="https://www.youtube.com/embed/your_video_id" // Replace with the actual video link
          title="Synology Overview"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default SynologyPage;
