import React from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate hook
import "./PolyPage.css"; // Custom styling for TP-Link page
// SynologyPage.jsx
import ReactPlayer from "react-player";
import Slider from "react-slick"; // Import Slider from react-slick
import "./SynologyPage.css"; // Custom styling for Synology page

// Import slick-carousel CSS
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

// Product Data
const products = [
  {
    name: "Poly Video conferencing Solutions",
    // description:
    //   "A 2U rack-mounted NAS designed for enterprise-level storage with redundancy and high performance.",
    image: "/images/products/poly/poly_video_conf.jpg",
  },
  {
    name: "Poly solutions for microsoft teams and zoom",
    // description:
    //   "A powerful rack-mounted NAS with advanced data management, suitable for SMBs requiring virtualization support.",
    image: "/images/products/poly/poly_microsoft_teams_zoom.jpg",
  },
];

// Carousel settings
const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3, // Number of slides to show on desktop
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000, // 3 seconds
  responsive: [
    {
      breakpoint: 1200, // Tablet breakpoint
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 768, // Mobile breakpoint
      settings: {
        slidesToShow: 1,
      },
    },
  ],
  arrows: false, // Hide arrows for a cleaner look
};
export default function PolyPage() {
  const navigate = useNavigate(); // Initialize navigate

  return (
    <div className="poly-page-container">
      <div className="poly-hero-container">
        <div className="hero-text">
          <h1>Poly</h1>
          <p>
            Poly, formerly known as Plantronics and Polycom, is a leading
            technology brand specializing in integrated communications and
            collaboration solutions. The company designs and manufactures a wide
            range of products, including high-quality headsets, video
            conferencing systems, and voice communication devices that enhance
            productivity in various work environments. With a strong focus on
            delivering exceptional audio and video experiences, Poly's solutions
            are tailored for both remote and in-office settings, enabling
            seamless collaboration across teams. Their innovative technology
            integrates easily with existing platforms, ensuring that users can
            communicate effectively without disruption. Committed to enhancing
            workplace interactions, Poly continues to set industry standards for
            quality and performance in communication technology.
          </p>
          <button
            className="learn-more-button"
            onClick={() => navigate("/contact")}
          >
            Learn More
          </button>
        </div>
        <div
          className="hero-image"
          style={{
            backgroundColor: "#fff",
            padding: "50px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "200px",
          }}
        >
          <img src="/images/logos/new/all_logos__9.svg" alt="Poly Logo" />
        </div>
      </div>

      {/* Product Carousel Section */}
      <div className="product-carousel-section">
        <h2 className="carousel-title">Our Poly Solutions</h2>
        <Slider {...settings} aria-label="Product Carousel">
          {products.map((product, index) => (
            <div key={index} className="product-card">
              <a
                href={`/products/${product.name}`}
                className="product-link"
                aria-label={`Learn more about ${product.name}`}
              >
                <div className="product-image-container">
                  <img
                    src={product.image}
                    alt={`${product.name} Logo`}
                    className="product-image"
                    loading="lazy"
                  />
                </div>
                <h3 className="product-name">{product.name}</h3>
                <p className="product-description">{product.description}</p>
              </a>
            </div>
          ))}
        </Slider>
      </div>

      <div className="video-container">
        <div className="video-responsive">
          <ReactPlayer
            url="https://www.youtube.com/watch?v=3hmP24NckdA"
            controls
            width="100%"
            height="100%"
            className="react-player"
          />
        </div>
      </div>
    </div>
  );
}
