import React from 'react';
import { Parallax } from 'react-parallax';
import './Services.css';
import {
  FaNetworkWired,
  FaEye,
  FaChartLine,
  FaBug,
  FaShieldAlt,
  FaClock,
  FaHeadset,
  FaSyncAlt,
} from 'react-icons/fa';

const NetworkMonitoring = () => {
  const features = [
    {
      icon: <FaEye />,
      title: '24/7 Monitoring',
      description: 'Continuous oversight of your network infrastructure.',
      iconClass: 'blue',
    },
    {
      icon: <FaChartLine />,
      title: 'Performance Analysis',
      description: 'Track network performance metrics.',
      iconClass: 'blue',
    },
    {
      icon: <FaBug />,
      title: 'Threat Detection',
      description: 'Identify and mitigate security threats.',
      iconClass: 'blue',
    },
    {
      icon: <FaSyncAlt />,
      title: 'Real-Time Alerts',
      description: 'Immediate notifications of network issues.',
      iconClass: 'blue',
    },
  ];

  const benefits = [
    {
      icon: <FaClock />,
      title: 'Minimized Downtime',
      description: 'Ensure network availability and reliability.',
      iconClass: 'teal',
    },
    {
      icon: <FaShieldAlt />,
      title: 'Enhanced Security',
      description: 'Protect your network from cyber threats.',
      iconClass: 'teal',
    },
    {
      icon: <FaHeadset />,
      title: 'Expert Support',
      description: 'Assistance from our network specialists.',
      iconClass: 'teal',
    },
    {
      icon: <FaNetworkWired />,
      title: 'Optimized Performance',
      description: 'Keep your network running smoothly.',
      iconClass: 'teal',
    },
  ];

  return (
    <div className="services-page">
      {/* Hero Section */}
      <section
        className="hero-section"
        style={{
          backgroundImage:
            "url('/images/services/network-monitoring/hero-network-monitoring.jpg')",
        }}
      >
        <div className="hero-content">
          <h1>Network Monitoring</h1>
          <p>We offer 24/7 network monitoring to identify and fix potential issues before they affect your business. Our proactive approach allows us to identify and address problems quickly, and ensure that your site remains secure, reliable, and optimized for functionality.
          </p>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/network-monitoring/parallax1.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Stay Connected</h2>
        </div>
      </Parallax>

      {/* Features Section */}
      <section className="section light-bg">
        <div className="container">
          <h2>Our Monitoring Features</h2>
          <div className="grid">
            {features.map((feature, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${feature.iconClass}`}>
                  {feature.icon}
                </div>
                <h3>{feature.title}</h3>
                <p>{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/network-monitoring/parallax2.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Peace of Mind</h2>
        </div>
      </Parallax>

      {/* Benefits Section */}
      <section className="section dark-bg">
        <div className="container">
          <h2>Benefits</h2>
          <div className="grid">
            {benefits.map((benefit, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${benefit.iconClass}`}>
                  {benefit.icon}
                </div>
                <h3>{benefit.title}</h3>
                <p>{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="cta-section">
        <h2>Ready to Monitor Your Network?</h2>
        <a href="/contact" className="cta-button">
          Contact Us
        </a>
      </section>
    </div>
  );
};

export default NetworkMonitoring;
