// src/components/Navbar.jsx

import React, { useEffect, useRef, useState } from "react";
import {
  FaAngleDown,
  FaAngleUp,
  FaBars,
  FaBuffer,
  FaCogs,
  FaEnvelope,
  FaHome,
  FaServer,
  FaTimes,
} from "react-icons/fa";
import { Link, NavLink } from "react-router-dom";
import "./Navbar.css";

const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isSolutionsOpen, setIsSolutionsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const mobileMenuRef = useRef(null);
  const solutionsRef = useRef(null);
  const hamburgerRef = useRef(null);

  // Handle scroll to change Navbar style
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Close mobile menu and solutions dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Close mobile menu if clicked outside
      if (
        isMobileMenuOpen &&
        mobileMenuRef.current &&
        !mobileMenuRef.current.contains(event.target) &&
        !hamburgerRef.current.contains(event.target)
      ) {
        setIsMobileMenuOpen(false);
      }

      // Close solutions dropdown if clicked outside
      if (
        isSolutionsOpen &&
        solutionsRef.current &&
        !solutionsRef.current.contains(event.target) &&
        !event.target.closest(".navbar__solutions-button")
      ) {
        setIsSolutionsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [isMobileMenuOpen, isSolutionsOpen]);

  // Toggle mobile menu
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
    if (isSolutionsOpen) setIsSolutionsOpen(false);
  };

  // Toggle Solutions dropdown
  const toggleSolutions = () => {
    setIsSolutionsOpen(!isSolutionsOpen);
  };

  // Menu Items Data
  const menuItems = [
    {
      name: "Home",
      path: "/",
      icon: <FaHome />,
    },
    {
      name: "Brands",
      path: "/brands",
      icon: <FaBuffer />, // Represents Brands
    },
    {
      name: "Solutions",
      path: "/solutions",
      icon: <FaCogs />,
      submenu: [
        {
          name: "Network Infrastructure",
          path: "/solutions/network-infrastructure",
        },
        {
          name: "Data Center Infrastructure",
          path: "/solutions/data-center-infrastructure",
        },
        { name: "Cloud Computing", path: "/solutions/cloud-computing" },
        { name: "Virtualization", path: "/solutions/virtualization" },
        { name: "Cybersecurity", path: "/solutions/cybersecurity" },
        { name: "Collaboration Tools", path: "/solutions/collaboration-tools" },
        {
          name: "IT Support & Management",
          path: "/solutions/it-support-management",
        },
        {
          name: "IoT Infrastructure Solutions",
          path: "/solutions/iot-infrastructure",
        },
        {
          name: "Video Content Analytics",
          path: "/solutions/video-content-analytics",
        },
      ],
    },
    {
      name: "Infrastructure Services", // New Menu Item
      path: "/infrastructure-service",
      icon: <FaServer />, // Icon representing Infrastructure Service
    },
    {
      name: "Contact",
      path: "/contact",
      icon: <FaEnvelope />,
      isButton: true, // Indicates this should be styled as a button
    },
  ];

  return (
    <nav
      className={`navbar ${isScrolled ? "navbar--scrolled" : ""}`}
      aria-label="Main Navigation"
    >
      <div className="navbar__container">
        {/* Logo */}
        <Link to="/" className="navbar__logo" aria-label="Homepage">
          <img src="/images/logo.svg" alt="Company Logo" loading="lazy" />
        </Link>

        {/* Hamburger Icon */}
        <button
          className="navbar__hamburger"
          onClick={toggleMobileMenu}
          aria-label={isMobileMenuOpen ? "Close Menu" : "Open Menu"}
          aria-expanded={isMobileMenuOpen}
          ref={hamburgerRef}
        >
          {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
        </button>

        {/* Menu Items */}
        <ul
          className={`navbar__menu ${
            isMobileMenuOpen ? "navbar__menu--active" : ""
          }`}
          ref={mobileMenuRef}
        >
          {menuItems.map((item, index) => (
            <li
              className={`navbar__item ${
                item.submenu ? "navbar__item--dropdown" : ""
              }`}
              key={index}
            >
              {!item.submenu ? (
                <NavLink
                  to={item.path}
                  className={({ isActive }) =>
                    isActive
                      ? "navbar__link navbar__link--active"
                      : "navbar__link"
                  }
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  <span className="navbar__icon">{item.icon}</span>
                  {item.isButton ? (
                    <span className="navbar__contact-text">{item.name}</span>
                  ) : (
                    item.name
                  )}
                </NavLink>
              ) : (
                <>
                  <button
                    className="navbar__solutions-button"
                    onClick={toggleSolutions}
                    aria-haspopup="true"
                    aria-expanded={isSolutionsOpen}
                    style={{
                      fontSize: "1rem",
                    }}
                  >
                    <span className="navbar__icon">{item.icon}</span>
                    <span>{item.name}</span>
                    <span
                      className="navbar__dropdown-icon"
                      style={{ position: "relative", top: "2px" }}
                    >
                      {isSolutionsOpen ? <FaAngleUp /> : <FaAngleDown />}
                    </span>
                  </button>
                  <ul
                    className={`navbar__submenu ${
                      isSolutionsOpen ? "navbar__submenu--open" : ""
                    }`}
                    ref={solutionsRef}
                  >
                    {item.submenu.map((subItem, subIndex) => (
                      <li className="navbar__subitem" key={subIndex}>
                        <NavLink
                          to={subItem.path}
                          className={({ isActive }) =>
                            isActive
                              ? "navbar__sublink navbar__sublink--active"
                              : "navbar__sublink"
                          }
                          onClick={() => setIsMobileMenuOpen(false)}
                        >
                          {subItem.name}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
