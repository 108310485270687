import React from 'react';
import { Parallax } from 'react-parallax';
import './Services.css';
import {
  FaCloudUploadAlt,
  FaServer,
  FaSyncAlt,
  FaRocket,
  FaClock,
  FaShieldAlt,
  FaHeadset,
  FaCogs,
} from 'react-icons/fa';

const CloudMigration = () => {
  const steps = [
    {
      icon: <FaServer />,
      title: 'Assessment & Planning',
      description: 'Evaluate your current infrastructure and plan the migration.',
      iconClass: 'blue',
    },
    {
      icon: <FaSyncAlt />,
      title: 'Data Migration',
      description: 'Securely transfer your data to the cloud.',
      iconClass: 'blue',
    },
    {
      icon: <FaRocket />,
      title: 'Application Migration',
      description: 'Migrate applications with minimal downtime.',
      iconClass: 'blue',
    },
    {
      icon: <FaCogs />,
      title: 'Optimization',
      description: 'Optimize performance in the new environment.',
      iconClass: 'blue',
    },
  ];

  const benefits = [
    {
      icon: <FaClock />,
      title: 'Reduced Downtime',
      description: 'Efficient migration processes minimize disruptions.',
      iconClass: 'teal',
    },
    {
      icon: <FaShieldAlt />,
      title: 'Enhanced Security',
      description: 'Secure migration to protect your data.',
      iconClass: 'teal',
    },
    {
      icon: <FaHeadset />,
      title: 'Expert Support',
      description: 'Guidance from our experienced cloud specialists.',
      iconClass: 'teal',
    },
    {
      icon: <FaCloudUploadAlt />,
      title: 'Scalability',
      description: 'Leverage the cloud for scalable resources.',
      iconClass: 'teal',
    },
  ];

  return (
    <div className="services-page">
      {/* Hero Section */}
      <section
        className="hero-section"
        style={{
          backgroundImage:
            "url('/images/services/cloud-migration/hero-cloud-migration.jpg')",
        }}
      >
        <div className="hero-content">
          <h1>Cloud Migration</h1>
          <p>Effortlessly shift your business functions to the cloud. Our cloud migration services look awesome
Easy, secure and hassle-free migration of your data and applications to the cloud environment you want. We handle everything from design to implementation and scaling your business well.
</p>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/cloud-migration/parallax1.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Embrace the Cloud</h2>
        </div>
      </Parallax>

      {/* Migration Steps Section */}
      <section className="section light-bg">
        <div className="container">
          <h2>Our Migration Process</h2>
          <div className="grid">
            {steps.map((step, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${step.iconClass}`}>{step.icon}</div>
                <h3>{step.title}</h3>
                <p>{step.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/cloud-migration/parallax2.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Future-Ready Infrastructure</h2>
        </div>
      </Parallax>

      {/* Benefits Section */}
      <section className="section dark-bg">
        <div className="container">
          <h2>Benefits</h2>
          <div className="grid">
            {benefits.map((benefit, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${benefit.iconClass}`}>{benefit.icon}</div>
                <h3>{benefit.title}</h3>
                <p>{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="cta-section">
        <h2>Ready to Migrate to the Cloud?</h2>
        <a href="/contact" className="cta-button">
          Contact Us
        </a>
      </section>
    </div>
  );
};

export default CloudMigration;
