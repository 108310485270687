import React from "react";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./CiscoPage.css";

// Carousel settings
const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3, // Number of slides to show on desktop
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000, // 3 seconds
  responsive: [
    {
      breakpoint: 1200, // Tablet breakpoint
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 768, // Mobile breakpoint
      settings: {
        slidesToShow: 1,
      },
    },
  ],
  arrows: false, // Hide arrows for a cleaner look
};
// Product Data
const products = [
  {
    name: "Enterprise networking solutions",
    // description:
    //   "A 2U rack-mounted NAS designed for enterprise-level storage with redundancy and high performance.",
    image: "/images/products/cisco/cisco-enterprise-networking-solutions.png",
  },
  {
    name: "Cybersecurity solutions",
    // description:
    //   "A powerful rack-mounted NAS with advanced data management, suitable for SMBs requiring virtualization support.",
    image: "/images/products/cisco/cisco-cybersecurity-solutions.png",
  },
  {
    name: "Collaboration solutions",
    // description:
    //   "A desktop NAS offering high scalability and performance, ideal for small to medium-sized businesses.",
    image: "/images/products/cisco/cisco-collaboration-solutions.png",
  },
  {
    name: "Data center and cloud solutions",
    // description:
    //   "A 6-bay NAS for businesses demanding fast data processing and seamless virtualization support.",
    image: "/images/products/cisco/cisco-data-center-cloud-solutions.png",
  },
];

const CiscoPage = () => {
  const navigate = useNavigate(); // Initialize navigate

  return (
    <div className="cisco-page-container">
      <div className="cisco-hero-container">
        <div className="hero-text">
          <h1>Cisco Systems, Inc.</h1>
          <h2>Innovating the Future of Networking</h2>
          <p>
            Cisco Systems, Inc. is a global leader in networking technologies.
            Founded in 1984, Cisco continues to define the landscape of the
            modern internet with innovations in routing, switching,
            cybersecurity, and IoT solutions. Through their commitment to
            improving how people connect, Cisco has become a trusted partner for
            businesses worldwide, advancing digital transformation.
          </p>
          <button
            className="learn-more-button"
            onClick={() => navigate("/contact")}
          >
            Learn More
          </button>
        </div>
        <div
          className="hero-image"
          style={{
            backgroundColor: "#fff",
            padding: "50px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <div className="hero-image">
            <img src="/images/logos/Cisco-logo.svg" alt="Cisco Logo" />
          </div>
        </div>
      </div>

      {/* Product Carousel Section */}
      <div className="product-carousel-section">
        <h2 className="carousel-title">Our Cisco Solutions</h2>
        <Slider {...settings} aria-label="Product Carousel">
          {products.map((product, index) => (
            <div key={index} className="product-card">
              <a
                href={`/products/${product.name}`}
                className="product-link"
                aria-label={`Learn more about ${product.name}`}
              >
                <div className="product-image-container">
                  <img
                    src={product.image}
                    alt={`${product.name} Logo`}
                    className="product-image"
                    loading="lazy"
                  />
                </div>
                <h3 className="product-name">{product.name}</h3>
                <p className="product-description">{product.description}</p>
              </a>
            </div>
          ))}
        </Slider>
      </div>

      <div className="video-container">
        <div className="video-responsive">
          <ReactPlayer
            url="https://www.youtube.com/watch?v=IpKZbUxsvHI"
            controls
            width="100%"
            height="100%"
            className="react-player"
          />
        </div>
      </div>
    </div>
  );
};

export default CiscoPage;
