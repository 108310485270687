import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import CiscoPage from "./brands-pages/CiscoPage";
import DasanNetworksPage from "./brands-pages/DasanNetworksPage";
import FlukeNetworksPage from "./brands-pages/FlukeNetworksPage";
import HPEPage from "./brands-pages/HPEPage";
import JuniperPage from "./brands-pages/JuniperPage";
import LenovoPage from "./brands-pages/LenovoPage";
import LinkFastPage from "./brands-pages/LinkFastPage";
import LogitechPage from "./brands-pages/LogitechPage";
import MicronPage from "./brands-pages/MicronPage";
import MicrosoftPage from "./brands-pages/MicrosoftPage";
import PanduitPage from "./brands-pages/PanduitPage";
import PolyPage from "./brands-pages/PolyPage";
import SynologyPage from "./brands-pages/SynologyPage";
import TPLinkPage from "./brands-pages/TPLinkPage";
import UbiquitiPage from "./brands-pages/UbiquitiPage";
import VCAPage from "./brands-pages/VCAPage";
import VolktekPage from "./brands-pages/VolktekPage";
import BrandsCarousel from "./BrandsCarousel";
import BrandsPage from "./BrandsPage";
import ContactUsPage from "./ContactUsPage";
import Footer from "./Footer";
import FrequentlyAskedQuestions from "./FrequentlyAskedQuestions";
import HeroSection from "./HeroSection";
import InfrastructureService from "./InfrastructureService";
import Navbar from "./Navbar";
import PrivacyPolicy from "./PrivacyPolicy";
import CloudComputing from "./solutions-pages/CloudComputing/CloudComputing";
import CloudArchitectureDesign from "./solutions-pages/CloudComputing/services-pages/CloudArchitectureDesign";
import CloudInfrastructureManagement from "./solutions-pages/CloudComputing/services-pages/CloudInfrastructureManagement";
import CloudMigration from "./solutions-pages/CloudComputing/services-pages/CloudMigration";
import CloudSecurity from "./solutions-pages/CloudComputing/services-pages/CloudSecurity";
import HybridCloudSolutions from "./solutions-pages/CloudComputing/services-pages/HybridCloudSolutions";
import PrivateCloudSolutions from "./solutions-pages/CloudComputing/services-pages/PrivateCloudSolutions";
import PublicCloudSolutions from "./solutions-pages/CloudComputing/services-pages/PublicCloudSolutions";
import CollaborationTools from "./solutions-pages/CollaborationTools/CollaborationTools";
import FileSharing from "./solutions-pages/CollaborationTools/services-pages/FileSharing";
import InstantMessaging from "./solutions-pages/CollaborationTools/services-pages/InstantMessaging";
import SecureEmailSolutions from "./solutions-pages/CollaborationTools/services-pages/SecureEmailSolutions";
import UnifiedCommunication from "./solutions-pages/CollaborationTools/services-pages/UnifiedCommunication";
import VideoConferencing from "./solutions-pages/CollaborationTools/services-pages/VideoConferencing";
import Cybersecurity from "./solutions-pages/Cybersecurity/Cybersecurity";
import AntivirusProtection from "./solutions-pages/Cybersecurity/services-pages/AntivirusProtection";
import FirewallProtection from "./solutions-pages/Cybersecurity/services-pages/FirewallProtection";
import IntrusionDetection from "./solutions-pages/Cybersecurity/services-pages/IntrusionDetection";
import SecurityAudits from "./solutions-pages/Cybersecurity/services-pages/SecurityAudits";
import VulnerabilityAssessments from "./solutions-pages/Cybersecurity/services-pages/VulnerabilityAssessments";
import DataCenterInfrastructure from "./solutions-pages/DataCenterDesign/DataCenterInfrastructure";
import DataCenterDesign from "./solutions-pages/DataCenterDesign/services-pages/DataCenterDesign";
import DataCenterImplementation from "./solutions-pages/DataCenterDesign/services-pages/DataCenterImplementation";
import DataCenterManagement from "./solutions-pages/DataCenterDesign/services-pages/DataCenterManagement";
import DisasterRecoverySolutions from "./solutions-pages/DataCenterDesign/services-pages/DisasterRecoverySolutions";
import ServerSolutions from "./solutions-pages/DataCenterDesign/services-pages/ServerSolutions";
import StorageSystems from "./solutions-pages/DataCenterDesign/services-pages/StorageSystems";
import VirtualizationTechnologies from "./solutions-pages/DataCenterDesign/services-pages/VirtualizationTechnologies";
import IoTInfrastructureSolutions from "./solutions-pages/IoTInfrastructureSolutions/IoTInfrastructureSolutions";
import CloudIoTPlatforms from "./solutions-pages/IoTInfrastructureSolutions/services-pages/CloudIoTPlatforms";
import IoTDeviceManagement from "./solutions-pages/IoTInfrastructureSolutions/services-pages/IoTDeviceManagement";
import IoTGateways from "./solutions-pages/IoTInfrastructureSolutions/services-pages/IoTGateways";
import IoTImplementation from "./solutions-pages/IoTInfrastructureSolutions/services-pages/IoTImplementation";
import IoTSecurity from "./solutions-pages/IoTInfrastructureSolutions/services-pages/IoTSecurity";
import SensorsEdgeDevices from "./solutions-pages/IoTInfrastructureSolutions/services-pages/SensorsEdgeDevices";
import ITSupportManagement from "./solutions-pages/ITSupportManagement/ITSupportManagement";
import DisasterRecovery from "./solutions-pages/ITSupportManagement/services-pages/DisasterRecovery";
import HelpDeskSupport from "./solutions-pages/ITSupportManagement/services-pages/HelpDeskSupport";
import InfrastructureMaintenance from "./solutions-pages/ITSupportManagement/services-pages/InfrastructureMaintenance";
import ITAssetManagement from "./solutions-pages/ITSupportManagement/services-pages/ITAssetManagement";
import NetworkMonitoring from "./solutions-pages/ITSupportManagement/services-pages/NetworkMonitoring";
import RemoteITManagement from "./solutions-pages/ITSupportManagement/services-pages/RemoteITManagement";
import NetworkInfrastructure from "./solutions-pages/NetworkIfrastructure/NetworkInfrastructure";
import LANSolutions from "./solutions-pages/NetworkIfrastructure/services-pages/LANSolutions";
import NetworkDesign from "./solutions-pages/NetworkIfrastructure/services-pages/NetworkDesign";
import NetworkImplementation from "./solutions-pages/NetworkIfrastructure/services-pages/NetworkImplementation";
import NetworkManagement from "./solutions-pages/NetworkIfrastructure/services-pages/NetworkManagement";
import NetworkSecurity from "./solutions-pages/NetworkIfrastructure/services-pages/NetworkSecurity";
import WANSolutions from "./solutions-pages/NetworkIfrastructure/services-pages/WANSolutions";
import WirelessNetworkSolutions from "./solutions-pages/NetworkIfrastructure/services-pages/WirelessNetworkSolutions";
import VideoContentAnalyticsSolutionsPage from "./solutions-pages/VideoContentAnalytics/VideoContentAnalyticsSolutionPage";
import BusinessContinuity from "./solutions-pages/Virtualization/services-pages/BusinessContinuity";
import DesktopVirtualization from "./solutions-pages/Virtualization/services-pages/DesktopVirtualization";
import ResourceOptimization from "./solutions-pages/Virtualization/services-pages/ResourceOptimization";
import ServerVirtualization from "./solutions-pages/Virtualization/services-pages/ServerVirtualization";
import VirtualInfrastructureManagement from "./solutions-pages/Virtualization/services-pages/VirtualInfrastructureManagement";
import Virtualization from "./solutions-pages/Virtualization/Virtualization";
import StatisticsSection from "./StatisticsSection";
import TermsAndConditions from "./TermsAndConditions";
import WhyChooseUsSection from "./WhyChooseUsSection";

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <HeroSection />
                <WhyChooseUsSection />
                <BrandsCarousel />
                <StatisticsSection />
                <FrequentlyAskedQuestions />
              </>
            }
          />
          <Route path="/contact" element={<ContactUsPage />} />
          <Route path="/brands/cisco" element={<CiscoPage />} />
          <Route path="/brands/ubiquiti" element={<UbiquitiPage />} />
          <Route path="/brands/volktek" element={<VolktekPage />} />
          <Route
            path="/brands/dasan-networks"
            element={<DasanNetworksPage />}
          />
          <Route path="/brands/panduit" element={<PanduitPage />} />
          <Route path="/brands/linkfast" element={<LinkFastPage />} />
          <Route
            path="/brands/fluke-networks"
            element={<FlukeNetworksPage />}
          />
          <Route path="/brands/synology" element={<SynologyPage />} />
          <Route path="/brands/tplink" element={<TPLinkPage />} />
          <Route path="/brands/hpe" element={<HPEPage />} />
          <Route path="/brands/juniper" element={<JuniperPage />} />
          <Route path="/brands/logitech" element={<LogitechPage />} />
          <Route path="/brands/micron" element={<MicronPage />} />
          <Route path="/brands/microsoft" element={<MicrosoftPage />} />
          <Route path="/brands/lenovo" element={<LenovoPage />} />
          <Route path="/brands/poly" element={<PolyPage />} />
          <Route path="/brands/vca" element={<VCAPage />} />
          <Route path="/brands" element={<BrandsPage />} />

          <Route
            path="/solutions/network-infrastructure"
            element={<NetworkInfrastructure />}
          />

          {/* Service pages */}

          <Route path="/services/network-design" element={<NetworkDesign />} />
          <Route
            path="/services/network-implementation"
            element={<NetworkImplementation />}
          />
          <Route path="/services/lan-solutions" element={<LANSolutions />} />
          <Route path="/services/wan-solutions" element={<WANSolutions />} />
          <Route
            path="/services/wireless-network-solutions"
            element={<WirelessNetworkSolutions />}
          />
          <Route
            path="/services/network-management"
            element={<NetworkManagement />}
          />
          <Route
            path="/services/network-security"
            element={<NetworkSecurity />}
          />

          <Route
            path="/solutions/data-center-infrastructure"
            element={<DataCenterInfrastructure />}
          />

          {/* Service pages */}

          <Route
            path="/services/data-center-design"
            element={<DataCenterDesign />}
          />
          <Route
            path="/services/data-center-implementation"
            element={<DataCenterImplementation />}
          />
          <Route
            path="/services/server-solutions"
            element={<ServerSolutions />}
          />
          <Route
            path="/services/storage-systems"
            element={<StorageSystems />}
          />
          <Route
            path="/services/virtualization-technologies"
            element={<VirtualizationTechnologies />}
          />
          <Route
            path="/services/data-center-management"
            element={<DataCenterManagement />}
          />
          <Route
            path="/services/disaster-recovery-solutions"
            element={<DisasterRecoverySolutions />}
          />

          <Route
            path="/solutions/cloud-computing"
            element={<CloudComputing />}
          />

          {/* Service pages */}

          <Route
            path="/services/cloud-migration"
            element={<CloudMigration />}
          />
          <Route
            path="/services/cloud-architecture-design"
            element={<CloudArchitectureDesign />}
          />
          <Route
            path="/services/cloud-infrastructure-management"
            element={<CloudInfrastructureManagement />}
          />
          <Route
            path="/services/public-cloud-solutions"
            element={<PublicCloudSolutions />}
          />
          <Route
            path="/services/private-cloud-solutions"
            element={<PrivateCloudSolutions />}
          />
          <Route
            path="/services/hybrid-cloud-solutions"
            element={<HybridCloudSolutions />}
          />
          <Route path="/services/cloud-security" element={<CloudSecurity />} />

          <Route
            path="/solutions/virtualization"
            element={<Virtualization />}
          />

          {/* Service pages */}

          <Route
            path="/services/server-virtualization"
            element={<ServerVirtualization />}
          />
          <Route
            path="/services/desktop-virtualization"
            element={<DesktopVirtualization />}
          />
          <Route
            path="/services/virtual-infrastructure-management"
            element={<VirtualInfrastructureManagement />}
          />
          <Route
            path="/services/resource-optimization"
            element={<ResourceOptimization />}
          />
          <Route
            path="/services/business-continuity"
            element={<BusinessContinuity />}
          />

          <Route path="/solutions/cybersecurity" element={<Cybersecurity />} />

          {/* Service pages */}

          <Route
            path="/services/firewall-protection"
            element={<FirewallProtection />}
          />
          <Route
            path="/services/antivirus-malware-protection"
            element={<AntivirusProtection />}
          />
          <Route
            path="/services/intrusion-detection-prevention"
            element={<IntrusionDetection />}
          />
          <Route
            path="/services/vulnerability-assessments"
            element={<VulnerabilityAssessments />}
          />
          <Route
            path="/services/security-audits-compliance"
            element={<SecurityAudits />}
          />
          <Route
            path="/services/network-security-monitoring"
            element={<NetworkSecurity />}
          />

          <Route
            path="/solutions/collaboration-tools"
            element={<CollaborationTools />}
          />

          {/* Service pages */}

          <Route
            path="/services/secure-email-solutions"
            element={<SecureEmailSolutions />}
          />
          <Route
            path="/services/instant-messaging"
            element={<InstantMessaging />}
          />
          <Route
            path="/services/video-conferencing-solutions"
            element={<VideoConferencing />}
          />
          <Route
            path="/services/file-sharing-collaboration"
            element={<FileSharing />}
          />
          <Route
            path="/services/unified-communication-platforms"
            element={<UnifiedCommunication />}
          />

          <Route
            path="/solutions/it-support-management"
            element={<ITSupportManagement />}
          />

          {/* Service pages */}

          <Route
            path="/services/help-desk-support"
            element={<HelpDeskSupport />}
          />
          <Route
            path="/services/network-monitoring"
            element={<NetworkMonitoring />}
          />
          <Route
            path="/services/remote-it-management"
            element={<RemoteITManagement />}
          />
          <Route
            path="/services/infrastructure-maintenance"
            element={<InfrastructureMaintenance />}
          />
          <Route
            path="/services/it-asset-management"
            element={<ITAssetManagement />}
          />
          <Route
            path="/services/disaster-recovery-backup"
            element={<DisasterRecovery />}
          />

          <Route
            path="/solutions/iot-infrastructure"
            element={<IoTInfrastructureSolutions />}
          />

          {/* Service pages */}

          <Route
            path="/services/iot-implementation"
            element={<IoTImplementation />}
          />
          <Route
            path="/services/iot-device-management"
            element={<IoTDeviceManagement />}
          />
          <Route
            path="/services/sensors-edge-devices"
            element={<SensorsEdgeDevices />}
          />
          <Route path="/services/iot-gateways" element={<IoTGateways />} />
          <Route
            path="/services/cloud-based-iot-platforms"
            element={<CloudIoTPlatforms />}
          />
          <Route path="/services/iot-security" element={<IoTSecurity />} />

          <Route
            path="/infrastructure-service"
            element={<InfrastructureService />}
          />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />

          <Route
            path="/solutions/video-content-analytics"
            element={<VideoContentAnalyticsSolutionsPage />}
          />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
