import React from 'react';
import { Parallax } from 'react-parallax';
import './Services.css';
import {
  FaEnvelope,
  FaLock,
  FaShieldAlt,
  FaUserSecret,
  FaCheckCircle,
  FaHeadset,
  FaCloud,
  FaSyncAlt,
} from 'react-icons/fa';

const SecureEmailSolutions = () => {
  const features = [
    {
      icon: <FaLock />,
      title: 'Encryption',
      description: 'End-to-end encryption to protect your emails.',
      iconClass: 'blue',
    },
    {
      icon: <FaShieldAlt />,
      title: 'Spam Filtering',
      description: 'Advanced filters to keep your inbox clean.',
      iconClass: 'blue',
    },
    {
      icon: <FaUserSecret />,
      title: 'Data Loss Prevention',
      description: 'Prevent sensitive information from being sent out.',
      iconClass: 'blue',
    },
    {
      icon: <FaCloud />,
      title: 'Cloud-Based Solutions',
      description: 'Access your email securely from anywhere.',
      iconClass: 'blue',
    },
  ];

  const benefits = [
    {
      icon: <FaCheckCircle />,
      title: 'Enhanced Security',
      description: 'Protect your communication from threats.',
      iconClass: 'teal',
    },
    {
      icon: <FaSyncAlt />,
      title: 'Seamless Integration',
      description: 'Works with your existing email clients.',
      iconClass: 'teal',
    },
    {
      icon: <FaHeadset />,
      title: 'Expert Support',
      description: 'Assistance from our email security specialists.',
      iconClass: 'teal',
    },
    {
      icon: <FaEnvelope />,
      title: 'Reliable Delivery',
      description: 'Ensure your emails reach their destination.',
      iconClass: 'teal',
    },
  ];

  return (
    <div className="services-page">
      {/* Hero Section */}
      <section
        className="hero-section"
        style={{
          backgroundImage:
            "url('/images/services/secure-email-solutions/hero-secure-email-solutions.jpg')",
        }}
      >
        <div className="hero-content">
          <h1>Secure Email Solutions</h1>
          <p>Our steady and secure email offerings make sure that your Correspondence stays private and guarded from cyber threats. We put in force superior encryption and safety protocols to save you phishing, malware, and unauthorized right of entry, safeguarding your enterprise communications.</p>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/secure-email-solutions/parallax1.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Secure Your Communication</h2>
        </div>
      </Parallax>

      {/* Features Section */}
      <section className="section light-bg">
        <div className="container">
          <h2>Our Email Security Features</h2>
          <div className="grid">
            {features.map((feature, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${feature.iconClass}`}>
                  {feature.icon}
                </div>
                <h3>{feature.title}</h3>
                <p>{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/secure-email-solutions/parallax2.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Stay Protected</h2>
        </div>
      </Parallax>

      {/* Benefits Section */}
      <section className="section dark-bg">
        <div className="container">
          <h2>Benefits</h2>
          <div className="grid">
            {benefits.map((benefit, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${benefit.iconClass}`}>
                  {benefit.icon}
                </div>
                <h3>{benefit.title}</h3>
                <p>{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="cta-section">
        <h2>Ready to Secure Your Email?</h2>
        <a href="/contact" className="cta-button">
          Contact Us
        </a>
      </section>
    </div>
  );
};

export default SecureEmailSolutions;
