import React from 'react';
import { Parallax } from 'react-parallax';
import './Services.css';
import {
  FaCogs,
  FaArrowsAlt,
  FaPlug,
  FaShieldAlt,
} from 'react-icons/fa';

const NetworkDesign = () => {
  const features = [
    {
      icon: <FaCogs />,
      title: 'Customized Architecture',
      description: 'Tailored network designs that fit your unique business requirements.',
      iconClass: 'blue',
    },
    {
      icon: <FaArrowsAlt />,
      title: 'Scalable Solutions',
      description: 'Networks designed to grow with your business seamlessly.',
      iconClass: 'blue',
    },
    {
      icon: <FaPlug />,
      title: 'Seamless Integration',
      description: 'Smooth integration with your existing infrastructure.',
      iconClass: 'blue',
    },
    {
      icon: <FaShieldAlt />,
      title: 'Industry Compliance',
      description: 'Designs that adhere to the latest industry standards and regulations.',
      iconClass: 'blue',
    },
  ];

  const benefits = [
    {
      icon: <FaCogs />,
      title: 'Improved Performance',
      description: 'Experience faster and more reliable network operations.',
      iconClass: 'teal',
    },
    {
      icon: <FaShieldAlt />,
      title: 'Enhanced Security',
      description: 'Protect your data with robust security measures.',
      iconClass: 'teal',
    },
    {
      icon: <FaArrowsAlt />,
      title: 'Cost-Effective',
      description: 'Optimize costs with efficient network designs.',
      iconClass: 'teal',
    },
    {
      icon: <FaPlug />,
      title: 'Expert Support',
      description: 'Receive guidance from our team of experienced professionals.',
      iconClass: 'teal',
    },
  ];

  return (
    <div className="services-page">
      {/* Hero Section */}
      <section
        className="hero-section"
        style={{
          backgroundImage: "url('/images/network-design/hero-network-design.jpg')",
        }}
      >
        <div className="hero-content">
          <h1>Network Design</h1>
          <p>
            We specialize in building the most optimized scalable, secure network infrastructures.
            Your work matters. Our network design services involve LAN (Local Area Network), WAN (Wide Area Network), and wireless networks, which provide easy connectivity, security, and Performance on all devices.
          </p>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/network-design/hero-network-design.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Custom Network Solutions</h2>
        </div>
      </Parallax>

      {/* Key Features Section */}
      <section className="section light-bg">
        <div className="container">
          <h2>Key Features</h2>
          <div className="grid">
            {features.map((feature, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${feature.iconClass}`}>{feature.icon}</div>
                <h3>{feature.title}</h3>
                <p>{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/network-design/hero-network-design.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Future-Proof Your Business</h2>
        </div>
      </Parallax>

      {/* Benefits Section */}
      <section className="section dark-bg">
        <div className="container">
          <h2>Benefits</h2>
          <div className="grid">
            {benefits.map((benefit, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${benefit.iconClass}`}>{benefit.icon}</div>
                <h3>{benefit.title}</h3>
                <p>{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="cta-section">
        <h2>Ready to Design Your Ideal Network?</h2>
        <a href="/contact" className="cta-button">
          Contact Us
        </a>
      </section>
    </div>
  );
};

export default NetworkDesign;
