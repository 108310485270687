// File: src/solutions-pages/Cybersecurity.jsx

import React from "react";
import {
  FaBug,
  FaClipboardCheck,
  FaEye,
  FaLock,
  FaShieldAlt,
  FaVirus,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import "./Cybersecurity.css";

// Import images from the 'public' directory using 'process.env.PUBLIC_URL'
const heroImage = `${process.env.PUBLIC_URL}/images/cybersecurity/hero-cybersecurity.jpg`;
const parallaxImage = `${process.env.PUBLIC_URL}/images/cybersecurity/parallax-cybersecurity.jpg`; // Ensure you have this image
const overviewImage = `${process.env.PUBLIC_URL}/images/cybersecurity/overview-cybersecurity.jpg`;

// Service images (optional: if you have specific images for services)
const serviceImages = {
  firewallProtection: `${process.env.PUBLIC_URL}/images/services/firewall-protection.jpg`,
  antivirusMalwareProtection: `${process.env.PUBLIC_URL}/images/services/antivirus-malware-protection.jpg`,
  intrusionDetectionPrevention: `${process.env.PUBLIC_URL}/images/services/intrusion-detection-prevention.jpg`,
  vulnerabilityAssessments: `${process.env.PUBLIC_URL}/images/services/vulnerability-assessments.jpg`,
  securityAuditsCompliance: `${process.env.PUBLIC_URL}/images/services/security-audits-compliance.jpg`,
  networkSecurityMonitoring: `${process.env.PUBLIC_URL}/images/services/network-security-monitoring.jpg`,
};

// Brand logos (optional: similar to other pages)
const brands = [
  {
    name: "Cisco",
    logo: `${process.env.PUBLIC_URL}/images/brands/cisco-logo.png`,
    link: "/brands/cisco",
    alt: "Cisco",
  },
  {
    name: "Palo Alto Networks",
    logo: `${process.env.PUBLIC_URL}/images/brands/paloalto-logo.png`,
    link: "/brands/paloalto",
    alt: "Palo Alto Networks",
  },
  {
    name: "Fortinet",
    logo: `${process.env.PUBLIC_URL}/images/brands/fortinet-logo.png`,
    link: "/brands/fortinet",
    alt: "Fortinet",
  },
  {
    name: "Check Point",
    logo: `${process.env.PUBLIC_URL}/images/brands/checkpoint-logo.png`,
    link: "/brands/checkpoint",
    alt: "Check Point",
  },
];

const services = [
  {
    title: "Firewall Protection",
    description:
      "Implement robust firewall solutions to safeguard your network from unauthorized access.",
    icon: <FaShieldAlt />,
    image: serviceImages.firewallProtection, // Optional: if you have service images
    link: "/services/firewall-protection",
  },
  {
    title: "Antivirus & Malware Protection",
    description:
      "Protect your systems from viruses, malware, and other malicious software with our advanced solutions.",
    icon: <FaVirus />,
    image: serviceImages.antivirusMalwareProtection,
    link: "/services/antivirus-malware-protection",
  },
  {
    title: "Intrusion Detection & Prevention",
    description:
      "Monitor and prevent unauthorized intrusions with our comprehensive detection systems.",
    icon: <FaLock />,
    image: serviceImages.intrusionDetectionPrevention,
    link: "/services/intrusion-detection-prevention",
  },
  {
    title: "Vulnerability Assessments",
    description:
      "Identify and address vulnerabilities in your network before they can be exploited.",
    icon: <FaBug />,
    image: serviceImages.vulnerabilityAssessments,
    link: "/services/vulnerability-assessments",
  },
  {
    title: "Security Audits & Compliance",
    description:
      "Ensure your organization meets industry standards and compliance requirements with thorough security audits.",
    icon: <FaClipboardCheck />,
    image: serviceImages.securityAuditsCompliance,
    link: "/services/security-audits-compliance",
  },
  {
    title: "Network Security Monitoring",
    description:
      "Continuously monitor your network to detect and respond to security threats in real-time.",
    icon: <FaEye />,
    image: serviceImages.networkSecurityMonitoring,
    link: "/services/network-security-monitoring",
  },
];

const Cybersecurity = () => {
  return (
    <div className="cybersecurity">
      {/* Hero Section */}
      <section
        className="hero-section"
        style={{
          backgroundImage: `url(${heroImage})`,
        }}
      >
        <div className="hero-overlay">
          <h1>Cybersecurity Solutions</h1>
          <p>
            Protect your digital assets with our comprehensive cybersecurity
            services. Stay ahead of threats and ensure the integrity of your
            systems.
          </p>
          <Link to="/contact" className="hero-button">
            Get Started
          </Link>
        </div>
        <div className="scroll-down-indicator"></div>
      </section>

      {/* Overview Section */}
      <section className="overview-section">
        <div className="container">
          <h2>Secure Your Business Against Cyber Threats</h2>
          <div className="overview-content">
            <div className="overview-text">
              <p>
                In an increasingly connected world, cybersecurity is more
                critical than ever. Our expert team provides tailored solutions
                to protect your business from evolving cyber threats.
              </p>
              <p>
                We employ the latest technologies and best practices to ensure
                your network, data, and applications remain secure.
              </p>
            </div>
            <div className="overview-image">
              <img src={overviewImage} alt="Cybersecurity Overview" />
            </div>
          </div>
        </div>
      </section>

      {/* Services Offered Section */}
      <section className="services-section">
        <div className="container">
          <h2>Our Cybersecurity Services</h2>
          <div className="services-grid">
            {services.map((service, index) => (
              <Link to={service.link} className="service-card" key={index}>
                <div
                  className="service-image"
                  style={{ backgroundImage: `url(${service.image})` }}
                >
                  <div className="service-icon">{service.icon}</div>
                </div>
                <div className="service-content">
                  <h3>{service.title}</h3>
                  <p>{service.description}</p>
                  <span className="read-more">Read More</span>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </section>

      {/* Parallax Section */}
      <section
        className="parallax-section"
        style={{
          backgroundImage: `url(${parallaxImage})`,
        }}
      >
        <div className="parallax-overlay">
          <h2>Comprehensive Cybersecurity for Modern Businesses</h2>
          <p>
            Enhance your security posture with our state-of-the-art
            cybersecurity services designed to meet your unique business needs.
          </p>
          <Link to="/solutions" className="parallax-button">
            Learn More
          </Link>
        </div>
      </section>

      {/* Call to Action */}
      <section className="cta-section">
        <div className="container">
          <h2>Ready to Enhance Your Cybersecurity?</h2>
          <p>
            Contact us today for a consultation and discover how our
            cybersecurity services can protect your business.
          </p>
          <Link to="/contact" className="cta-button">
            Contact Us
          </Link>
        </div>
      </section>
    </div>
  );
};

export default Cybersecurity;
