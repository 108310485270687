import { default as React } from "react";
import { useNavigate } from "react-router-dom";
import "./TPLinkPage.css"; // Custom styling for TP-Link page
import "./UbiquitiPage.css"; // Custom styling for Ubiquiti page
// SynologyPage.jsx
import ReactPlayer from "react-player";
import Slider from "react-slick"; // Import Slider from react-slick
import "./SynologyPage.css"; // Custom styling for Synology page

// Import slick-carousel CSS
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

// Product Data
const products = [
  {
    name: "Cloud gateways",
    // description:
    //   "A 2U rack-mounted NAS designed for enterprise-level storage with redundancy and high performance.",
    image: "/images/products/ubiquiti/ubiquity-cloud-gateways.jpg",
  },
  {
    name: "Switching solutions",
    // description:
    //   "A powerful rack-mounted NAS with advanced data management, suitable for SMBs requiring virtualization support.",
    image: "/images/products/ubiquiti/ubiquity-switching-solutions.jpg",
  },
  {
    name: "Wifi solutions",
    // description:
    //   "A desktop NAS offering high scalability and performance, ideal for small to medium-sized businesses.",
    image: "/images/products/ubiquiti/ubiquity-wifi-solutions.jpg",
  },
  {
    name: "Camera security solutions",
    // description:
    //   "A 6-bay NAS for businesses demanding fast data processing and seamless virtualization support.",
    image: "/images/products/ubiquiti/ubiquity-camera-security-solutions.jpg",
  },
];

// Carousel settings
const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3, // Number of slides to show on desktop
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000, // 3 seconds
  responsive: [
    {
      breakpoint: 1200, // Tablet breakpoint
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 768, // Mobile breakpoint
      settings: {
        slidesToShow: 1,
      },
    },
  ],
  arrows: false, // Hide arrows for a cleaner look
};

const UbiquitiPage = () => {
  const navigate = useNavigate();

  return (
    <div className="ubiquiti-page-container">
      <div className="ubiquiti-hero-container">
        <div className="hero-text">
          <h1>Ubiquiti Networks</h1>
          <h2>Empowering Wireless Innovation</h2>
          <p>
            Ubiquiti Networks is a technology company specializing in wireless
            communication devices for enterprises and service providers. Founded
            in 2005, Ubiquiti has disrupted the wireless technology sector by
            offering high-performance networking solutions at unprecedented
            prices, making wireless innovation accessible to all.
          </p>
          <button
            className="learn-more-button"
            onClick={() => navigate("/contact")}
          >
            Learn More
          </button>
        </div>
        <div
          className="hero-image"
          style={{
            padding: "50px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "200px",
          }}
        >
          <img src="/images/logos/new/U-with-bg.svg" alt="Ubiquiti Logo" />
        </div>
      </div>

      {/* Product Carousel Section */}
      <div className="product-carousel-section">
        <h2 className="carousel-title">Our Ubiquiti Solutions</h2>
        <Slider {...settings} aria-label="Product Carousel">
          {products.map((product, index) => (
            <div key={index} className="product-card">
              <a
                href={`/products/${product.name}`}
                className="product-link"
                aria-label={`Learn more about ${product.name}`}
              >
                <div className="product-image-container">
                  <img
                    src={product.image}
                    alt={`${product.name} Logo`}
                    className="product-image"
                    loading="lazy"
                  />
                </div>
                <h3 className="product-name">{product.name}</h3>
                <p className="product-description">{product.description}</p>
              </a>
            </div>
          ))}
        </Slider>
      </div>

      <div className="video-container">
        <div className="video-responsive">
          <ReactPlayer
            url="https://www.youtube.com/watch?v=ixnyxmggNUY"
            controls
            width="100%"
            height="100%"
            className="react-player"
          />
        </div>
      </div>
    </div>
  );
};

export default UbiquitiPage;
