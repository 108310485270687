import React from 'react';
import { Parallax } from 'react-parallax';
import './Services.css';
import {
  FaDatabase,
  FaCloud,
  FaHdd,
  FaSyncAlt,
  FaLock,
  FaChartBar,
  FaCogs,
  FaHeadset,
} from 'react-icons/fa';

const StorageSystems = () => {
  const solutions = [
    {
      icon: <FaDatabase />,
      title: 'Network Attached Storage (NAS)',
      description: 'Centralized storage accessible over the network.',
      iconClass: 'blue',
    },
    {
      icon: <FaHdd />,
      title: 'Storage Area Network (SAN)',
      description: 'High-speed storage solutions for critical applications.',
      iconClass: 'blue',
    },
    {
      icon: <FaCloud />,
      title: 'Cloud Storage',
      description: 'Scalable storage solutions in the cloud.',
      iconClass: 'blue',
    },
    {
      icon: <FaSyncAlt />,
      title: 'Hybrid Storage',
      description: 'Combine local and cloud storage for flexibility.',
      iconClass: 'blue',
    },
  ];

  const benefits = [
    {
      icon: <FaLock />,
      title: 'Data Security',
      description: 'Protect your data with advanced security features.',
      iconClass: 'teal',
    },
    {
      icon: <FaChartBar />,
      title: 'Scalability',
      description: 'Easily expand storage capacity as needed.',
      iconClass: 'teal',
    },
    {
      icon: <FaCogs />,
      title: 'Performance',
      description: 'High-speed access to your critical data.',
      iconClass: 'teal',
    },
    {
      icon: <FaHeadset />,
      title: 'Expert Support',
      description: 'Assistance from our knowledgeable team.',
      iconClass: 'teal',
    },
  ];

  return (
    <div className="services-page">
      {/* Hero Section */}
      <section
        className="hero-section"
        style={{
          backgroundImage: "url('/images/services/storage-systems/hero-storage-systems.jpg')",
        }}
      >
        <div className="hero-content">
          <h1>Storage Systems</h1>
          <p>Data storage is critical for business continuity and disaster recovery. We provide comprehensive
Storage solutions, ensuring your data is easy, secure and fully backed up. Our systems are designed for current needs and future growth, optimizing your storage power and speed.
</p>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/storage-systems/parallax1.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Secure Your Data</h2>
        </div>
      </Parallax>

      {/* Solutions Section */}
      <section className="section light-bg">
        <div className="container">
          <h2>Our Storage Solutions</h2>
          <div className="grid">
            {solutions.map((solution, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${solution.iconClass}`}>{solution.icon}</div>
                <h3>{solution.title}</h3>
                <p>{solution.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/storage-systems/parallax2.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Reliable Access</h2>
        </div>
      </Parallax>

      {/* Benefits Section */}
      <section className="section dark-bg">
        <div className="container">
          <h2>Benefits</h2>
          <div className="grid">
            {benefits.map((benefit, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${benefit.iconClass}`}>{benefit.icon}</div>
                <h3>{benefit.title}</h3>
                <p>{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="cta-section">
        <h2>Ready to Enhance Your Storage?</h2>
        <a href="/contact" className="cta-button">
          Contact Us
        </a>
      </section>
    </div>
  );
};

export default StorageSystems;
