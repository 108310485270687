import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import "./StatisticsSection.css";

const StatisticItem = ({ icon, endNumber, endAdornment, description }) => {
  const [count, setCount] = useState(0);
  const { ref, inView } = useInView({ triggerOnce: true });

  useEffect(() => {
    if (inView) {
      let start = 0;
      const duration = 2000;
      const increment = endNumber / (duration / 16.67);

      const timer = setInterval(() => {
        start += increment;
        if (start >= endNumber) {
          clearInterval(timer);
          setCount(endNumber);
        } else {
          setCount(Math.ceil(start));
        }
      }, 16.67);
    }
  }, [inView, endNumber]);

  return (
    <div className="stat-item" ref={ref}>
      <div className="stat-icon">
        <img src={icon} alt={description} />
      </div>
      <div className="stat-details">
        <div className="stat-number">
          {count}
          {endAdornment}
        </div>
        <div className="stat-description">{description}</div>
      </div>
    </div>
  );
};

const StatisticsSection = () => {
  return (
    <div className="statistics-container">
      <div className="statistics-overlay"></div>{" "}
      {/* Background image overlay */}
      <h1 className="statistics-main-heading">Our Impact</h1>
      <h2 className="statistics-sub-heading">Achievements and Milestones</h2>
      <div className="statistics-content">
        <StatisticItem
          icon="/images/statistics-icon/globe-grid-icon.png"
          endNumber={200}
          endAdornment=" +"
          description="Cybersecurity Services"
        />
        <StatisticItem
          icon="/images/statistics-icon/client-protection-icon.png"
          endNumber={1200}
          description="Clients Satisfied"
        />
        <StatisticItem
          icon="/images/statistics-icon/service-icon.png"
          endNumber={100}
          endAdornment=" %"
          description="Service Satisfaction"
        />
        <StatisticItem
          icon="/images/statistics-icon/Team-icon.png"
          endNumber={25}
          description="Team Experts"
        />
      </div>
    </div>
  );
};

export default StatisticsSection;
