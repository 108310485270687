// src/WhyChooseUsSection.js
import React from 'react';
import './WhyChooseUsSection.css';

const WhyChooseUsSection = () => {
  return (
    <div className="why-choose-us-section">
      <h2 className="why-choose-us-heading">Our Expertise</h2>
      <p className="why-choose-us-subheading">Where Innovation Meets Reliability</p>
      <div className="why-choose-us-content">
        <div className="why-choose-us-text top-left">
          <h3>Comprehensive Solutions</h3>
          <p>From cybersecurity to network management, we cover all aspects of your IT needs.</p>
        </div>
        <div className="why-choose-us-text top-right">
          <h3>Trusted Partnerships</h3>
          <p>Our collaboration with industry-leading brands ensures access to top-tier technology and solutions.</p>
        </div>
        <div className="why-choose-us-text bottom-left">
          <h3>Tailored Services</h3>
          <p>We understand that every business is unique, and we customize our services to fit your specific needs.</p>
        </div>
        <div className="why-choose-us-text bottom-right">
          <h3>Expertise and Experience</h3>
          <p>Our team of professionals brings extensive experience and knowledge to every project, ensuring optimal outcomes.</p>
        </div>
        <div className="why-choose-us-center">
          <img src='/images/whychooseus.png' alt="Central image showcasing the product features" />
        </div>
      </div>
    </div>
  );
};

export default WhyChooseUsSection;
