import React from 'react';
import { Parallax } from 'react-parallax';
import './Services.css';
import {
  FaCloud,
  FaLock,
  FaCogs,
  FaArrowsAlt,
  FaShieldAlt,
  FaHeadset,
  FaChartBar,
  FaUserShield,
} from 'react-icons/fa';

const PrivateCloudSolutions = () => {
  const solutions = [
    {
      icon: <FaLock />,
      title: 'Enhanced Security',
      description: 'Dedicated resources for your business only.',
      iconClass: 'blue',
    },
    {
      icon: <FaCogs />,
      title: 'Customization',
      description: 'Tailored cloud environments to your needs.',
      iconClass: 'blue',
    },
    {
      icon: <FaArrowsAlt />,
      title: 'Scalability',
      description: 'Easily adjust resources as your business grows.',
      iconClass: 'blue',
    },
    {
      icon: <FaUserShield />,
      title: 'Compliance',
      description: 'Meet regulatory requirements with dedicated infrastructure.',
      iconClass: 'blue',
    },
  ];

  const benefits = [
    {
      icon: <FaShieldAlt />,
      title: 'Data Protection',
      description: 'Keep your data secure and private.',
      iconClass: 'teal',
    },
    {
      icon: <FaChartBar />,
      title: 'Performance',
      description: 'High-performance computing resources.',
      iconClass: 'teal',
    },
    {
      icon: <FaHeadset />,
      title: 'Expert Support',
      description: 'Assistance from our private cloud specialists.',
      iconClass: 'teal',
    },
    {
      icon: <FaCogs />,
      title: 'Control',
      description: 'Full control over your cloud environment.',
      iconClass: 'teal',
    },
  ];

  return (
    <div className="services-page">
      {/* Hero Section */}
      <section
        className="hero-section"
        style={{
          backgroundImage:
            "url('/images/services/private-cloud-solutions/hero-private-cloud-solutions.jpg')",
        }}
      >
        <div className="hero-content">
          <h1>Private Cloud Solutions</h1>
          <p>Our private cloud is for businesses with specific security, compliance, or business requirements. This solution provides a fully customizable and secure environment. We arrange and observe privately
cloud infrastructure for complete control and peace of mind.
</p>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/private-cloud-solutions/parallax1.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Secure and Dedicated</h2>
        </div>
      </Parallax>

      {/* Solutions Section */}
      <section className="section light-bg">
        <div className="container">
          <h2>Our Private Cloud Services</h2>
          <div className="grid">
            {solutions.map((solution, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${solution.iconClass}`}>{solution.icon}</div>
                <h3>{solution.title}</h3>
                <p>{solution.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/private-cloud-solutions/parallax2.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Control Your Cloud</h2>
        </div>
      </Parallax>

      {/* Benefits Section */}
      <section className="section dark-bg">
        <div className="container">
          <h2>Benefits</h2>
          <div className="grid">
            {benefits.map((benefit, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${benefit.iconClass}`}>{benefit.icon}</div>
                <h3>{benefit.title}</h3>
                <p>{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="cta-section">
        <h2>Ready for a Private Cloud?</h2>
        <a href="/contact" className="cta-button">
          Contact Us
        </a>
      </section>
    </div>
  );
};

export default PrivateCloudSolutions;
