import React from 'react';
import { Parallax } from 'react-parallax';
import './Services.css';
import {
  FaTools,
  FaEye,
  FaCogs,
  FaSyncAlt,
  FaClock,
  FaChartBar,
  FaHeadset,
  FaShieldAlt,
} from 'react-icons/fa';

const VirtualInfrastructureManagement = () => {
  const services = [
    {
      icon: <FaEye />,
      title: '24/7 Monitoring',
      description: 'Continuous oversight of your virtual environment.',
      iconClass: 'blue',
    },
    {
      icon: <FaTools />,
      title: 'Maintenance & Updates',
      description: 'Regular updates to keep systems optimized.',
      iconClass: 'blue',
    },
    {
      icon: <FaCogs />,
      title: 'Resource Management',
      description: 'Efficient allocation of virtual resources.',
      iconClass: 'blue',
    },
    {
      icon: <FaSyncAlt />,
      title: 'Backup & Recovery',
      description: 'Protect your virtual infrastructure with backups.',
      iconClass: 'blue',
    },
  ];

  const benefits = [
    {
      icon: <FaClock />,
      title: 'Maximized Uptime',
      description: 'Ensure your virtual infrastructure is always operational.',
      iconClass: 'teal',
    },
    {
      icon: <FaChartBar />,
      title: 'Optimized Performance',
      description: 'Keep your systems running at peak efficiency.',
      iconClass: 'teal',
    },
    {
      icon: <FaShieldAlt />,
      title: 'Enhanced Security',
      description: 'Protect against virtual threats.',
      iconClass: 'teal',
    },
    {
      icon: <FaHeadset />,
      title: 'Expert Support',
      description: 'Assistance from our virtualization experts.',
      iconClass: 'teal',
    },
  ];

  return (
    <div className="services-page">
      {/* Hero Section */}
      <section
        className="hero-section"
        style={{
          backgroundImage:
            "url('/images/services/virtual-infrastructure-management/hero-virtual-infrastructure-management.jpg')",
        }}
      >
        <div className="hero-content">
          <h1>Virtual Infrastructure Management</h1>
          <p>We provide end-to-end management of your virtualized infrastructure, including administration
Monitoring, resource allocation, and restructuring. Our dynamic staff ensures that your virtualized environment is more efficient and effective, Allowing your IT team to concentrate on more strategic projects.
</p>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/virtual-infrastructure-management/parallax1.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Expert Oversight</h2>
        </div>
      </Parallax>

      {/* Services Overview Section */}
      <section className="section light-bg">
        <div className="container">
          <h2>Our Management Services</h2>
          <div className="grid">
            {services.map((service, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${service.iconClass}`}>{service.icon}</div>
                <h3>{service.title}</h3>
                <p>{service.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/virtual-infrastructure-management/parallax2.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Reliable Operations</h2>
        </div>
      </Parallax>

      {/* Benefits Section */}
      <section className="section dark-bg">
        <div className="container">
          <h2>Benefits</h2>
          <div className="grid">
            {benefits.map((benefit, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${benefit.iconClass}`}>{benefit.icon}</div>
                <h3>{benefit.title}</h3>
                <p>{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="cta-section">
        <h2>Ready to Manage Your Virtual Infrastructure?</h2>
        <a href="/contact" className="cta-button">
          Contact Us
        </a>
      </section>
    </div>
  );
};

export default VirtualInfrastructureManagement;
