import React from 'react';
import { Parallax } from 'react-parallax';
import './Services.css';
import {
  FaSyncAlt,
  FaCloud,
  FaShieldAlt,
  FaServer,
  FaLock,
  FaHistory,
  FaCogs,
  FaHeadset,
} from 'react-icons/fa';

const DisasterRecoverySolutions = () => {
  const solutions = [
    {
      icon: <FaSyncAlt />,
      title: 'Data Backup',
      description: 'Regular backups to secure your critical data.',
      iconClass: 'blue',
    },
    {
      icon: <FaCloud />,
      title: 'Cloud Recovery',
      description: 'Recover your systems quickly via the cloud.',
      iconClass: 'blue',
    },
    {
      icon: <FaServer />,
      title: 'On-Premise Recovery',
      description: 'Local recovery solutions for rapid response.',
      iconClass: 'blue',
    },
    {
      icon: <FaShieldAlt />,
      title: 'Business Continuity Planning',
      description: 'Comprehensive plans to keep your business running.',
      iconClass: 'blue',
    },
  ];

  const benefits = [
    {
      icon: <FaLock />,
      title: 'Data Security',
      description: 'Protect against data loss and corruption.',
      iconClass: 'teal',
    },
    {
      icon: <FaHistory />,
      title: 'Quick Recovery',
      description: 'Minimize downtime with fast recovery options.',
      iconClass: 'teal',
    },
    {
      icon: <FaCogs />,
      title: 'Customized Solutions',
      description: 'Tailored to your business needs and risks.',
      iconClass: 'teal',
    },
    {
      icon: <FaHeadset />,
      title: 'Expert Support',
      description: 'Guidance and assistance during critical times.',
      iconClass: 'teal',
    },
  ];

  return (
    <div className="services-page">
      {/* Hero Section */}
      <section
        className="hero-section"
        style={{
          backgroundImage:
            "url('/images/services/disaster-recovery-solutions/hero-disaster-recovery-solutions.jpg')",
        }}
      >
        <div className="hero-content">
          <h1>Disaster Recovery Solutions</h1>
          <p>We provide solutions that protect your business from unexpected disruptions by ensuring the continuity and availability of critical systems and data. Our comprehensive recovery plans include data backup, redundancy, and failover strategies to minimize downtime and data loss. Whether facing natural disasters, cyber-attacks, or hardware failures, our solutions offer rapid recovery with minimal impact on your operations. Through automated backup processes, cloud-based recovery options, and real-time monitoring, we ensure your business can quickly resume normal operations, safeguarding your data and maintaining business continuity in any situation.
          </p>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/disaster-recovery-solutions/parallax1.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Stay Prepared</h2>
        </div>
      </Parallax>

      {/* Solutions Section */}
      <section className="section light-bg">
        <div className="container">
          <h2>Our Recovery Solutions</h2>
          <div className="grid">
            {solutions.map((solution, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${solution.iconClass}`}>{solution.icon}</div>
                <h3>{solution.title}</h3>
                <p>{solution.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/disaster-recovery-solutions/parallax2.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Secure Your Future</h2>
        </div>
      </Parallax>

      {/* Benefits Section */}
      <section className="section dark-bg">
        <div className="container">
          <h2>Benefits</h2>
          <div className="grid">
            {benefits.map((benefit, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${benefit.iconClass}`}>{benefit.icon}</div>
                <h3>{benefit.title}</h3>
                <p>{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="cta-section">
        <h2>Ready to Protect Your Business?</h2>
        <a href="/contact" className="cta-button">
          Contact Us
        </a>
      </section>
    </div>
  );
};

export default DisasterRecoverySolutions;
