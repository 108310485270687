import { default as React } from "react";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom"; // Import useNavigate hook
import Slider from "react-slick"; // Import Slider from react-slick
import "./JuniperPage.css"; // Custom styling for Juniper page
import "./LogitechPage.css"; // Custom styling for Logitech page
import "./MicronPage.css"; // Custom styling for Micron page

// Import slick-carousel CSS
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

// Carousel settings
const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3, // Number of slides to show on desktop
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000, // 3 seconds
  responsive: [
    {
      breakpoint: 1200, // Tablet breakpoint
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 768, // Mobile breakpoint
      settings: {
        slidesToShow: 1,
      },
    },
  ],
  arrows: false, // Hide arrows for a cleaner look
};
// Product Data
const products = [
  {
    name: "Productivity solutions",
    // description:
    //   "A 2U rack-mounted NAS designed for enterprise-level storage with redundancy and high performance.",
    image: "/images/products/logitech/logitech-productivity-solutions.jpg",
  },
  {
    name: "Video conferencing solutions",
    // description:
    //   "A powerful rack-mounted NAS with advanced data management, suitable for SMBs requiring virtualization support.",
    image:
      "/images/products/logitech/logitech-video-conferencing-solutions.jpg",
  },
  {
    name: "Gaming solutions",
    // description:
    //   "A desktop NAS offering high scalability and performance, ideal for small to medium-sized businesses.",
    image: "/images/products/logitech/logitech-gaming-solutions.jpg",
  },
  {
    name: "Smart Home Solutions",
    // description:
    //   "A desktop NAS offering high scalability and performance, ideal for small to medium-sized businesses.",
    image: "/images/products/logitech/logitech-smart-home-solutions.jpg",
  },
  {
    name: "Audio and Sound Solutions",
    // description:
    //   "A desktop NAS offering high scalability and performance, ideal for small to medium-sized businesses.",
    image: "/images/products/logitech/logitech-audio-sound-solutions.jpg",
  },
  {
    name: "Remote work and Virtual meetings Solutions",
    // description:
    //   "A desktop NAS offering high scalability and performance, ideal for small to medium-sized businesses.",
    image: "/images/products/logitech/logitech-remote-work-solutions.jpg",
  },
  {
    name: "Education solutions",
    // description:
    //   "A desktop NAS offering high scalability and performance, ideal for small to medium-sized businesses.",
    image: "/images/products/logitech/logitech-education-solutions.jpg",
  },
  {
    name: "Enterprise solutions",
    // description:
    //   "A desktop NAS offering high scalability and performance, ideal for small to medium-sized businesses.",
    image: "/images/products/logitech/logitech-enterprise-solutions.jpg",
  },
];

const LogitechPage = () => {
  const navigate = useNavigate(); // Initialize navigate

  return (
    <div className="logitech-page-container">
      <div className="logitech-hero-container">
        <div className="hero-text">
          <h1>Logitech</h1>
          <h2>Innovative Peripherals for Enhanced Productivity</h2>
          <p>
            Logitech is a leading manufacturer of personal peripherals, offering
            high-quality products designed to help people enjoy a more seamless
            experience with their technology. With a focus on innovation,
            quality, and sustainability, Logitech delivers solutions that
            enhance productivity and creativity.
          </p>
          <button
            className="learn-more-button"
            onClick={() => navigate("/contact")}
          >
            Learn More
          </button>
        </div>
        <div
          className="hero-image"
          style={{
            backgroundColor: "#fff",
            padding: "50px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "200px",
          }}
        >
          <img
            src="/images/logos/new/all_logos__4.svg"
            alt="Logitech Logo"
            style={{ height: "140px" }}
          />
        </div>
      </div>
      {/* Product Carousel Section */}
      <div className="product-carousel-section">
        <h2 className="carousel-title">Our Logitech Solutions</h2>
        <Slider {...settings} aria-label="Product Carousel">
          {products.map((product, index) => (
            <div key={index} className="product-card">
              <a
                href={`/products/${product.name}`}
                className="product-link"
                aria-label={`Learn more about ${product.name}`}
              >
                <div className="product-image-container">
                  <img
                    src={product.image}
                    alt={`${product.name} Logo`}
                    className="product-image"
                    loading="lazy"
                  />
                </div>
                <h3 className="product-name">{product.name}</h3>
                <p className="product-description">{product.description}</p>
              </a>
            </div>
          ))}
        </Slider>
      </div>

      <div className="video-container">
        <div className="video-responsive">
          <ReactPlayer
            url="https://www.youtube.com/watch?v=hAPNqkHHITA"
            controls
            width="100%"
            height="100%"
            className="react-player"
          />
        </div>
      </div>
    </div>
  );
};

export default LogitechPage;
