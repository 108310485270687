import React from 'react';
import { Parallax } from 'react-parallax';
import './Services.css';
import {
  FaSitemap,
  FaCloud,
  FaShieldAlt,
  FaSatelliteDish,
  FaGlobe,
  FaChartLine,
  FaLock,
  FaCogs,
} from 'react-icons/fa';

const WANSolutions = () => {
  const services = [
    {
      icon: <FaSitemap />,
      title: 'MPLS Networks',
      description: 'Efficient data routing and improved network performance.',
      iconClass: 'blue',
    },
    {
      icon: <FaCloud />,
      title: 'SD-WAN Solutions',
      description: 'Flexible and cost-effective networking solutions.',
      iconClass: 'blue',
    },
    {
      icon: <FaShieldAlt />,
      title: 'VPN Implementation',
      description: 'Securely connect remote locations and users.',
      iconClass: 'blue',
    },
    {
      icon: <FaSatelliteDish />,
      title: 'Satellite WAN',
      description: 'Connectivity in remote and hard-to-reach areas.',
      iconClass: 'blue',
    },
  ];

  const benefits = [
    {
      icon: <FaGlobe />,
      title: 'Global Connectivity',
      description: 'Connect your business locations worldwide.',
      iconClass: 'teal',
    },
    {
      icon: <FaChartLine />,
      title: 'High Performance',
      description: 'Experience reliable and fast network communication.',
      iconClass: 'teal',
    },
    {
      icon: <FaLock />,
      title: 'Enhanced Security',
      description: 'Secure data transfer across all locations.',
      iconClass: 'teal',
    },
    {
      icon: <FaCogs />,
      title: 'Scalability',
      description: 'Easily add new locations and users.',
      iconClass: 'teal',
    },
  ];

  return (
    <div className="services-page">
      {/* Hero Section */}
      <section
        className="hero-section"
        style={{
          backgroundImage: "url('/images/services/wan-solutions/hero-wan-solutions.jpg')",
        }}
      >
        <div className="hero-content">
          <h1>WAN Solutions</h1>
          <p>For companies with multiple locations, our WAN answer provides seamless connectivity throughout Geographically dispersed lines. We build robust, scalable, and high-performance WAN networks allowing for efficient communication and data exchange between remote offices and data management centers.
          </p>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/wan-solutions/parallax1.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Global Connectivity</h2>
        </div>
      </Parallax>

      {/* Services Offered Section */}
      <section className="section light-bg">
        <div className="container">
          <h2>Services We Offer</h2>
          <div className="grid">
            {services.map((service, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${service.iconClass}`}>{service.icon}</div>
                <h3>{service.title}</h3>
                <p>{service.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/wan-solutions/parallax2.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Seamless Integration</h2>
        </div>
      </Parallax>

      {/* Benefits Section */}
      <section className="section dark-bg">
        <div className="container">
          <h2>Benefits</h2>
          <div className="grid">
            {benefits.map((benefit, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${benefit.iconClass}`}>{benefit.icon}</div>
                <h3>{benefit.title}</h3>
                <p>{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="cta-section">
        <h2>Ready to Expand Your Network?</h2>
        <a href="/contact" className="cta-button">
          Contact Us
        </a>
      </section>
    </div>
  );
};

export default WANSolutions;
