import React from 'react';
import { Parallax } from 'react-parallax';
import './Services.css';
import {
  FaLaptop,
  FaBarcode,
  FaDatabase,
  FaSyncAlt,
  FaDollarSign,
  FaHeadset,
  FaChartPie,
  FaClipboardCheck,
} from 'react-icons/fa';

const ITAssetManagement = () => {
  const features = [
    {
      icon: <FaBarcode />,
      title: 'Asset Tracking',
      description: 'Keep track of all your IT assets.',
      iconClass: 'blue',
    },
    {
      icon: <FaDatabase />,
      title: 'Inventory Management',
      description: 'Maintain an up-to-date inventory database.',
      iconClass: 'blue',
    },
    {
      icon: <FaSyncAlt />,
      title: 'Lifecycle Management',
      description: 'Manage assets from procurement to disposal.',
      iconClass: 'blue',
    },
    {
      icon: <FaClipboardCheck />,
      title: 'Compliance Reporting',
      description: 'Ensure compliance with licensing agreements.',
      iconClass: 'blue',
    },
  ];

  const benefits = [
    {
      icon: <FaDollarSign />,
      title: 'Cost Savings',
      description: 'Optimize asset utilization and reduce expenses.',
      iconClass: 'teal',
    },
    {
      icon: <FaHeadset />,
      title: 'Expert Support',
      description: 'Guidance from our asset management specialists.',
      iconClass: 'teal',
    },
    {
      icon: <FaChartPie />,
      title: 'Improved Planning',
      description: 'Make informed decisions about IT investments.',
      iconClass: 'teal',
    },
    {
      icon: <FaLaptop />,
      title: 'Asset Visibility',
      description: 'Gain complete visibility of your IT assets.',
      iconClass: 'teal',
    },
  ];

  return (
    <div className="services-page">
      {/* Hero Section */}
      <section
        className="hero-section"
        style={{
          backgroundImage:
            "url('/images/services/it-asset-management/hero-it-asset-management.jpg')",
        }}
      >
        <div className="hero-content">
          <h1>IT Asset Management</h1>
          <p>We manage your IT assets, ensuring that all hardware and software are properly tracked, Maintained and updated. Our IT asset management services help you do the things right functionality, reduce costs, and maintain compliance with industry standards.
          </p>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/it-asset-management/parallax1.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Maximize Asset Value</h2>
        </div>
      </Parallax>

      {/* Features Section */}
      <section className="section light-bg">
        <div className="container">
          <h2>Our Asset Management Services</h2>
          <div className="grid">
            {features.map((feature, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${feature.iconClass}`}>
                  {feature.icon}
                </div>
                <h3>{feature.title}</h3>
                <p>{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Parallax Section */}
      <Parallax
        bgImage="/images/services/it-asset-management/parallax2.jpg"
        strength={500}
        className="parallax-section"
      >
        <div className="parallax-content">
          <h2>Streamline Operations</h2>
        </div>
      </Parallax>

      {/* Benefits Section */}
      <section className="section dark-bg">
        <div className="container">
          <h2>Benefits</h2>
          <div className="grid">
            {benefits.map((benefit, index) => (
              <div className="card" key={index}>
                <div className={`card-icon ${benefit.iconClass}`}>
                  {benefit.icon}
                </div>
                <h3>{benefit.title}</h3>
                <p>{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="cta-section">
        <h2>Ready to Manage Your Assets?</h2>
        <a href="/contact" className="cta-button">
          Contact Us
        </a>
      </section>
    </div>
  );
};

export default ITAssetManagement;
